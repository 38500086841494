import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundDamageStatsCategories } from '../../../../../helpers';
import { IMatchFinalBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchOverallWeaponsDamageTable from './MatchOverallWeaponsDamageTable';

interface MatchOverallWeaponCategoryDamageTabsViewProps {
    categoryObject: MatchRoundDamageStatsCategories;
    activeWeapon: string;
    finalBoxScore: IMatchFinalBoxScoreDto;
};

const MatchOverallWeaponCategoryDamageTabsView = (props: MatchOverallWeaponCategoryDamageTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeWeapon}>
                {props.categoryObject && props.categoryObject.DamageWeaponCategories.map((s, idx) => (
                    <TabPane tabId={s}>
                        <MatchOverallWeaponsDamageTable activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                    </TabPane>
                    ))}
            </TabContent>
        </React.Fragment>);
};

export default MatchOverallWeaponCategoryDamageTabsView;