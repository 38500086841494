import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import {
    IMatchLineScoreDto,
    IMatchTeamInfoDto,
} from '../../WorldDoomLeague';

interface MatchPreviewSummaryProps {
    teamInfo: IMatchTeamInfoDto,
    seasonName: string
}

const MatchPreviewSummary = (props: MatchPreviewSummaryProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className='text-center mb-3'>{props.seasonName}</h2>
                </Col>
            </Row>
            <Card className='bg-secondary border-white mb-3'>
                <div className="container-fluid">
                    <Row xs="3">
                        <Col>
                            <h2 className='text-left'><NavLink className='text-info' to={`/team/${props.teamInfo.blueTeamId}`}>{props.teamInfo.blueTeamName}</NavLink></h2>
                        </Col>
                        <Col>
                            <h2 className='text-center'>vs.</h2>
                        </Col>
                        <Col>
                            <h2 className='text-right'><NavLink className='text-danger' to={`/team/${props.teamInfo.redTeamId}`}>{props.teamInfo.redTeamName}</NavLink></h2>
                        </Col>
                    </Row>
                </div>
            </Card>
        </React.Fragment>);
};

export default MatchPreviewSummary;