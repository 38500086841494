import * as React from 'react';
import { IMyDemoDto } from '../../WorldDoomLeague';
import DemoItem from './DemoItem';

interface DemoListProps {
    demoList: IMyDemoDto[];
    setChangedDemoId: React.Dispatch<React.SetStateAction<number>>;

}

const DemoList = (props: DemoListProps) => {
    return (
        <React.Fragment>
            {props.demoList && props.demoList.map((s, idx) => (
                <DemoItem demo={s} setChangedDemoId={props.setChangedDemoId} key={s.id} />
            ))}
        </React.Fragment>);
};

export default DemoList;