import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, FormGroup, Form, Button } from 'reactstrap';
import {
    IEnginesVm,
    IEnginesDto,
    CreateEngineCommand,
    EngineClient,
    ISeasonDto
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';

interface EngineListProps {
    engine: number;
    setEngine: React.Dispatch<React.SetStateAction<number>>;
    season: ISeasonDto;
}

const EngineList = (props: EngineListProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IEnginesDto[]>([]);
    const [engineFormName, setEngineFormName] = useState<string>("");
    const [engineFormUrl, setEngineFormUrl] = useState<string>("");
    const [newEngineId, setNewEngineId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new EngineClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IEnginesVm>);
                const data = response.engineList;
                setData(data);
                if (data.length > 0) {
                    handleEngineChange(data[0].id);
                }
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [newEngineId]);

    useEffect(() => {
        if (props.season) {
            props.setEngine(props.season.engineId);
        }
    }, [props.season]);

    const handleEngineChange = (value: number) => {
        props.setEngine(value);
    };

    const handleSubmit = async (evt) => {
        try {
            let client = new EngineClient();
            const command = new CreateEngineCommand;
            command.engineName = engineFormName;
            command.engineUrl = engineFormUrl;
            const response = await client.create(command);
            setNewEngineId(response);
            setEngineFormName('');
            setEngineFormUrl('');
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    // create a list for each engine.
    const renderEngineDropdown = () => {
        let select = null;
        if (!loading) {
            if (data.length > 0) {
                select = (
                    data.map(engine =>
                        <option key={engine.id} value={engine.id}>
                            {engine.engineName}
                        </option>));
            } else {
                select = (
                    <option>
                        No engines currently in the system.
                    </option>);
            }
        } else {
            select = (
                <option>
                    Loading...
                </option>);
        }
        return (select);
    };

    // create a form for entering a new engine.
    const renderNewEngineForm = () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="engineName">Engine Name</Label>
                    <Input type="text" name="engineName" id="engineName" value={engineFormName} placeholder="Odamex v0.8.3" onChange={e => setEngineFormName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="engineUrl">Engine Url</Label>
                    <Input type="text" name="engineUrl" id="engineUrl" value={engineFormUrl} placeholder="https://odamex.net" onChange={e => setEngineFormUrl(e.target.value)} />
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!engineFormUrl || !engineFormName} onClick={handleSubmit}>Create New Engine</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Form>
                <FormGroup>
                    <Label for="engine">Engine</Label>
                    <Input type="select" name="engine" id="engineSelect" value={props.engine} onChange={(e) => handleEngineChange(parseInt(e.target.value))}>
                        {renderEngineDropdown()}
                    </Input>
                </FormGroup>
            </Form>
            <Form>
                {renderNewEngineForm()}
            </Form>
        </React.Fragment>
    );
};

export default EngineList;