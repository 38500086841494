import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';
import HomeTeamStandings from './HomeTeamStandings';

const WDLLinks = () => {
    return (
        <Col className='col-sm-4'>
            <Card className='bg-secondary mb-3'>
                <CardHeader>
                    More League Information
                </CardHeader>
                <CardBody>
                    <p><a href='https://forums.doomleague.org' target="_blank" rel="noopener noreferrer">Forums</a></p>
                    <p><NavLink to={`/rulebook`}>Rulebook</NavLink></p>
                    <p><a href='https://discord.gg/TyF9NTj' target="_blank" rel="noopener noreferrer">Discord</a></p>
                </CardBody>
            </Card>

            <Card className='bg-primary mb-3'>
                <CardHeader>
                    Stay Connected
                </CardHeader>
                <CardBody>
                    <p><a href='https://www.youtube.com/c/DoomleagueOrg' className='text-white' target="_blank" rel="noopener noreferrer">YouTube</a></p>
                    <p><a href='https://twitter.com/WorldDoomLeague' className='text-white' target="_blank" rel="noopener noreferrer">Twitter</a></p>
                    <p><a href='https://twitch.tv/WorldDoomLeague' className='text-white' target="_blank" rel="noopener noreferrer">Twitch</a></p>
                </CardBody>
            </Card>
            <HomeTeamStandings />
        </Col>
    );
};

export default WDLLinks;