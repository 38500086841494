import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ITeamPlayerDto } from '../../WorldDoomLeague';

interface TeamInitialPlayersProps {
    totalGamePlayers: ITeamPlayerDto[];
}

const TeamGamePlayers = (props: TeamInitialPlayersProps) => {
    return (
        <React.Fragment>
            <Card className='bg-secondary mb-3'>
                <CardHeader>
                    Total Game Players
                </CardHeader>
                <CardBody>
                    {props.totalGamePlayers.map((s, idx) => (
                        <p className='text-center'><NavLink to={`/player/${s.playerId}`}>{s.playerName}</NavLink></p>
                    ))}
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default TeamGamePlayers;