import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundFragStatsCategories, FragWeaponCategories } from '../../../../../helpers';
import { IRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchRoundWeaponsCategoryFragTabsView from './MatchRoundWeaponsFragTabsView';
import MatchRoundWeaponsFragTabs from './MatchRoundWeaponsFragTabs';

interface MatchRoundWeaponCategoryTabsViewProps {
    category: FragWeaponCategories;
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    perRoundBoxScore: IRoundBoxScoreDto;
};

const MatchRoundWeaponsFragCategoryTabsView = (props: MatchRoundWeaponCategoryTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.category}>
                <TabPane tabId={FragWeaponCategories.Frags}>
                    <MatchRoundWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsCategoryFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.FragsWithFlag}>
                    <MatchRoundWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsCategoryFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.CarrierFrags}>
                    <MatchRoundWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsCategoryFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.CarrierFragsWithFlag}>
                    <MatchRoundWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsCategoryFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default MatchRoundWeaponsFragCategoryTabsView;