import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import { formatdecider, DamageWeaponCategories, MatchRoundAccuracyStatsCategories, AccuracyWeaponCategories, FormatMinMaxAvg, pcntgformat } from '../../../../../helpers'
import { useEffect, useState } from 'react';

interface AdHocMatchOverallWeaponsAccuracyTableProps {
    categoryObject: MatchRoundAccuracyStatsCategories;
    activeWeapon: string;
    finalBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponsAccuracyTable = (props: AdHocMatchOverallWeaponsAccuracyTableProps) => {
    const [redPlayers, setRedPlayers] = useState<number[]>([]);
    const [bluePlayers, setBluePlayers] = useState<number[]>([]);
    const [greenPlayers, setGreenPlayers] = useState<number[]>([]);
    const [nonePlayers, setNonePlayers] = useState<number[]>([]);

    const getRedTeamPlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var redPlayers = [] as number[];
        boxscore.redTeamPlayerRoundBoxScore.map((s, idx) => {
            redPlayers.push(s.playerId);
        });

        return redPlayers;
    };

    const getBlueTeamPlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var bluePlayers = [] as number[];
        boxscore.blueTeamPlayerRoundBoxScore.map((s, idx) => {
            bluePlayers.push(s.playerId);
        });

        return bluePlayers;
    };

    const getGreenTeamPlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var greenPlayers = [] as number[];
        boxscore.greenTeamPlayerRoundBoxScore.map((s, idx) => {
            greenPlayers.push(s.playerId);
        });

        return greenPlayers;
    };

    const getNoneTeamlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var nonePlayers = [] as number[];
        boxscore.nonTeamPlayerRoundBoxScore.map((s, idx) => {
            nonePlayers.push(s.playerId);
        });

        return nonePlayers;
    };

    const createRedPlayerTableRow = () => {
        var redRows = [];
        var redRow = [];
        redPlayers.map((s, idx) => {
            redRow.push(<th scope="row">{props.finalBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            var playerRoundStats = props.finalBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerId === s);
            if (playerRoundStats) {
                if (props.categoryObject.category === AccuracyWeaponCategories.Accuracy) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeapon.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                redRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                redRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                redRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                redRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            redRow.push(<td>-</td>);
                            redRow.push(<td>- / -</td>);
                            redRow.push(<td>- / - / -</td>);
                            redRow.push(<td>-</td>);
                        }
                    } else {
                        redRow.push(<td>-</td>);
                        redRow.push(<td>- / -</td>);
                        redRow.push(<td>- / - / -</td>);
                        redRow.push(<td>-</td>);
                    }
                } else if (props.categoryObject.category === AccuracyWeaponCategories.AccuracyWithFlag) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                redRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                redRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                redRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                redRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            redRow.push(<td>-</td>);
                            redRow.push(<td>- / -</td>);
                            redRow.push(<td>- / - / -</td>);
                            redRow.push(<td>-</td>);
                        }
                    } else {
                        redRow.push(<td>-</td>);
                        redRow.push(<td>- / -</td>);
                        redRow.push(<td>- / - / -</td>);
                        redRow.push(<td>-</td>);
                    }
                }
            }

            redRows.push(<tr className='bg-danger'>{redRow}</tr>);
            redRow = [];
        });

        return (redRows);
    };

    const createBluePlayerTableRow = () => {
        var blueRows = [];
        var blueRow = [];
        bluePlayers.map((s, idx) => {
            blueRow.push(<th scope="row">{props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            var playerRoundStats = props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s);
            if (playerRoundStats) {
                if (props.categoryObject.category === AccuracyWeaponCategories.Accuracy) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeapon.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                blueRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                blueRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                blueRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                blueRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            blueRow.push(<td>-</td>);
                            blueRow.push(<td>- / -</td>);
                            blueRow.push(<td>- / - / -</td>);
                            blueRow.push(<td>-</td>);
                        }
                    } else {
                        blueRow.push(<td>-</td>);
                        blueRow.push(<td>- / -</td>);
                        blueRow.push(<td>- / - / -</td>);
                        blueRow.push(<td>-</td>);
                    }
                } else if (props.categoryObject.category === AccuracyWeaponCategories.AccuracyWithFlag) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                blueRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                blueRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                blueRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                blueRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            blueRow.push(<td>-</td>);
                            blueRow.push(<td>- / -</td>);
                            blueRow.push(<td>- / - / -</td>);
                            blueRow.push(<td>-</td>);
                        }
                    } else {
                        blueRow.push(<td>-</td>);
                        blueRow.push(<td>- / -</td>);
                        blueRow.push(<td>- / - / -</td>);
                        blueRow.push(<td>-</td>);
                    }
                }
            }

            blueRows.push(<tr className='bg-primary'>{blueRow}</tr>);
            blueRow = [];
        });

        return (blueRows);
    };

    const createGreenPlayerTableRow = () => {
        var greenRows = [];
        var greenRow = [];
        greenPlayers.map((s, idx) => {
            greenRow.push(<th scope="row">{props.finalBoxScore.greenTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            var playerRoundStats = props.finalBoxScore.greenTeamPlayerRoundBoxScore.find(f => f.playerId === s);
            if (playerRoundStats) {
                if (props.categoryObject.category === AccuracyWeaponCategories.Accuracy) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeapon.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                greenRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                greenRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                greenRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                greenRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            greenRow.push(<td>-</td>);
                            greenRow.push(<td>- / -</td>);
                            greenRow.push(<td>- / - / -</td>);
                            greenRow.push(<td>-</td>);
                        }
                    } else {
                        greenRow.push(<td>-</td>);
                        greenRow.push(<td>- / -</td>);
                        greenRow.push(<td>- / - / -</td>);
                        greenRow.push(<td>-</td>);
                    }
                } else if (props.categoryObject.category === AccuracyWeaponCategories.AccuracyWithFlag) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                greenRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                greenRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                greenRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                greenRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            greenRow.push(<td>-</td>);
                            greenRow.push(<td>- / -</td>);
                            greenRow.push(<td>- / - / -</td>);
                            greenRow.push(<td>-</td>);
                        }
                    } else {
                        greenRow.push(<td>-</td>);
                        greenRow.push(<td>- / -</td>);
                        greenRow.push(<td>- / - / -</td>);
                        greenRow.push(<td>-</td>);
                    }
                }
            }

            greenRows.push(<tr className='bg-success'>{greenRow}</tr>);
            greenRow = [];
        });

        return (greenRows);
    };

    const createNonePlayerTableRow = () => {
        var noneRows = [];
        var noneRow = [];
        nonePlayers.map((s, idx) => {
            noneRow.push(<th scope="row">{props.finalBoxScore.nonTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            var playerRoundStats = props.finalBoxScore.nonTeamPlayerRoundBoxScore.find(f => f.playerId === s);
            if (playerRoundStats) {
                if (props.categoryObject.category === AccuracyWeaponCategories.Accuracy) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeapon.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                noneRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                noneRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                noneRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                noneRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            noneRow.push(<td>-</td>);
                            noneRow.push(<td>- / -</td>);
                            noneRow.push(<td>- / - / -</td>);
                            noneRow.push(<td>-</td>);
                        }
                    } else {
                        noneRow.push(<td>-</td>);
                        noneRow.push(<td>- / -</td>);
                        noneRow.push(<td>- / - / -</td>);
                        noneRow.push(<td>-</td>);
                    }
                } else if (props.categoryObject.category === AccuracyWeaponCategories.AccuracyWithFlag) {
                    var playerAccuracyByWeapon = playerRoundStats.accuracyStats.accuracyByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                    if (playerAccuracyByWeapon) {
                        var playerAccuracy = playerAccuracyByWeapon.accuracy;
                        if (playerAccuracy) {
                            playerAccuracy.map((u, _idx) => {
                                var hitMiss = u.hitMissRatio;
                                var spritePrcnt = u.spritePercent;
                                var pinpointPrcnt = u.pinpointPercent;
                                var pelletsHit = u.totalPelletsHit;
                                var pelletsMissed = u.totalPelletsMissed;
                                var pelletsAttempted = u.totalPelletsAttempted;
                                var shotsHit = u.totalShotsHit;
                                var shotsMissed = u.totalShotsMissed;
                                var shotsAttempted = u.totalShotsAttempted;

                                noneRow.push(<td>{pcntgformat(hitMiss)}</td>);
                                noneRow.push(<td>{FormatMinMaxAvg(spritePrcnt)} / {FormatMinMaxAvg(pinpointPrcnt)}</td>);
                                noneRow.push(<td>{shotsHit} / {shotsMissed} / {shotsAttempted}</td>);
                                noneRow.push(<td>{pelletsHit} / {pelletsMissed} / {pelletsAttempted}</td>);
                            });
                        } else {
                            noneRow.push(<td>-</td>);
                            noneRow.push(<td>- / -</td>);
                            noneRow.push(<td>- / - / -</td>);
                            noneRow.push(<td>-</td>);
                        }
                    } else {
                        noneRow.push(<td>-</td>);
                        noneRow.push(<td>- / -</td>);
                        noneRow.push(<td>- / - / -</td>);
                        noneRow.push(<td>-</td>);
                    }
                }
            }

            noneRows.push(<tr className='bg-dark'>{noneRow}</tr>);
            noneRow = [];
        });

        return (noneRows);
    };

    useEffect(() => {
        setRedPlayers(getRedTeamPlayers(props.finalBoxScore));
        setBluePlayers(getBlueTeamPlayers(props.finalBoxScore));
        setGreenPlayers(getGreenTeamPlayers(props.finalBoxScore));
        setNonePlayers(getNoneTeamlayers(props.finalBoxScore));

    }, [props.categoryObject, props.activeWeapon]);

    return (
        <React.Fragment>
            {redPlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>Hit/Miss %</th>
                                    <th>Sprite % / Pinpoint %</th>
                                    <th>Shot Hit / Missed / Attempted</th>
                                    <th>Pellets Hit / Missed / Attempted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createRedPlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {bluePlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>Hit/Miss %</th>
                                    <th>Sprite % / Pinpoint %</th>
                                    <th>Shot Hit / Missed / Attempted</th>
                                    <th>Pellets Hit / Missed / Attempted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createBluePlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {greenPlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>Hit/Miss %</th>
                                    <th>Sprite % / Pinpoint %</th>
                                    <th>Shot Hit / Missed / Attempted</th>
                                    <th>Pellets Hit / Missed / Attempted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createGreenPlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            {nonePlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>Hit/Miss %</th>
                                    <th>Sprite % / Pinpoint %</th>
                                    <th>Shot Hit / Missed / Attempted</th>
                                    <th>Pellets Hit / Missed / Attempted</th>
                                </tr>
                            </thead>
                            <tbody>
                                {createNonePlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default AdHocMatchOverallWeaponsAccuracyTable;