import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { ITeamPlayerDto } from '../../WorldDoomLeague';
import TeamCurrentPlayers from './TeamCurrentPlayers';
import TeamGamePlayers from './TeamGamePlayers';
import TeamInitialPlayers from './TeamInitialPlayers';

interface TeamRosterProps {
    initialPlayers: ITeamPlayerDto[];
    totalGamePlayers: ITeamPlayerDto[];
    currentPlayers: ITeamPlayerDto[];
}

const TeamRoster = (props: TeamRosterProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Row>
                        <Col xs="6" sm="4">
                            <TeamInitialPlayers initialPlayers={props.initialPlayers} />
                        </Col>
                        <Col xs="6" sm="4">
                            <TeamGamePlayers totalGamePlayers={props.totalGamePlayers} />
                            <br />
                        </Col>
                        <Col xs="6" sm="4">
                            <TeamCurrentPlayers currentPlayers={props.currentPlayers} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>);
};

export default TeamRoster;