import * as React from 'react';
import { Col, Row } from 'reactstrap';
import NewsBlog from './NewsBlog';
import WDLLinks from './WDLLinks';

function Home() {
    return (
        <React.Fragment>
            <Row>
                <NewsBlog />
                <WDLLinks />
            </Row>
        </React.Fragment>
    );
}

export default Home;