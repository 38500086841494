import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IMapSeasonsDto, IMapStatsDto, IMapSummaryImagesDto, IMapTeamHomefieldsDto } from '../../WorldDoomLeague';
import MapAppearancesTable from './MapAppearancesTable';
import MapSeasonsPlayed from './MapSeasonsPlayed';
import MapStats from './MapStats';
import MapTeamHomefields from './MapTeamHomefields';
import MapView from './MapView';
import MapViewTabs from './MapViewTabs';

interface MapDetailsProps {
    seasonsFeaturingMap: IMapSeasonsDto[];
    teamHomefields: IMapTeamHomefieldsDto[];
    regularSeasonGamesPlayed: number;
    playoffsGamesPlayed: number;
    finalsGamesPlayed: number;
    mapStats: IMapStatsDto;
}

const MapDetails = (props: MapDetailsProps) => {
    const [activeTab, setActiveTab] = React.useState(1);

    return (
        <React.Fragment>
            <MapViewTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <MapView
                activeTab={activeTab}
                seasonsFeaturingMap={props.seasonsFeaturingMap}
                teamHomefields={props.teamHomefields}
                regularSeasonGamesPlayed={props.regularSeasonGamesPlayed}
                playoffsGamesPlayed={props.playoffsGamesPlayed}
                finalsGamesPlayed={props.finalsGamesPlayed}
                mapStats={props.mapStats}
            />
        </React.Fragment>);
};

export default MapDetails;