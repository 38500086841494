import * as React from 'react';
import { Container, Jumbotron } from 'reactstrap';
import NavMenu from './navmenu/NavMenu';
import ErrorMessage from './ErrorMessage';
import Footer from './footer/Footer'
import { ErrorProvider } from '../state';

function Layout(props: { children?: React.ReactNode }) {
    return (
    <React.Fragment>
            <Container>
                <NavMenu />
                <Jumbotron>
                    <ErrorProvider>
                        <ErrorMessage />
                        {props.children}
                    </ErrorProvider>
                </Jumbotron>
                <Footer />
            </Container>
    </React.Fragment>
    );
}

export default Layout;