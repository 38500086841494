import * as React from 'react';
import { ISeasonStandingsDto } from '../../WorldDoomLeague';
import { FormatHhMmSs, formatdecider } from '../../helpers'
import { NavLink } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';

interface SeasonTeamStandingsProps {
    seasonStandings: ISeasonStandingsDto[];
}

const SeasonTeamStandings = (props: SeasonTeamStandingsProps) => {
    return (
        <Row className='mb-3'>
            <Col>
                {props.seasonStandings && props.seasonStandings.length > 0 && (
                <Table size='sm' striped>
                    <thead>
                        <tr>
                            <th>Team</th>
                            <th>PTS</th>
                            <th>GP</th>
                            <th>RP</th>
                            <th>W</th>
                            <th>L</th>
                            <th>T</th>
                            <th>FF</th>
                            <th>FA</th>
                            <th>DEF</th>
                            <th>FRAGS</th>
                            <th>DMG</th>
                            <th>TIME</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.seasonStandings.map((seasonStandings: ISeasonStandingsDto) =>
                            <tr key={seasonStandings.teamName}>
                                <td><NavLink to={`/team/${seasonStandings.teamId}`}>{seasonStandings.teamName}</NavLink></td>
                                <td>{formatdecider(seasonStandings.points)}</td>
                                <td>{formatdecider(seasonStandings.gamesPlayed)}</td>
                                <td>{formatdecider(seasonStandings.roundsPlayed)}</td>
                                <td>{formatdecider(seasonStandings.wins)}</td>
                                <td>{formatdecider(seasonStandings.losses)}</td>
                                <td>{formatdecider(seasonStandings.ties)}</td>
                                <td>{formatdecider(seasonStandings.flagCapturesFor)}</td>
                                <td>{formatdecider(seasonStandings.flagCapturesAgainst)}</td>
                                <td>{formatdecider(seasonStandings.flagDefenses)}</td>
                                <td>{formatdecider(seasonStandings.frags)}</td>
                                <td>{formatdecider(seasonStandings.damage)}</td>
                                <td>{FormatHhMmSs(seasonStandings.timePlayed)}</td>
                            </tr>
                        )}
                    </tbody>
                    </Table>
                )}
            </Col>
        </Row>
    );
};

export default SeasonTeamStandings;