import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { GameModeType, IAdHocMatchStatsDto, IAdHocRoundBoxScoreDto } from '../../../../WorldDoomLeague';
import { FormatMinMaxAvg } from '../../../../helpers'

interface AdHocMatchOverallPerformanceDamageStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto,
    gameMode: GameModeType
};

const AdHocMatchOverallPerformanceDamageStats = (props: AdHocMatchOverallPerformanceDamageStatsProps) => {
    const CreateHeader = (gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (<tr>
                <th>Name</th>
                <th>HP Damage</th>
                <th>Green Armor Damage</th>
                <th>Blue Armor Damage</th>
                <th>Damage to Flag Carriers</th>
                <th>Damage with Flag</th>
                <th>Damage to Flag Carriers w/ Flag</th>
                <th>Env. Damage</th>
                <th>Env. Damage w/ Flag</th>
                <th>Min/Max/Avg Dmg {'->'} Cap</th>
            </tr>);
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (<tr>
                <th>Name</th>
                <th>HP Damage</th>
                <th>Green Armor Damage</th>
                <th>Blue Armor Damage</th>
                <th>Env. Damage</th>
                <th>Env. Damage w/ Flag</th>
            </tr>);
        }
        else {
            return (<tr>
                <th>Name</th>
                <th>HP Damage</th>
                <th>Green Armor Damage</th>
                <th>Blue Armor Damage</th>
                <th>Env. Damage</th>
                <th>Env. Damage w/ Flag</th>
            </tr>);
        }
    };

    const CreateDamageStatsRows = (boxscore: IAdHocMatchStatsDto, gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (
                <React.Fragment>
                    <td>{boxscore.damage}</td>
                    <td>{boxscore.damageToGreenArmor}</td>
                    <td>{boxscore.damageToBlueArmor}</td>
                    <td>{boxscore.damageToFlagCarriers}</td>
                    <td>{boxscore.damageAsFlagCarrier}</td>
                    <td>{boxscore.damageAsFlagCarrierWhileHoldingFlag}</td>
                    <td>{boxscore.damageTakenFromEnvironment}</td>
                    <td>{boxscore.damageTakenFromEnvironmentAsFlagCarrier}</td>
                    <td>{FormatMinMaxAvg(boxscore.damageBetweenTouchAndCaptureMin)} / {FormatMinMaxAvg(boxscore.damageBetweenTouchAndCaptureMax)} / {FormatMinMaxAvg(boxscore.damageBetweenTouchAndCaptureAverage)}</td>
                </React.Fragment>
            );
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (
                <React.Fragment>
                    <td>{boxscore.damage}</td>
                    <td>{boxscore.damageToGreenArmor}</td>
                    <td>{boxscore.damageToBlueArmor}</td>
                    <td>{boxscore.damageTakenFromEnvironment}</td>
                    <td>{boxscore.damageTakenFromEnvironmentAsFlagCarrier}</td>
                </React.Fragment>
            );
        }
        else {
            return (<React.Fragment>
                <td>{boxscore.damage}</td>
                <td>{boxscore.damageToGreenArmor}</td>
                <td>{boxscore.damageToBlueArmor}</td>
                <td>{boxscore.damageTakenFromEnvironment}</td>
                <td>{boxscore.damageTakenFromEnvironmentAsFlagCarrier}</td>
            </React.Fragment>);
        }
    };
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button color="primary" block id="damagetoggler" style={{ marginBottom: '1rem' }}>
                        Damage
                    </Button>
                    <UncontrolledCollapse toggler="#damagetoggler">
                        <Row>
                            <Col>
                                <h2>Damage</h2>
                                <Table responsive>
                                    <thead>
                                        {CreateHeader(props.gameMode)}
                                    </thead>
                                    <tbody>
                                        {props.finalBoxScore.redTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateDamageStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateDamageStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.greenTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-success'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateDamageStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.nonTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-dark'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateDamageStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdHocMatchOverallPerformanceDamageStats;