import * as React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { IMapSeasonsDto } from '../../WorldDoomLeague';

interface MapSeasonsPlayedProps {
    mapSeasonsPlayed: IMapSeasonsDto[];
}

const MapSeasonsPlayed = (props: MapSeasonsPlayedProps) => {
    return (
        <Row>
            {props.mapSeasonsPlayed.map((s, idx) => (
                <Col sm='3' className='mx-auto'>
                    <Card className='border-warning mb-3'>
                        <CardHeader>
                            <NavLink className='text-white' to={`/season/${s.seasonId}`}>{s.seasonName}</NavLink>
                        </CardHeader>
                        <CardBody>
                                                <ul>
                        {s.regularSeasonAppearance && (
                            <li>Regular Season</li>
                        )}
                        {s.playoffAppearance && (
                            <li>Playoffs</li>
                        )}
                        {s.finalsAppearance && (
                            <li>Finals</li>
                        )}
                    </ul>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            {props.mapSeasonsPlayed.length <= 0 && (
                <p>No team has picked this map for a homefield yet</p>
            )}
        </Row>
    );
};

export default MapSeasonsPlayed;