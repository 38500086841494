import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import {
    IMatchLineScoreDto,
    IMatchTeamInfoDto,
    IMatchWinningTeamInfoDto,
} from '../../WorldDoomLeague';

interface PostMatchSummaryProps {
    teamInfo: IMatchTeamInfoDto,
    lineScore: IMatchLineScoreDto,
    winningTeam?: IMatchWinningTeamInfoDto
    seasonName: string
}

const PostMatchSummary = (props: PostMatchSummaryProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className='text-center mb-3'>{props.seasonName}</h2>
                </Col>
            </Row>
            <Card className='bg-secondary border-white mb-3'>
                <div className="container-fluid">
                    <Row xs="3">
                        <Col>
                            <h2 className='text-left'>
                                <NavLink className='text-info' to={`/team/${props.teamInfo.blueTeamId}`}>
                                    <span className={props.winningTeam && props.winningTeam.winningTeamColor === "b" && ("font-weight-bold")}>
                                        {props.teamInfo.blueTeamName}
                                    </span>
                                </NavLink>
                            </h2>
                        </Col>
                        <Col>
                            <h2 className='text-center'>
                                <span className={props.winningTeam && props.winningTeam.winningTeamColor === "b" && ("font-weight-bold")}>
                                    {props.lineScore.matchResult.blueRoundScore}
                                </span>
                                <span> - </span>
                                <span className={props.winningTeam && props.winningTeam.winningTeamColor === "r" && ("font-weight-bold")}>
                                    {props.lineScore.matchResult.redRoundScore}
                                </span>
                            </h2>
                        </Col>
                        <Col>
                            <h2 className='text-right'>
                                <NavLink className='text-danger' to={`/team/${props.teamInfo.redTeamId}`}>
                                    <span className={props.winningTeam && props.winningTeam.winningTeamColor === "r" && ("font-weight-bold")}>
                                        {props.teamInfo.redTeamName}
                                    </span>
                                </NavLink>
                            </h2>
                        </Col>
                    </Row>
                </div>
            </Card>
        </React.Fragment>);
};

export default PostMatchSummary;