import * as React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { ISeasonsPlayedInDto } from '../../WorldDoomLeague';

interface PlayerSeasonsPlayedInProps {
    seasonsPlayedIn: ISeasonsPlayedInDto[];
}

const PlayerSeasonsPlayedIn = (props: PlayerSeasonsPlayedInProps) => {
    return (
        <Row>
            {props.seasonsPlayedIn.map((s, idx) => (
                <Col sm='3' className='mx-auto'>
                    <Card className='border-white mb-3'>
                        <CardHeader>
                            <NavLink className='text-white' to={`/season/${s.id}`}>{s.seasonName}</NavLink>
                        </CardHeader>
                        <CardBody>
                            {s.teamsPlayedFor.map((t, _idx) => (
                                <p><NavLink to={`/team/${t.id}`}>{t.teamName}{t.didTeamWinSeason && ('👑')}{t.asCaptain && (' (Captain)')}</NavLink></p>
                            ))}
                        </CardBody>
                    </Card>
                </Col>
            ))}
            {props.seasonsPlayedIn.length <= 0 && (
                <p className='text-center'>This player has not played in any season.</p>
            )}
        </Row>
    );
};

export default PlayerSeasonsPlayedIn;