import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { IAdHocRoundBoxScoreDto } from '../../../../WorldDoomLeague';

interface AdHocMatchOverallPerformancePickupStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto
};

const AdHocMatchOverallPerformancePickupStats = (props: AdHocMatchOverallPerformancePickupStatsProps) => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button color="primary" block id="pickuptoggler" style={{ marginBottom: '1rem' }}>
                        Item Pickups
                    </Button>
                    <UncontrolledCollapse toggler="#pickuptoggler">
                        <Row>
                            <Col>
                                <h2>Item Pickups</h2>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Total Power Pickups</th>
                                            <th>Power Pickup HP</th>
                                            <th>Minor Pickup HP</th>
                                            <th>Total HP from Pickups</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.finalBoxScore.redTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.powerups}</td>
                                                <td>{s.matchStats.healthFromPowerPickups}</td>
                                                <td>{s.matchStats.healthFromNonPowerPickups}</td>
                                                <td>{s.matchStats.pickupHealthGained}</td>
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.powerups}</td>
                                                <td>{s.matchStats.healthFromPowerPickups}</td>
                                                <td>{s.matchStats.healthFromNonPowerPickups}</td>
                                                <td>{s.matchStats.pickupHealthGained}</td>
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.greenTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-success'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.powerups}</td>
                                                <td>{s.matchStats.healthFromPowerPickups}</td>
                                                <td>{s.matchStats.healthFromNonPowerPickups}</td>
                                                <td>{s.matchStats.pickupHealthGained}</td>
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.nonTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-dark'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.powerups}</td>
                                                <td>{s.matchStats.healthFromPowerPickups}</td>
                                                <td>{s.matchStats.healthFromNonPowerPickups}</td>
                                                <td>{s.matchStats.pickupHealthGained}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdHocMatchOverallPerformancePickupStats;