import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, FormGroup, Form, Button } from 'reactstrap';

import {
    CreatePlayerCommand,
    PlayersClient
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';

interface AddPlayerProps {
    setNewPlayerId: React.Dispatch<React.SetStateAction<number>>
}

const AddPlayer = (props: AddPlayerProps) => {
    const error = useContext(ErrorContext);
    const [playerFormName, setPlayerFormName] = useState<string>("");
    const [playerFormAlias, setPlayerFormAlias] = useState<string>("");
    const [newPlayerId, setNewPlayerId] = useState(0);

    const handleSubmit = async (evt) => {
        try {
            let client = new PlayersClient();
            const command = new CreatePlayerCommand;
            command.playerName = playerFormName;
            command.playerAlias = playerFormAlias;
            const response = await client.create(command);
            setNewPlayerId(response);
            props.setNewPlayerId(response);
            setPlayerFormName('');
            setPlayerFormAlias('');
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    // create a form for entering a new engine.
    const renderNewPlayerForm = () => {
        return (
            <React.Fragment>
                <h3 className='text-center'>Add Player</h3>
                <p>Please input a unique player name and an alias, if applicable.</p>
                <FormGroup>
                    <Label for='playername'>Player Name</Label>
                    <Input type='text' className='form-control' id='playername' name='playername' placeholder='Player Name' value={playerFormName} onChange={e => setPlayerFormName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for='playeralias'>Player Alias</Label>
                    <Input type='text' className='form-control' id='playeralias' name='playeralias' placeholder='Player Alias' value={playerFormAlias} onChange={e => setPlayerFormAlias(e.target.value)} />
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!playerFormName} onClick={handleSubmit}>Create New Player</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Form>
                {renderNewPlayerForm()}
            </Form>
        </React.Fragment>
    );
};

export default AddPlayer;