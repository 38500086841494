import * as React from 'react';
import { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { IPlayoffWeeksDto, IRegularSeasonWeeksDto, ISeasonCombinedStatsDto, ISeasonDraftDto, ISeasonStandingsDto, ISeasonTeamDto, ISeasonTradesDto } from '../../WorldDoomLeague';
import SeasonView from './SeasonView';
import SeasonViewTabs from './SeasonViewTabs';

interface SeasonDetailsProps {
    draft: ISeasonDraftDto[];
    trades: ISeasonTradesDto[];
    regSeasonWeeks: IRegularSeasonWeeksDto[];
    playoffWeeks: IPlayoffWeeksDto[];
    finalsWeek: IPlayoffWeeksDto;
    teams: ISeasonTeamDto[];
    combinedStats: ISeasonCombinedStatsDto;
}

const SeasonDetails = (props: SeasonDetailsProps) => {
    const [activeTab, setActiveTab] = useState(4);
    return (
        <React.Fragment>
            <SeasonViewTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <SeasonView activeTab={activeTab}
                combinedStats={props.combinedStats}
                draft={props.draft}
                trades={props.trades}
                teams={props.teams}
                regSeasonWeeks={props.regSeasonWeeks}
                playoffWeeks={props.playoffWeeks}
                finalsWeek={props.finalsWeek}
            />
        </React.Fragment>);
};

export default SeasonDetails;