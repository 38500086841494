import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, FormGroup, Form, Button, Row, Col } from 'reactstrap';
import {
    MapsClient,
    CreateMapCommand,
    IMapsDto,
    IMapsVm
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';

interface NewMapProps {
    setNewMapId: React.Dispatch<React.SetStateAction<number>>;
}

const NewMap = (props: NewMapProps) => {
    const error = useContext(ErrorContext);
    const [mapFormName, setMapFormName] = useState<string>("");
    const [mapFormPack, setMapFormPack] = useState<string>("");
    const [mapNumber, setMapNumber] = useState(0);

    const handleSubmit = async (evt) => {
        try {
            let client = new MapsClient();
            const command = new CreateMapCommand;
            command.mapName = mapFormName;
            command.mapPack = mapFormPack;
            command.mapNumber = mapNumber;
            const response = await client.create(command);
            props.setNewMapId(response);
            setMapFormName('');
            setMapFormPack('');
            setMapNumber(0);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    // create a form for entering a new map.
    const renderNewMapForm = () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="engineName">Map Name</Label>
                    <Input type="text" name="mapName" id="mapName" value={mapFormName} placeholder="Something Epic" onChange={e => setMapFormName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="engineUrl">Map Pack</Label>
                    <Input type="text" name="mapPack" id="mapPack" value={mapFormPack} placeholder="ZDCTFMP1" onChange={e => setMapFormPack(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="engineUrl">Map Number</Label>
                    <Input placeholder="Amount" name="amountWeeksPlayoffs" min={1} max={32} type="number" step="1" value={mapNumber} onChange={e => setMapNumber(parseInt(e.target.value, 10))} />
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!mapFormName || !mapFormPack || mapNumber <= 0} onClick={handleSubmit}>Create New Map</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {renderNewMapForm()}
        </React.Fragment>
    );
};

export default NewMap