import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { OrdinalSuffixOf } from '../../helpers';
import { IPlayerSummaryDraftDto } from '../../WorldDoomLeague';

interface PlayerDraftProps {
    draft: IPlayerSummaryDraftDto[];
}

const PlayerDraft = (props: PlayerDraftProps) => {
    return (
        <Row>
            {props.draft.map((s, idx) => (
                <Col sm='3' className='mx-auto'>
                    <Card className='border-info mb-3'>
                        <CardHeader>
                            <NavLink className='text-white' to={`/season/${s.seasonId}`}>{s.seasonName}</NavLink>
                        </CardHeader>
                        <CardBody>
                            <ol start={s.draftNominationPosition}>
                                <li>{s.teamNominating} nominates {s.playerNominated}</li>
                                <ul>
                                    <li>Sold to {s.teamSoldTo} for {new Intl.NumberFormat('default', { style: 'currency', currency: 'USD' }).format(s.sellPrice)} ({OrdinalSuffixOf(s.teamDraftPosition)})</li>
                                </ul>
                            </ol>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            {props.draft.length <= 0 && (
                <Col>
                    <p className='text-center'>This player has never been drafted.</p>
                </Col>
            )}
        </Row>
    );
};

export default PlayerDraft;