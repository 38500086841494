import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { DamageWeaponCategories, MatchRoundDamageStatsCategories } from '../../../../../helpers';
import { IRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchRoundWeaponsDamageTabsView from './MatchRoundWeaponsDamageTabsView';
import MatchRoundWeaponsDamageTabs from './MatchRoundWeaponsDamageTabs';

interface MatchRoundWeaponsDamageCategoryTabsViewProps {
    category: DamageWeaponCategories;
    categoryObject: MatchRoundDamageStatsCategories;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    perRoundBoxScore: IRoundBoxScoreDto;
};

const MatchRoundWeaponsDamageCategoryTabsView = (props: MatchRoundWeaponsDamageCategoryTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.category}>
                <TabPane tabId={DamageWeaponCategories.Damage}>
                    <MatchRoundWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.DamageWithFlag}>
                    <MatchRoundWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.CarrierDamage}>
                    <MatchRoundWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.CarrierDamageWithFlag}>
                    <MatchRoundWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchRoundWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default MatchRoundWeaponsDamageCategoryTabsView;