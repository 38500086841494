import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { AccuracyWeaponCategories, MatchRoundAccuracyStatsCategories } from '../../../../../helpers';
import { AccuracyAggregate, IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsAccuracyTabsView from './AdHocMatchOverallWeaponsAccuracyTabsView';
import AdHocMatchOverallWeaponsAccuracyTabs from './AdHocMatchOverallWeaponsAccuracyTabs';

interface AdHocMatchOverallWeaponsAccuracyCategoryTabsViewProps {
    category: AccuracyWeaponCategories;
    categoryObject: MatchRoundAccuracyStatsCategories;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    finalBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponsAccuracyCategoryTabsView = (props: AdHocMatchOverallWeaponsAccuracyCategoryTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.category}>
                <TabPane tabId={AccuracyWeaponCategories.Accuracy}>
                    <AdHocMatchOverallWeaponsAccuracyTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsAccuracyTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
                <TabPane tabId={AccuracyWeaponCategories.AccuracyWithFlag}>
                    <AdHocMatchOverallWeaponsAccuracyTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsAccuracyTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default AdHocMatchOverallWeaponsAccuracyCategoryTabsView;