import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { IRoundBoxScoreDto } from '../../../../WorldDoomLeague';

interface MatchRoundPerformanceFragStatsProps {
    activeRound: number,
    perRoundBoxScore: IRoundBoxScoreDto[]
};

const MatchRoundPerformanceFragStats = (props: MatchRoundPerformanceFragStatsProps) => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button color="primary" block id="fragtoggler" style={{ marginBottom: '1rem' }}>
                        Frags
                    </Button>
                    <UncontrolledCollapse toggler="#fragtoggler">
                        <Row>
                            <Col>
                                <h2>Frags</h2>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Frags</th>
                                            <th>Max Frags before cap</th>
                                            <th>Flag Defs</th>
                                            <th>Flag Defs w/ Flag</th>
                                            <th>Deaths</th>
                                            <th>Env. Deaths</th>
                                            <th>Env. Flag Deaths</th>
                                            <th>Suicides</th>
                                            <th>Suicides w/ flag</th>
                                            <th>Team Kills</th>
                                            <th>Longest Spree</th>
                                            <th>Highest Multi Kill</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.perRoundBoxScore[props.activeRound - 2].redTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.frags}</td>
                                                <td>{s.matchStats.highestKillsBeforeCapturing}</td>
                                                <td>{s.matchStats.flagDefenses}</td>
                                                <td>{s.matchStats.flagCarriersKilledWhileHoldingFlag}</td>
                                                <td>{s.matchStats.deaths}</td>
                                                <td>{s.matchStats.environmentalDeaths}</td>
                                                <td>{s.matchStats.environmentalFlagCarrierDeaths}</td>
                                                <td>{s.matchStats.suicides}</td>
                                                <td>{s.matchStats.suicidesWithFlag}</td>
                                                <td>{s.matchStats.teamKills}</td>
                                                <td>{s.matchStats.longestSpree}</td>
                                                <td>{s.matchStats.highestMultiKill}</td>
                                            </tr>
                                        ))}
                                        {props.perRoundBoxScore[props.activeRound - 2].blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.frags}</td>
                                                <td>{s.matchStats.highestKillsBeforeCapturing}</td>
                                                <td>{s.matchStats.flagDefenses}</td>
                                                <td>{s.matchStats.flagCarriersKilledWhileHoldingFlag}</td>
                                                <td>{s.matchStats.deaths}</td>
                                                <td>{s.matchStats.environmentalDeaths}</td>
                                                <td>{s.matchStats.environmentalFlagCarrierDeaths}</td>
                                                <td>{s.matchStats.suicides}</td>
                                                <td>{s.matchStats.suicidesWithFlag}</td>
                                                <td>{s.matchStats.teamKills}</td>
                                                <td>{s.matchStats.longestSpree}</td>
                                                <td>{s.matchStats.highestMultiKill}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Col>
            </Row >
        </React.Fragment>
    );
};

export default MatchRoundPerformanceFragStats;