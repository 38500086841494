import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, FormGroup, Form, Button } from 'reactstrap';
import {
    CreateLeagueCommand,
    ILeaguesDto,
    ILeaguesVm,
    LeagueClient
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';
import DateTimePicker from 'react-datetime-picker';

interface LeagueListProps {
    setLeague: React.Dispatch<React.SetStateAction<number>>;
}

const LeagueList = (props: LeagueListProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<ILeaguesDto[]>([]);
    const [index, setIndex] = useState(0);
    const [leagueFormName, setLeagueFormName] = useState<string>("");
    const [leagueFormShortName, setLeagueFormShortName] = useState<string>("");
    const [leagueFormFounder, setLeagueFormFounder] = useState<string>("");
    const [leagueFormFileName, setLeagueFormFileName] = useState<string>("");
    const [leagueFoundedDate, setLeagueFoundedDate] = useState<Date>(null);
    const [newLeagueId, setNewLeagueId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new LeagueClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<ILeaguesVm>);
                const data = response.leagueList;
                setData(data);
                if (data.length > 0) {
                    handleLeagueChange(data[0].id);
                }
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [newLeagueId]);

    const handleLeagueChange = (value) => {
        setIndex(value);
        props.setLeague(value);
    };

    const handleSubmit = async (evt) => {
        try {
            let client = new LeagueClient();
            const command = new CreateLeagueCommand;
            command.leagueName = leagueFormName;
            command.leagueShortName = leagueFormShortName;
            command.leagueFounder = leagueFormFounder;
            command.leaguePicFilename = leagueFormFileName;
            command.leagueFounded = leagueFoundedDate;
            const response = await client.create(command);
            setNewLeagueId(response);
            setLeagueFormName('');
            setLeagueFormShortName('');
            setLeagueFormFounder('');
            setLeagueFormFileName('');
            setLeagueFoundedDate(null);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    // create a list for each engine.
    const renderLeagueDropdown = () => {
        let select = null;
        if (!loading) {
            if (data.length > 0) {
                select = (
                    data.map(league =>
                        <option key={league.id} value={league.id}>
                            {league.leagueName}
                        </option>));
            } else {
                select = (
                    <option>
                        No leagues currently in the system.
                    </option>);
            }
        } else {
            select = (
                <option>
                    Loading...
                </option>);
        }
        return (select);
    };

    // create a form for entering a new engine.
    const renderNewLeagueForm = () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="leagueName">League Name</Label>
                    <Input type="text" name="leagueName" id="leagueName" value={leagueFormName} placeholder="World Doom League" onChange={e => setLeagueFormName(e.target.value)} />
                    <Label for="leagueShortName">League Short Name</Label>
                    <Input type="text" name="leagueShortName" id="leagueShortName" value={leagueFormShortName} placeholder="WDL" onChange={e => setLeagueFormShortName(e.target.value)} />
                    <Label for="leagueImageFileName">League Image Filename</Label>
                    <Input type="text" name="leagueImageFileName" id="leagueImageFileName" value={leagueFormFileName} placeholder="WDL" onChange={e => setLeagueFormFileName(e.target.value)} />
                    <Label for="leagueFounder">League Founder</Label>
                    <Input type="text" name="leagueFounder" id="leagueFounder" value={leagueFormFounder} placeholder="Ralphis" onChange={e => setLeagueFormFounder(e.target.value)} />
                    <Label for="leagueFoundedDate">League Founded Date</Label>
                    <DateTimePicker id='leagueFoundedDate' name='leagueFoundedDate' onChange={setLeagueFoundedDate} value={leagueFoundedDate} />
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!leagueFormName || !leagueFormShortName || !leagueFormFileName || !leagueFormFounder || !leagueFoundedDate} onClick={handleSubmit}>Create New League</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Form>
                <FormGroup>
                    <Label for="league">League</Label>
                    <Input type="select" name="league" id="leagueSelect" value={index} onChange={(e) => handleLeagueChange(e.target.value)}>
                        {renderLeagueDropdown()}
                    </Input>
                </FormGroup>
            </Form>
            <Form>
                {renderNewLeagueForm()}
            </Form>
        </React.Fragment>
    );
};

export default LeagueList;