import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import {
    IMatchTeamInfoDto,
    IMatchWinningTeamInfoDto,
} from '../../WorldDoomLeague';

interface MatchForfeitSummaryProps {
    teamInfo: IMatchTeamInfoDto,
    seasonName: string,
    doubleForfeit: boolean,
    winningTeam?: IMatchWinningTeamInfoDto
}

const MatchForfeitSummary = (props: MatchForfeitSummaryProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className='text-center mb-3'>{props.seasonName}</h2>
                </Col>
            </Row>
            <Card className='bg-secondary border-white mb-3'>
                <div className="container-fluid">
                    <Row xs="3">
                        <Col>
                            <h2 className='text-left'>
                                <NavLink className='text-info' to={`/team/${props.teamInfo.blueTeamId}`}>
                                    <span className={props.winningTeam && props.winningTeam.winningTeamColor === "b" && ("font-weight-bold")}>
                                        {props.teamInfo.blueTeamName}
                                    </span>
                                </NavLink>
                            </h2>
                        </Col>
                        <Col>
                            <h2 className='text-center'>vs.</h2>
                        </Col>
                        <Col>
                            <h2 className='text-right'>
                                <NavLink className='text-danger' to={`/team/${props.teamInfo.redTeamId}`}>
                                    <span className={props.winningTeam && props.winningTeam.winningTeamColor === "r" && ("font-weight-bold")}>
                                        {props.teamInfo.redTeamName}
                                    </span>
                                </NavLink>
                            </h2>
                        </Col>
                    </Row>
                </div>
            </Card>
            <Row>
                <Col>
                    {props.doubleForfeit && (<h2 className='text-center mb-3'>This match resulted in a double forfeit. Both teams will be assessed -1 points on the season.</h2>)}
                    {!props.doubleForfeit && props.winningTeam && (
                        <React.Fragment>
                            <h2 className='text-center mb-3'>
                                This match resulted in a forfeit. The win was given to {props.winningTeam.winningTeamName}.
                            </h2>
                            <h4 className='text-center mb-3'>
                                The winning team was given 4 points. The losing team was penalized -1 point.
                            </h4>
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        </React.Fragment>);
};

export default MatchForfeitSummary;