import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    IMapSummaryVm,
    MapsClient,
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import MapSummary from './MapSummary';
import MapDetails from './MapDetails';

interface MapRouterProps {
    id: string;   // id of the match (coming from the router)
}

interface MapProps extends RouteComponentProps<MapRouterProps> {
    // Add your regular properties here
}

const Map = (props: MapProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [mapData, setMapData] = useState<IMapSummaryVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MapsClient();
                const response = await client.getMapSummaryById(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<IMapSummaryVm>);
                setMapData(response);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            {mapData && (
                <React.Fragment>
                    <MapSummary
                        mapName={mapData.mapName}
                        mapPack={mapData.mapPack}
                        mapNumber={mapData.mapNumber}
                        mapImages={mapData.mapImages}
                    />
                    <MapDetails
                        teamHomefields={mapData.teamHomefields}
                        seasonsFeaturingMap={mapData.seasonsFeaturingMap}
                        regularSeasonGamesPlayed={mapData.regularSeasonGamesPlayed}
                        playoffsGamesPlayed={mapData.playoffsGamesPlayed}
                        finalsGamesPlayed={mapData.finalsGamesPlayed}
                        mapStats={mapData.mapStats}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Map;