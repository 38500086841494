import StepButtons from './StepButtons'
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import SeasonList from './SeasonList';
import EngineList from './EngineList';
import WadList from './WadList';
import { useState } from 'react';
import { StepWizardChildProps } from 'react-step-wizard';
import LeagueList from './LeagueList';

interface SeasonBasicsProps extends StepWizardChildProps {
    setSeason: React.Dispatch<React.SetStateAction<number>>;
}

const SeasonBasics = (props: SeasonBasicsProps) => {
    const [engineId, setEngineId] = useState(0);
    const [wadId, setWadId] = useState(0);
    const [seasonId, setSeasonId] = useState(0);
    const [leagueId, setLeagueId] = useState(0);

    const handleSetSeason = (season: number) => {
        setSeasonId(season);
        props.setSeason(season);
    };

    return (
        <React.Fragment>
        <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
                <h3 className='text-center'>New Season Basics</h3>
                <p>This first step will fill out the basic details for the new season.</p>
            </Col>
        </Row>
        <Row>
            <Col xs="6" sm="4">
                <EngineList setEngine={setEngineId} />
            </Col>
                <Col xs="6" sm="4">
                    <SeasonList engine={engineId} wad={wadId} league={leagueId} setSeason={handleSetSeason} />
            </Col>
            <Col sm="4">
                <WadList setWad={setWadId} />
            </Col>
        </Row>
        <Row>
            <Col>
                <LeagueList setLeague={setLeagueId} />
            </Col>
        </Row>
        <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <StepButtons {...props} step={1} disabled={engineId <= 0 || wadId <= 0 || seasonId <= 0 || leagueId <= 0 }/>
            </Col>
        </Row>
        </React.Fragment>
    );
};

export default SeasonBasics