import * as React from 'react';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ErrorContext } from '../../../state';
import {
    ApproveDemoCommand,
    DemosClient,
    IUnapprovedDemoDto,
    RejectDemoCommand
} from '../../../WorldDoomLeague';

interface ApproveDemoListProps {
    demo: IUnapprovedDemoDto;
    setUpdatedDemoId: React.Dispatch<React.SetStateAction<number>>;
}

const ApproveDemoList = (props: ApproveDemoListProps) => {
    const error = useContext(ErrorContext);
    const handleAccept = async (evt) => {
        try {
            let client = new DemosClient();
            const command = new ApproveDemoCommand;
            command.demoId = props.demo.id;
            const response = await client.approveDemo(command);
            props.setUpdatedDemoId(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const handleReject = async (evt) => {
        try {
            let client = new DemosClient();
            const command = new RejectDemoCommand;
            command.demoId = props.demo.id;
            const response = await client.rejectDemo(command);
            props.setUpdatedDemoId(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h3>{props.demo.gameName}</h3>
                    <br />
                    <h4>{props.demo.demoSeason}</h4>
                    <br />
                    <h4><NavLink to={`/player/${props.demo.playerId}`}>{props.demo.playerName}</NavLink></h4>
                    <br />
                    <h4><NavLink to={`/player/${props.demo.teamId}`}>{props.demo.teamName}</NavLink></h4>
                    <br />
                    {props.demo.isUploaded && (
                        <h4>Demo File: <NavLink to={props.demo.fileName}>Download</NavLink></h4>
                    )}
                    {props.demo.playerLostDemo && (
                        <h4 className='text-danger'>Player lost the demo file.</h4>
                    )}
                    <br />
                    <div className="clearfix">
                        <Button color="primary" size="lg" className='float-left' onClick={handleAccept}>Accept</Button>
                        <Button color="danger" size="lg" className='float-right' onClick={handleReject}>Reject</Button>
                    </div>
                    <br />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ApproveDemoList;