import * as React from 'react';
import { Label, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import { DeleteSeasonCommand, ISeasonDto, SeasonsClient, UpdateSeasonCommand } from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';

interface EditSeasonButtonsProps {
    season: ISeasonDto;
    seasonName: string;
    signupDate: Date;
    wadId: number;
    engineId: number;
    setUpdatedSeasonId: React.Dispatch<React.SetStateAction<number>>;
}

const EditSeasonButtons = (props: EditSeasonButtonsProps) => {
    const error = useContext(ErrorContext);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [canReset, setCanReset] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);

    const [resetModal, setResetModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const toggleReset = () => setResetModal(!resetModal);
    const toggleDelete = () => setDeleteModal(!deleteModal);

    useEffect(() => {
        if (props.season) {
            checkIfSeasonChanges();
            setCanReset(true);
            setCanDelete(true);
        } else {
            setCanEdit(false);
            setCanReset(false);
            setCanDelete(false);
        }
    }, [props.season, props.seasonName, props.signupDate, props.wadId, props.engineId]);

    const checkIfSeasonChanges = () => {
        if (
            (props.seasonName !== props.season.seasonName ||
                new Date(props.signupDate).getTime() !== new Date(props.season.dateStart).getTime() ||
                props.wadId !== props.season.wadId ||
                props.engineId !== props.season.engineId)
            &&
            (props.seasonName &&
                props.engineId &&
                props.season &&
                props.signupDate &&
                props.wadId)) {
            setCanEdit(true);
        } else {
            setCanEdit(false);
        }
    };

    const handleUpdate = async (evt) => {
        try {
            props.setUpdatedSeasonId(0);
            let client = new SeasonsClient();
            const command = new UpdateSeasonCommand;
            command.seasonId = props.season.id;
            command.dateStart = props.signupDate;
            command.seasonName = props.seasonName;
            command.wadId = props.wadId;
            command.engineId = props.engineId;
            const response = await client.update(props.season.id, command);
            props.setUpdatedSeasonId(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const handleDelete = async (evt) => {
        try {
            props.setUpdatedSeasonId(0);
            let client = new SeasonsClient();
            const command = new DeleteSeasonCommand;
            command.seasonId = props.season.id;
            const response = await client.delete(props.season.id, command);
            props.setUpdatedSeasonId(response);
            toggleDelete();
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };
    /*
    const handleReset = async (evt) => {
        try {
            props.setUpdatedSeasonId(0);
            let client = new SeasonsClient();
            const command = new ResetSeasonCommand;
            command.seasonId = props.season.id;
            const response = await client.reset(props.season.id, command);
            props.setUpdatedSeasonId(response);
            toggleReset();
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };
    */
    const showResetModal = () => {
        return (
            <div>
                <Modal isOpen={resetModal} toggle={toggleReset}>
                    <ModalHeader toggle={toggleReset}>Reset Season</ModalHeader>
                    <ModalBody>
                        This will reset the season back into its original, post-draft state. All stats and demo records in the database from this season will be deleted.
                        <br />
                        <br />
                        You must be COMPLETELY sure you want to do this, as you will need to re-input all games again for this season.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="warning" onClick={toggleReset}>Reset Season</Button>{' '}
                        <Button color="secondary" onClick={toggleReset}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    };

    const showDeleteModal = () => {
        return (
            <div>
                <Modal isOpen={deleteModal} toggle={toggleDelete}>
                    <ModalHeader toggle={toggleDelete}>Delete Season</ModalHeader>
                    <ModalBody>
                        This will completely delete the season from the database, saving no stats, teams or draft records.
                        <br />
                        <br />
                        You must be COMPLETELY sure you want to do this, as you will need to re-input EVERYTHING for the season.
                        <br />
                        <br />
                        If you interrupted a New Season Wizard, you must delete the season using this page to try again.
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={handleDelete}>Delete Season</Button>{' '}
                        <Button color="secondary" onClick={toggleDelete}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Button color="primary" size="lg" block disabled={!canEdit} onClick={handleUpdate}>Update Season</Button>
            <hr className='border-white'/>
            <Button color="warning" size="lg" block disabled={!canReset} onClick={toggleReset}>Reset Season</Button>
            <Button color="danger" size="lg" block disabled={!canDelete} onClick={toggleDelete}>Delete Season</Button>
            {showResetModal()}
            {showDeleteModal()}
        </React.Fragment>
    );
};

export default EditSeasonButtons;