import * as React from 'react';
import {  Row, Col, Card, CardBody, CardImg, CardTitle, CardSubtitle, CardText, CardDeck, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import * as Moment from 'moment';
import 'moment/min/locales';
import { FormatHhMmSs, FormatShortTimeString } from '../../helpers';
import { IMatchMapsPlayedDto } from '../../WorldDoomLeague';
import { NavLink } from 'react-router-dom';

interface MatchMetadataProps {
    roundsPlayed: number,
    gameTimeTotal: string, // iso 8601 duration string
    gameDateTime: Date,
    mapsPlayed: IMatchMapsPlayedDto[],
    forfeit: boolean,
    played: boolean
}

const MatchMetadata = (props: MatchMetadataProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='mb-3'>
                        <CardHeader>
                            Game Metadata
                        </CardHeader>
                        <CardBody>
                            <ListGroup>
                                {props.roundsPlayed > 0 && (<ListGroupItem>{props.roundsPlayed} Rounds Played</ListGroupItem>)}
                                {props.gameTimeTotal !== "PT0S" && (<ListGroupItem>Game Length: {FormatShortTimeString(props.gameTimeTotal)}</ListGroupItem>)}
                                {props.gameDateTime && props.played && (<ListGroupItem>{!props.forfeit && ("Game Played on")}{props.forfeit && ("Game Forfeited on")}: {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'full' } as Intl.DateTimeFormatOptions).format(new Date(props.gameDateTime))}</ListGroupItem>)}
                                {props.gameDateTime && !props.played && (<ListGroupItem>Game Scheduled for: {new Intl.DateTimeFormat('default', { dateStyle: 'full', timeStyle: 'full' } as Intl.DateTimeFormatOptions).format(new Date(props.gameDateTime))}</ListGroupItem>)}
                                {!props.gameDateTime && (<ListGroupItem>Game not yet scheduled.</ListGroupItem>)}
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-3'>
                        <CardHeader>
                            {(!props.played || props.forfeit) && props.mapsPlayed.length > 1 && ("Maps Slated for Play")}
                            {(!props.played || props.forfeit) && props.mapsPlayed.length == 1 && ("Map Slated for Play")}
                            {props.played && !props.forfeit && props.mapsPlayed.length > 1 && ("Maps Played")}
                            {props.played && !props.forfeit && props.mapsPlayed.length == 1 && ("Map Played")}
                        </CardHeader>
                        <CardBody>
                            <CardDeck>
                                {props.mapsPlayed.map((s, idx) => (
                                    <Card className='border-dark mb-3'>
                                        <NavLink className='text-white text-decoration-none' to={`/map/${s.mapId}`}>
                                            {s.imageFilePath !== "" && (<CardImg top width="100%" src={s.imageFilePath} alt="Card image cap" />)}
                                            <CardBody>
                                                <CardTitle tag="h5">{s.mapName}</CardTitle>
                                                <CardText>{s.mapPack}</CardText>
                                                <CardSubtitle tag="h6" className="mb-2 text-muted">{s.mapNumber}</CardSubtitle>
                                            </CardBody>
                                        </NavLink>
                                    </Card>
                                ))}
                             </CardDeck>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>);
};

export default MatchMetadata;