import * as React from "react";
import DocViewer from "./DocViewer";

const Rules = () => {
    return (
        <div>
            <h1 className='text-center'>League Rulebook</h1>
            <p><DocViewer source="https://doomleague.org/rules/rules.pdf" /></p>
        </div>
    );
};

export default Rules;