import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';
import { ITeamGameDto } from '../../WorldDoomLeague';
import TeamGame from './TeamGame';

interface TeamGamesProps {
    regularSeasonGames: ITeamGameDto[];
    playoffsGames: ITeamGameDto[];
    finalsGames: ITeamGameDto[];
}

const TeamGames = (props: TeamGamesProps) => {
    return (
        <React.Fragment>
            {props.regularSeasonGames.length > 0 && (
                <React.Fragment>
                    <h2 className='text-center'>Regular Season</h2>
                    {props.regularSeasonGames.map((s, idx) => (
                        <Row>
                            <Col>
                                <Card className='bg-secondary mb-3'>
                                    <CardHeader>
                                        <div className='text-center'>Week #{s.weekNumber}</div>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col sm='9'>
                                                <TeamGame game={s} />
                                            </Col>
                                            <Col sm='3'>
                                                {s.gameMap.map((u, __idx) => (
                                                    <NavLink className='text-white text-decoration-none' to={`/map/${u.idMap}`}>
                                                        <Card className='border-dark mb-3 resultcard'>
                                                            {u.mapImagePath !== "" && (<CardImg top width="100%" src={u.mapImagePath} alt="Card image cap" />)}
                                                            <CardBody>
                                                                <CardTitle tag="h5">{u.mapName}</CardTitle>
                                                                <CardText>{u.mapPack}</CardText>
                                                                <CardSubtitle tag="h6" className="mb-2 text-muted">{u.mapNumber}</CardSubtitle>
                                                            </CardBody>
                                                        </Card>
                                                    </NavLink>
                                                ))}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    ))}
                </React.Fragment>
            )}
            {props.playoffsGames.length > 0 && (
                <React.Fragment>
                    <h2 className='text-center'>Playoffs</h2>
                    {props.playoffsGames.map((s, idx) => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card className='bg-secondary mb-3'>
                                        <CardHeader>
                                            <div className='text-center'>Week #{s.weekNumber}</div>
                                        </CardHeader>
                                        <CardBody>
                                                <Row>
                                                    <Col sm='9'>
                                                        <TeamGame game={s} />
                                                    </Col>
                                                    <Col sm='3'>
                                                    {s.gameMap.map((u, __idx) => (
                                                        <NavLink className='text-white text-decoration-none' to={`/map/${u.idMap}`}>
                                                            <Card className='border-dark mb-3 resultcard'>
                                                                {u.mapImagePath !== "" && (<CardImg top width="100%" src={u.mapImagePath} alt="Card image cap" />)}
                                                                <CardBody>
                                                                    <CardTitle tag="h5">{u.mapName}</CardTitle>
                                                                    <CardText>{u.mapPack}</CardText>
                                                                    <CardSubtitle tag="h6" className="mb-2 text-muted">{u.mapNumber}</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </NavLink>
                                                        ))}
                                                    </Col>
                                                </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}
            {props.finalsGames.length > 0 && (
                <React.Fragment>
                    <h2 className='text-center'>Finals</h2>
                    {props.finalsGames.map((s, idx) => (
                        <React.Fragment>
                            <Row>
                                <Col>
                                    <Card className='bg-secondary mb-3'>
                                        <CardHeader>
                                            <div className='text-center'>Week #{s.weekNumber}</div>
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col sm='9'>
                                                    <TeamGame game={s} />
                                                </Col>
                                                <Col sm='3'>
                                                    {s.gameMap.map((u, __idx) => (
                                                        <NavLink className='text-white text-decoration-none' to={`/map/${u.idMap}`}>
                                                            <Card className='border-dark mb-3 resultcard'>
                                                                {u.mapImagePath !== "" && (<CardImg top width="100%" src={u.mapImagePath} alt="Card image cap" />)}
                                                                <CardBody>
                                                                    <CardTitle tag="h5">{u.mapName}</CardTitle>
                                                                    <CardText>{u.mapPack}</CardText>
                                                                    <CardSubtitle tag="h6" className="mb-2 text-muted">{u.mapNumber}</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </NavLink>
                                                    ))}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default TeamGames;