import * as React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { FormatHhMmSs, formatdecider } from '../../helpers';
import { ISeasonCombinedStatsDto } from '../../WorldDoomLeague';

interface SeasonCombinedStatsProps {
    combinedStats: ISeasonCombinedStatsDto;
}

const SeasonCombinedStats = (props: SeasonCombinedStatsProps) => {
    return (
        <React.Fragment>
            <Card className='bg-danger mb-3'>
                <CardHeader>
                    <div className='text-center'>Combined Season Stats</div>
                </CardHeader>
                <CardBody>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Rounds Played</th>
                                <th>Total Time Played</th>
                                <th>Captures</th>
                                <th>P. Captures</th>
                                <th>Assists</th>
                                <th>Touches</th>
                                <th>P. Touches</th>
                                <th>Frags</th>
                                <th>Flag Defs</th>
                                <th>Deaths</th>
                                <th>Damage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatdecider(props.combinedStats.roundsPlayed)}</td>
                                <td>{FormatHhMmSs(props.combinedStats.totalGameTime)}</td>
                                <td>{formatdecider(props.combinedStats.totalCaptures)}</td>
                                <td>{formatdecider(props.combinedStats.totalPickupCaptures)}</td>
                                <td>{formatdecider(props.combinedStats.totalAssists)}</td>
                                <td>{formatdecider(props.combinedStats.totalTouches)}</td>
                                <td>{formatdecider(props.combinedStats.totalPickupTouches)}</td>
                                <td>{formatdecider(props.combinedStats.totalFrags)}</td>
                                <td>{formatdecider(props.combinedStats.totalFlagDefs)}</td>
                                <td>{formatdecider(props.combinedStats.totalDeaths)}</td>
                                <td>{formatdecider(props.combinedStats.totalDamage)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default SeasonCombinedStats;