import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { IAdHocMatchSummaryVm } from '../../WorldDoomLeague';
import AdHocMatchOverallPerformanceStats from './overall/performance/AdHocMatchOverallPerformanceStats';
import AdHocMatchOverallStats from './overall/stats/AdHocMatchOverallStats';
import AdHocMatchOverallWeaponsStats from './overall/weapons/AdHocMatchOverallWeaponsStats';

interface AdHocMatchViewProps {
    activeTab: number,
    matchInfo: IAdHocMatchSummaryVm
};

const AdHocMatchView = (props: AdHocMatchViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeTab}>
                <TabPane tabId={1}>
                    <AdHocMatchOverallStats finalBoxScore={props.matchInfo.roundBoxScore} gameMode={props.matchInfo.gameMode} />
                </TabPane>
                <TabPane tabId={2}>
                    <AdHocMatchOverallPerformanceStats finalBoxScore={props.matchInfo.roundBoxScore} gameMode={props.matchInfo.gameMode} />
                </TabPane>
                <TabPane tabId={3}>
                    <AdHocMatchOverallWeaponsStats finalBoxScore={props.matchInfo.roundBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default AdHocMatchView;