import * as React from 'react';
import {  Row, Col, Card, CardBody, CardImg, CardTitle, CardSubtitle, CardText, CardDeck, CardHeader, ListGroup, ListGroupItem } from 'reactstrap';
import * as Moment from 'moment';
import 'moment/min/locales';
import { FormatHhMmSs, FormatShortTimeString } from '../../helpers';
import { GameModeType, GameType, IAdHocMatchMapsPlayedDto } from '../../WorldDoomLeague';
import { NavLink } from 'react-router-dom';

interface AdHocMatchMetadataProps {
    gameTimeTotal: string, // iso 8601 duration string
    gameDateTime: Date,
    mapPlayed: IAdHocMatchMapsPlayedDto,
    gameType: GameModeType
    serverPlayed: string
}

const AdHocMatchMetadata = (props: AdHocMatchMetadataProps) => {

    const GetGameMode = (gamemode: GameModeType) => {
        if (gamemode === GameModeType.Coop) {
            return "Cooperative";
        }
        else if (gamemode === GameModeType.Horde) {
            return "Horde";
        }
        else if (gamemode === GameModeType.CaptureTheFlag) {
            return "CTF";
        }
        else if (gamemode === GameModeType.DeathMatch) {
            return "Deathmatch";
        }
        else if (gamemode === GameModeType.TeamDeathmatch) {
            return "Team Deathmatch";
        }
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='mb-3'>
                        <CardHeader>
                            Game Metadata
                        </CardHeader>
                        <CardBody>
                            <ListGroup>
                                <ListGroupItem>
                                    Game Played on: 
                                    {
                                        new Intl.DateTimeFormat('default',
                                            {
                                                dateStyle: 'full', timeStyle: 'full'
                                            } as Intl.DateTimeFormatOptions).format(new Date(props.gameDateTime))
                                    }
                                </ListGroupItem>
                                <ListGroupItem>
                                    Server Played: {props.serverPlayed}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Game Length: {FormatShortTimeString(props.gameTimeTotal)}
                                </ListGroupItem>
                                <ListGroupItem>
                                    Game Mode: {GetGameMode(props.gameType)}
                                </ListGroupItem>
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <Card className='mb-3'>
                        <CardHeader>
                            Map Played
                        </CardHeader>
                        <CardBody>
                            <CardDeck>
                                <Card className='border-dark mb-3'>
                                    <CardBody>
                                        <CardTitle tag="h5">{props.mapPlayed.mapName}</CardTitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">{props.mapPlayed.mapNumber}</CardSubtitle>
                                    </CardBody>
                                </Card>
                             </CardDeck>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>);
};

export default AdHocMatchMetadata;