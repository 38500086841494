import * as React from 'react';
import { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { ITeamDraftDto, ITeamGameDto, ITeamPlayerDto, ITeamStatsDto, ITeamTradesDto } from '../../WorldDoomLeague';
import TeamCombinedStats from './TeamCombinedStats';
import TeamDraft from './TeamDraft';
import TeamGames from './TeamGames';
import TeamRoster from './TeamRoster';
import TeamTrades from './TeamTrades';

interface TeamViewProps {
    activeTab: number;
    draft: ITeamDraftDto[];
    trades: ITeamTradesDto[];
    regularSeasonGames: ITeamGameDto[];
    playoffsGames: ITeamGameDto[];
    finalsGames: ITeamGameDto[];
    combinedStats: ITeamStatsDto;
    initialPlayers: ITeamPlayerDto[];
    totalGamePlayers: ITeamPlayerDto[];
    currentPlayers: ITeamPlayerDto[];

};

const TeamView = (props: TeamViewProps) => {
    return (
        <React.Fragment>
            <TabContent activeTab={props.activeTab}>
                <TabPane tabId={1}>
                    <TeamDraft draft={props.draft} />
                </TabPane>
                <TabPane tabId={2}>
                    <TeamTrades trades={props.trades} />
                </TabPane>
                <TabPane tabId={3}>
                    <TeamRoster initialPlayers={props.initialPlayers} currentPlayers={props.currentPlayers} totalGamePlayers={props.totalGamePlayers} />
                </TabPane>
                <TabPane tabId={4}>
                    <TeamGames regularSeasonGames={props.regularSeasonGames} playoffsGames={props.playoffsGames} finalsGames={props.finalsGames} />
                </TabPane>
                <TabPane tabId={5}>
                    <TeamCombinedStats combinedStats={props.combinedStats} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default TeamView;