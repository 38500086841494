import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { ErrorContext } from '../../../state';
import { formatdecider, getEnumKeyByEnumValue } from '../../../helpers'
import useQueryParam from '../../../useQueryParam';
import {
    LeaderboardStatsMode,
    ILeaderboardStatsDto,
    LeaderboardStatsClient,
    SeasonsClient,
    ISeasonDto,
    IPlayerLeaderboardSeasonStatsVm,
    MapsClient,
    GameType,
    IPlayedSeasonGameTypeMapsVm,
    IPlayedSeasonGameTypeMapsDto,
    LeaderboardStatsType,
    IPlayedSeasonsVm,
} from '../../../WorldDoomLeague';
import { Col, Container, Row, Table } from 'reactstrap';
import { NavLink } from 'react-router-dom';


export interface LeaderboardRouteParams {
    season: string,
    map: string,
    gameType: GameType,
    statsMode: LeaderboardStatsMode,
    statsType: LeaderboardStatsType
};

const LeaderboardStats = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [season, setSeason] = useState<number>();
    const [map, setMap] = useState<number>();
    const [gameType, setGameType] = useState<string>();
    const [mode, setMode] = useState<string>();
    const [statsType, setStatsType] = useState<string>();
    const [seasonData, setSeasonData] = useState<ISeasonDto[]>([]);
    const [leaderboardData, setLeaderboardData] = useState<ILeaderboardStatsDto[]>([]);
    const [mapData, setMapData] = useState<IPlayedSeasonGameTypeMapsDto[]>([]);

    const [seasonParam, setSeasonParam] = useQueryParam('season', '');
    const [mapParam, setMapParam] = useQueryParam('map', '');
    const [gameTypeParam, setGameTypeParam] = useQueryParam('gametype', '');
    const [statsTypeParam, setStatsTypeParam] = useQueryParam('statstype', '');
    const [modeParam, setModeParam] = useQueryParam('mode', '');

    useEffect(() => {
        const fetchData = async () => {
            if (mode !== undefined && season !== undefined && map !== undefined && gameType !== undefined && statsType !== undefined) {
                setLoading(true);
                try {
                    let client = new LeaderboardStatsClient();
                    const response = await client.get(season, map, mode as LeaderboardStatsMode, statsType as LeaderboardStatsType, gameType as GameType)
                        .then(response => response.toJSON() as Promise<IPlayerLeaderboardSeasonStatsVm>);
                    const data = response.playerLeaderboardStats;
                    setLeaderboardData(data);
                } catch (e) {
                    error.setError(JSON.parse(e.response));
                }
                setLoading(false);
            };
        }

        fetchData();
    }, [mode, season, map, gameType, statsType]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MapsClient();
                const response = await client.getMapsBySeasonAndGameType(season, gameType as GameType)
                    .then(response => response.toJSON() as Promise<IPlayedSeasonGameTypeMapsVm>);
                const data = response.mapList;

                if (mode !== undefined && season !== undefined && map !== undefined && gameType !== undefined && statsType !== undefined) {
                    if (map !== null && data.find(f => f.mapId == map) === undefined) {
                        setMap(null);
                        setMapParam("");
                    }
                }

                setMapData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [season, gameType]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SeasonsClient();
                const response = await client.getPlayedSeasons()
                    .then(response => response.toJSON() as Promise<IPlayedSeasonsVm>);
                const data = response.seasonList;
                setSeasonData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (seasonParam) {
            setSeason(parseInt(seasonParam));
        } else {
            setSeason(null);
        }

        if (mapParam) {
            setMap(parseInt(mapParam));
        } else {
            setMap(null);
        }

        if (gameTypeParam) {
            setGameType(gameTypeParam);
        } else {
            setGameType(GameType.All);
        }

        if (modeParam) {
            setMode(modeParam);
        } else {
            setMode(LeaderboardStatsMode.Total);
        }

        if (statsTypeParam) {
            setStatsType(statsTypeParam);
        } else {
            setStatsType(getEnumKeyByEnumValue(LeaderboardStatsType, LeaderboardStatsType.Points));
        }

    }, []);

    // create a new table for each stat.
    const renderStatsTables = () => {
        var tableArray = null;
        if (!loading) {
            tableArray = (
                <React.Fragment>
                    <Row>
                        <Col>
                            <div className='table-responsive table-sticky table-hover'>
                            <Table size="sm">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>GP</th>
                                                <th>RP</th>
                                                <th>MIN</th>
                                                <th>PTS</th>
                                                <th>CAPS</th>
                                                <th>PCAPS</th>
                                                <th>OCAP%</th>
                                                <th>CAP%</th>
                                                <th>PCAP%</th>
                                                <th>AST</th>
                                                <th>AST%</th>
                                                <th>TCH</th>
                                                <th>PTCH</th>
                                                <th>KILLS</th>
                                                <th>KDR</th>
                                                <th>DMG</th>
                                                <th>DEFS</th>
                                                <th>RAT</th>
                                            </tr>
                                        </thead>
                                <tbody>
                                    {leaderboardData.length > 0 && leaderboardData.map((s, idx) => (
                                        <tr key={s.id}>
                                            <td>{idx + 1}</td>
                                            <td><NavLink to={`/player/${s.id}`}>{s.playerName}</NavLink></td>
                                            <td>{s.gamesPlayed}</td>
                                            <td>{s.roundsPlayed}</td>
                                            <td>{formatdecider(s.minutesPlayed)}</td>
                                            <td>{formatdecider(s.points)}</td>
                                            <td>{formatdecider(s.captures)}</td>
                                            <td>{formatdecider(s.pickupCaptures)}</td>
                                            <td>{formatdecider(s.overallCapRate)}%</td>
                                            <td>{formatdecider(s.capRate)}%</td>
                                            <td>{formatdecider(s.pCapRate)}%</td>
                                            <td>{formatdecider(s.assists)}</td>
                                            <td>{formatdecider(s.assistRate)}%</td>
                                            <td>{formatdecider(s.touches)}</td>
                                            <td>{formatdecider(s.pickupTouches)}</td>
                                            <td>{formatdecider(s.frags)}</td>
                                            <td>{formatdecider(s.kdr)}%</td>
                                            <td>{formatdecider(s.damage)}</td>
                                            <td>{formatdecider(s.flagDefenses)}</td>
                                            <td>{formatdecider(s.wdlrat)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                </Table>
                                </div>
                        </Col>
                    </Row>
                </React.Fragment>);
        } else {
            tableArray = (<span>Loading...</span>);
        }
        return (tableArray);
    };

    const handleModeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setModeParam(e.target.value);

        setMode(e.target.value as any);
    };

    const handleGameTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setGameTypeParam(e.target.value);

        setGameType(e.target.value as any);
    };

    const handleStatsTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStatsTypeParam(e.target.value);

        setStatsType(e.target.value as any);
    };

    const handleSeasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== "All Seasons") {
            setSeasonParam(e.target.value);
            setSeason(parseInt(e.target.value));
        } else {
            setSeason(null);
            setSeasonParam("");
        }
    };

    const handleMapChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value !== "All Maps") {
            setMap(parseInt(e.target.value));
            setMapParam(e.target.value);
        } else {
            setMap(null);
            setMapParam("");
        }

        setMap(parseInt(e.target.value));
    };
        
    const renderSeasonSelect = () => {
        return (
            <select value={season} onChange={(e) => handleSeasonChange(e)}>
                <option key={null} value={null}>
                    All Seasons
                </option>
                {seasonData.length > 0 && seasonData.map(season => (
                    <option key={season.id} value={season.id}>
                        {season.seasonName}
                    </option>
                ))}
            </select>
        );
    };

    const renderGameTypeSelect = () => {
        return (
            <select value={gameType} onChange={(e) => handleGameTypeChange(e)}>
                {Object.keys(GameType).map(key => (
                    <option key={key} value={key}>
                        {GameType[key]}
                    </option>
                ))}
            </select>
        );
    };

    const renderStatsTypeSelect = () => {
        return (
            <select value={statsType} onChange={(e) => handleStatsTypeChange(e)}>
                {Object.keys(LeaderboardStatsType).map(key => (
                    <option key={key} value={key}>
                        {LeaderboardStatsType[key]}
                    </option>
                ))}
            </select>
        );
    };

    const renderModeSelect = () => {
        return (
            <select value={mode} onChange={(e) => handleModeChange(e)}>
                {Object.keys(LeaderboardStatsMode).map(key => (
                    <option key={key} value={key}>
                        {LeaderboardStatsMode[key]}
                    </option>
                ))}
            </select>
        );
    };

    const renderMapSelect = () => {
        return (
            <select value={map} onChange={(e) => handleMapChange(e)}>
                <option key={null} value={null}>
                    All Maps
                </option>
                {mapData.length > 0 && mapData.map(map => (
                    <option key={map.mapId} value={map.mapId}>
                        {map.mapName} | {map.mapPack} | {map.mapNumber}
                    </option>
                ))}
            </select>
        );
    };

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Leaderboards Stats</h1>
            <div className='clearfix'>
                {renderSeasonSelect()} {renderGameTypeSelect()} {renderModeSelect()} {renderStatsTypeSelect()} {renderMapSelect()}
            </div>
            {renderStatsTables()}
        </React.Fragment>
    );
};

export default LeaderboardStats;