import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    ITeamSummaryVm,
    TeamsClient,
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import TeamSummary from './TeamSummary';
import TeamDetails from './TeamDetails';

interface TeamRouterProps {
    id: string;   // id of the match (coming from the router)
}

interface TeamProps extends RouteComponentProps<TeamRouterProps> {
    // Add your regular properties here
}

const Team = (props: TeamProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [teamData, setTeamData] = useState<ITeamSummaryVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new TeamsClient();
                const response = await client.getTeamSummaryById(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<ITeamSummaryVm>);
                setTeamData(response);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            {teamData && (
                <React.Fragment>
                    <TeamSummary
                        seasonName={teamData.seasonName}
                        seasonId={teamData.seasonId}
                        teamName={teamData.teamName}
                        didTeamWinSeason={teamData.didTeamWinSeason}
                        record={teamData.record}
                        homefield={teamData.homefieldMap}
                        teamCaptainId={teamData.teamCaptainId}
                        teamCaptainName={teamData.teamCaptainName}
                    />
                    <TeamDetails
                        draft={teamData.draft}
                        trades={teamData.trades}
                        regularSeasonGames={teamData.regularSeasonGamesPlayed}
                        playoffsGames={teamData.playoffGamesPlayed}
                        finalsGames={teamData.finalsGamesPlayed}
                        combinedStats={teamData.stats}
                        initialPlayers={teamData.initialLineup}
                        currentPlayers={teamData.currentLineup}
                        totalGamePlayers={teamData.allGamePlayers}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Team;