import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IMapSummaryImagesDto } from '../../WorldDoomLeague';
import MapImages from './MapImages';

interface MapSummaryProps {
    mapName: string;
    mapPack: string;
    mapNumber: string;
    mapImages: IMapSummaryImagesDto[];
}

const MapSummary = (props: MapSummaryProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className='text-center'>{props.mapName}</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className='text-center'>{props.mapPack}</h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className='text-center'>{props.mapNumber}</h5>
                </Col>
            </Row>
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <MapImages images={props.mapImages} />
                </Col>
            </Row>
        </React.Fragment>);
};

export default MapSummary;