import * as React from 'react';
import { IRoundBoxScoreDto } from '../../../../WorldDoomLeague';
import MatchRoundPerformanceCaptureStats from './MatchRoundPerformanceCaptureStats';
import MatchRoundPerformanceDamageStats from './MatchRoundPerformanceDamageStats';
import MatchRoundPerformanceFragStats from './MatchRoundPerformanceFragStats';
import MatchRoundPerformancePickupStats from './MatchRoundPerformancePickupStats';
import MatchRoundPerformanceTouchesStats from './MatchRoundPerformanceTouchesStats';

interface MatchRoundPerformanceStatsProps {
    activeRound: number,
    perRoundBoxScore: IRoundBoxScoreDto[]
};

const MatchRoundPerformanceStats = (props: MatchRoundPerformanceStatsProps) => {

    return (
        <React.Fragment>
            <MatchRoundPerformanceFragStats activeRound={props.activeRound} perRoundBoxScore={props.perRoundBoxScore} />
            <MatchRoundPerformanceDamageStats activeRound={props.activeRound} perRoundBoxScore={props.perRoundBoxScore} />
            <MatchRoundPerformanceCaptureStats activeRound={props.activeRound} perRoundBoxScore={props.perRoundBoxScore} />
            <MatchRoundPerformanceTouchesStats activeRound={props.activeRound} perRoundBoxScore={props.perRoundBoxScore} />
            <MatchRoundPerformancePickupStats activeRound={props.activeRound} perRoundBoxScore={props.perRoundBoxScore} />
        </React.Fragment>
    );
};

export default MatchRoundPerformanceStats;