import * as Moment from 'moment';
import 'moment/min/locales';
import { IMatchCapturesDto } from './WorldDoomLeague';

export const FormatShortTimeString = (iso8601duration: string) => {
    if (Moment.duration(iso8601duration).hours() === 0 &&
        Moment.duration(iso8601duration).minutes() === 0 &&
        Moment.duration(iso8601duration).seconds() === 0) {
        return '-';
    } else if (Moment.duration(iso8601duration).hours() > 0) {
        return `${Moment.duration(iso8601duration).hours()}h ${Moment.duration(iso8601duration).minutes()}m ${Moment.duration(iso8601duration).seconds()}s`;
    } else if (Moment.duration(iso8601duration).hours() === 0 &&
        Moment.duration(iso8601duration).minutes() > 0) {
        return `${Moment.duration(iso8601duration).minutes()}m ${Moment.duration(iso8601duration).seconds()}s`;
    } else if (Moment.duration(iso8601duration).minutes() === 0 &&
        Moment.duration(iso8601duration).seconds() > 0) {
        return `${Moment.duration(iso8601duration).seconds()}s`;
    } else {
        return `${Moment.duration(iso8601duration).minutes()}m ${Moment.duration(iso8601duration).seconds()}s`;
    }
};

export const FormatHhMmSs = (iso8601duration: string) => {
    var days = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).days());
    var hours = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).hours());
    var mins = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).minutes());
    var secs = new Intl.NumberFormat('default', { minimumIntegerDigits: 2 }).format(Moment.duration(iso8601duration).seconds());

    if (Moment.duration(iso8601duration).days() !== 0) {
        return `${days}:${hours}:${mins}:${secs}`;
    } else if (Moment.duration(iso8601duration).hours() !== 0) {
        return `${hours}:${mins}:${secs}`;
    } else if (Moment.duration(iso8601duration).minutes() !== 0) {
        return `${mins}:${secs}`;
    } else {
        return `${mins}:${secs}`;
    }
};

export const FormatMinMaxAvg = (minmaxavg?: number) => {
    if (minmaxavg === null) {
        return '-';
    } else {
        return `${new Intl.NumberFormat('default', { maximumFractionDigits: 2 }).format(minmaxavg)}`;
    }
};

export const OrdinalSuffixOf = (i: number) => {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
};

const fraction = new Intl.NumberFormat('default', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const formatter = new Intl.NumberFormat('default', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const formatdecider = (input: number) => {
    if (input % 1 == 0) {
        return fraction.format(input);
    } else {
        return formatter.format(input);
    }
};

export const pcntgformat = (input: number) => {
    return `${new Intl.NumberFormat('default', { maximumFractionDigits: 3, minimumFractionDigits: 3 }).format(input)}`;
};

export const getEnumKeyByEnumValue = <T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null => {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
};

export interface MatchRoundFragStatsCategories {
    category: FragWeaponCategories,
    FragWeaponCategories: string[];
};

export enum FragWeaponCategories {
    Frags = "Frags",
    FragsWithFlag = "Frags With Flag",
    CarrierFrags = "Carrier Frags",
    CarrierFragsWithFlag = "Carrier Frags With Flag"
};

export interface MatchRoundDamageStatsCategories {
    category: DamageWeaponCategories,
    DamageWeaponCategories: string[];
};

export enum DamageWeaponCategories {
    Damage = "Damage",
    DamageWithFlag = "Damage With Flag",
    CarrierDamage = "Carrier Damage",
    CarrierDamageWithFlag = "Carrier Damage With Flag"
};

export interface MatchRoundAccuracyStatsCategories {
    category: AccuracyWeaponCategories,
    AccuracyWeaponCategories: string[];
};

export enum AccuracyWeaponCategories {
    Accuracy = "Accuracy",
    AccuracyWithFlag = "Accuracy With Flag",
};

export const str2bool = (value) => {
    if (value && typeof value === "string") {
        if (value.toLowerCase() === "true") return true;
        if (value.toLowerCase() === "false") return false;
    }
    return value;
};

export const getHighestAssistTable = (captures: IMatchCapturesDto[]) => {
    var assistLength = 0;
    captures.map((s, idx) => {
        if (s.assists.length - 1 > assistLength) {
            assistLength = s.assists.length - 1
        }
    });
    return assistLength;
};