import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { DamageWeaponCategories, MatchRoundDamageStatsCategories } from '../../../../../helpers';
import { IMatchFinalBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchOverallWeaponsDamageTabsView from './MatchOverallWeaponsDamageTabsView';
import MatchOverallWeaponsDamageTabs from './MatchOverallWeaponsDamageTabs';

interface MatchOverallWeaponsDamageCategoryTabsViewProps {
    category: DamageWeaponCategories;
    categoryObject: MatchRoundDamageStatsCategories;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    finalBoxScore: IMatchFinalBoxScoreDto;
};

const MatchOverallWeaponsDamageCategoryTabsView = (props: MatchOverallWeaponsDamageCategoryTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.category}>
                <TabPane tabId={DamageWeaponCategories.Damage}>
                    <MatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.DamageWithFlag}>
                    <MatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.CarrierDamage}>
                    <MatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.CarrierDamageWithFlag}>
                    <MatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default MatchOverallWeaponsDamageCategoryTabsView;