import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import {
    DemosClient,
    IUnapprovedDemoVm,
    IUnapprovedDemoDto
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';
import ApproveDemoList from './ApproveDemoList';

interface ApproveDemosProps {
    // Add your regular properties here
}

const ApproveDemos = (props: ApproveDemosProps) => {
    const [loading, setLoading] = useState(true);
    const error = useContext(ErrorContext);
    const [demosData, setDemosData] = useState<IUnapprovedDemoDto[]>([]);
    const [updatedDemoId, setUpdatedDemoId] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new DemosClient();
                const response = await client.getUnapprovedDemos()
                    .then(response => response.toJSON() as Promise<IUnapprovedDemoVm>);
                const data = response.demoList;
                setDemosData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [updatedDemoId]);

    return (
        <React.Fragment>
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <h3 className='text-center'>Approve Demos</h3>
                    <p>Please approve all pending demos so they can be downloaded from the site. You must approve demos marked missing to acknowledge the need for appropriate punishment.</p>
                    {demosData && demosData.length > 0 && demosData.map((s, idx) => (
                        <ApproveDemoList demo={s} setUpdatedDemoId={setUpdatedDemoId} />
                    ))}
                    {demosData && demosData.length <= 0 && (
                        <h2 className='text-center'>No demos to process right now.</h2>
                    )}
                    {loading && (<h2 className='text-center'>Loading...</h2>)}
                    <br />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ApproveDemos;