import { useState, useEffect } from 'react';
import * as React from 'react';
import {
    Collapse,
    Container,
    Nav,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import './NavMenu.css';
import SeasonNav from './SeasonNav';

function NavMenu(props) {
    const [isOpen, setIsOpen] = useState(false);
    
    function toggle() {
        setIsOpen(!isOpen);
    };

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow bg-danger">
                <NavbarBrand tag={Link} to="/" aria-label="World Doom League"><img src="/images/wdllogo.png" height="50" width="358" className='img-fluid' alt="World Doom League" /></NavbarBrand>
                    <NavbarToggler onClick={toggle} className="mr-2"/>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={isOpen} navbar>
                        <Nav className="navbar-nav flex-grow container-fluid" navbar>
                            <NavItem>
                                <NavLink tag={Link} to="/matches">Matches</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/results">Results</NavLink>
                            </NavItem>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Seasons
                                </DropdownToggle>
                                <SeasonNav />
                            </UncontrolledDropdown>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>
                                    Stats
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem header>
                                        Leaderboards
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavLink tag={Link} to='/stats/leaderboards'>Leaderboards</NavLink>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <NavLink tag={Link} to="/players">Players</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/demos">Demos</NavLink>
                            </NavItem>
                            <LoginMenu />
                        </Nav>
                    </Collapse>
            </Navbar>
        </header>
    );
}

export default NavMenu;