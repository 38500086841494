import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { AccuracyWeaponCategories, MatchRoundAccuracyStatsCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsAccuracyCategoryTabsView from './AdHocMatchOverallWeaponsAccuracyCategoryTabsView';
import AdHocMatchOverallWeaponsAccuracyCategoriesTabs from './AdHocMatchOverallWeaponsAccuracyCategoriesTabs';

interface AdHocMatchOverallWeaponsAccuracyStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto
};

const AdHocMatchOverallWeaponsAccuracyStats = (props: AdHocMatchOverallWeaponsAccuracyStatsProps) => {
    const [activeCategoryTab, setActiveCategoryTab] = useState<AccuracyWeaponCategories>();
    const [activeWeapon, setActiveWeapon] = useState<string>();
    const [category, setCategory] = useState<MatchRoundAccuracyStatsCategories>();
    const [categories, setCategories] = useState<MatchRoundAccuracyStatsCategories[]>([]);


    const CreateStatCategories = (match: IAdHocRoundBoxScoreDto ) => {
        var categories = [] as MatchRoundAccuracyStatsCategories[];

        match.redTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.accuracyStats.accuracyByWeapon.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.Accuracy)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.Accuracy, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.AccuracyWithFlag)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.AccuracyWithFlag, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }
        });

        match.blueTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.accuracyStats.accuracyByWeapon.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.Accuracy)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.Accuracy, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.AccuracyWithFlag)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.AccuracyWithFlag, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }
        });

        match.greenTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.accuracyStats.accuracyByWeapon.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.Accuracy)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.Accuracy, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.AccuracyWithFlag)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.AccuracyWithFlag, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }
        });

        match.nonTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.accuracyStats.accuracyByWeapon.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.Accuracy)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.Accuracy, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.accuracyStats.accuracyByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.Accuracy).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== AccuracyWeaponCategories.AccuracyWithFlag)) {
                    var newcat: MatchRoundAccuracyStatsCategories = { category: AccuracyWeaponCategories.AccuracyWithFlag, AccuracyWeaponCategories: [] as string[] } as MatchRoundAccuracyStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === AccuracyWeaponCategories.AccuracyWithFlag).AccuracyWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }
        });

        return categories;
    };

    useEffect(() => {
        var newcats = CreateStatCategories(props.finalBoxScore);

        if (newcats.length > 0) {
            setCategories(newcats);
            setCategory(newcats[0]);
            setActiveCategoryTab(newcats[0].category);
            setActiveWeapon(newcats[0].AccuracyWeaponCategories[0]);
        }
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='bg-dark mb-3'>
                        <CardHeader>
                            Accuracy
                        </CardHeader>
                        <CardBody>
                            <AdHocMatchOverallWeaponsAccuracyCategoriesTabs activeCategoryTab={activeCategoryTab}
                                setActiveCategoryTab={setActiveCategoryTab}
                                categories={categories}
                                setCategory={setCategory}
                                activeWeapon={activeWeapon}
                                setActiveWeapon={setActiveWeapon}
                            />
                            <AdHocMatchOverallWeaponsAccuracyCategoryTabsView activeWeapon={activeWeapon}
                                setActiveWeapon={setActiveWeapon}
                                category={activeCategoryTab}
                                categoryObject={category}
                                finalBoxScore={props.finalBoxScore}
                                    />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdHocMatchOverallWeaponsAccuracyStats;