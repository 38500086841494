import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'reactstrap';
import {
    GameModeType,
    IAdHocMatchLineScoreDto,
    IAdHocMatchWinnerInfoDto
} from '../../WorldDoomLeague';

interface AdHocPostMatchSummaryProps {
    lineScore: IAdHocMatchLineScoreDto,
    winningTeam?: IAdHocMatchWinnerInfoDto,
    gameMode: GameModeType,
    greenCount: number
}

const AdHocPostMatchSummary = (props: AdHocPostMatchSummaryProps) => {
    const DetermineSummaryBasedOnGameMode = (gamemode: GameModeType, greencount: number) => {
        if (gamemode === GameModeType.CaptureTheFlag || gamemode === GameModeType.TeamDeathmatch) {
            return (
                <React.Fragment>
                    <Row xs="3">
                        <Col>
                            <h2 className='text-left'>Blue Team</h2>
                        </Col>
                        <Col>
                            <h2 className='text-center'>
                                <span>
                                    {props.lineScore.roundScore.blueScore}
                                </span>
                                <span> - </span>
                                <span>
                                    {props.lineScore.roundScore.redScore}
                                </span>
                            </h2>
                        </Col>
                        <Col>
                            <h2 className='text-right'>Red Team</h2>
                        </Col>
                    </Row>
                    {greencount > 0 && (
                        <React.Fragment>
                            <Row xs="3">
                                <Col className='col-md-6 offset-md-3'>
                                    <h2 className='text-center'>{props.lineScore.roundScore.greenScore}</h2>
                                </Col>
                            </Row>
                            <Row xs="3">
                                <Col className='col-md-6 offset-md-3'>
                                    <h2 className='text-center'>Green Team</h2>
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </React.Fragment>
            );
        }
        else {
            return (<React.Fragment>
                </React.Fragment>);
        }
    };

    return (
        <React.Fragment>
            <Card className='bg-secondary border-white mb-3'>
                <div className="container-fluid">
                    <Row>
                        <Col className='col-md-6 offset-md-3'>
                            <h3 className='text-center'>{props.winningTeam.winner}</h3>
                        </Col>
                    </Row>
                    {DetermineSummaryBasedOnGameMode(props.gameMode, props.greenCount)}
                </div>
            </Card>
        </React.Fragment>);
};

export default AdHocPostMatchSummary;