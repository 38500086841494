import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    IPlayerSummaryVm,
    PlayersClient,
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import PlayerSummary from './PlayerSummary';
import PlayerDetails from './PlayerDetails';

interface PlayerRouterProps {
    id: string;   // id of the match (coming from the router)
}

interface PlayerProps extends RouteComponentProps<PlayerRouterProps> {
    // Add your regular properties here
}

const Player = (props: PlayerProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [playerData, setPlayerData] = useState<IPlayerSummaryVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new PlayersClient();
                const response = await client.getPlayerSummaryById(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<IPlayerSummaryVm>);
                setPlayerData(response);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            {playerData && (
                <React.Fragment>
                    <PlayerSummary
                        playerName={playerData.name}
                        totalRecord={playerData.totalRecord}
                        regularSeason={playerData.regularSeasonRecord}
                        playoffs={playerData.playoffRecord}
                        finals={playerData.finalsRecord}
                    />
                    <PlayerDetails
                        draft={playerData.drafts}
                        captainDraft={playerData.captainDrafts}
                        trades={playerData.trades}
                        combinedStats={playerData.playerAllTimeStats}
                        seasonsPlayedIn={playerData.seasonsPlayedIn}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Player;