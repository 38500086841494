import * as React from 'react';
import { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import './mapsummarycarousel.css'
import { IMapSummaryImagesDto } from '../../WorldDoomLeague';

interface MapImagesProps {
    images: IMapSummaryImagesDto[];
}

const MapImages = (props: MapImagesProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === props.images.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? props.images.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = props.images.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.imagePath}
                className='mapSummaryCarouoselItem'
            >
                <img src={item.imagePath} alt={item.imageCaption} />
                <CarouselCaption captionText={item.imageCaption} captionHeader={''} />
            </CarouselItem>
        );
    });

    // A way to view the uploaded images.
    const renderMapImageCarousel = () => {
        var jsx = null;
        {
            props.images.length > 0 && (
                jsx = (
                    <React.Fragment>
                        <Carousel
                            activeIndex={activeIndex}
                            next={next}
                            previous={previous}
                            className='mapSummaryCarouosel mb-3'
                        >
                            <CarouselIndicators items={props.images} activeIndex={activeIndex} onClickHandler={goToIndex} />
                            {slides}
                            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>
                    </React.Fragment>
                ))
        };

        {
            props.images.length <= 0 && (
                jsx = (
                    <React.Fragment>
                        <h2>No map image files for this map.</h2>
                    </React.Fragment>
                ))
        };

        return jsx;
    };

    return (
        <React.Fragment>
            {renderMapImageCarousel()}
        </React.Fragment>
    );
};

export default MapImages;