import * as React from 'react';
import { useContext, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, CardText, FormGroup, Label, Input, FormText, Button, Form, Spinner } from 'reactstrap';
import {
    DemosClient,
    FileParameter,
    FilesClient,
    IMyDemoDto,
    MarkDemoAsMissingCommand,
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';

interface DemoItemProps {
    demo: IMyDemoDto;
    setChangedDemoId: React.Dispatch<React.SetStateAction<number>>;
}

const DemoItem = (props: DemoItemProps) => {
    const error = useContext(ErrorContext);
    const [file, setFile] = useState<FileParameter>();
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const handleSubmit = async (evt) => {
        try {
            let client = new FilesClient();
            setIsUploading(true);
            const response = await client.createDemoFile(file, props.demo.id);
            props.setChangedDemoId(response);
            setFile(null);
            setIsUploading(false);
        } catch (e) {
            error.setError(JSON.parse(e.response));
            setIsUploading(false);
        }
    };

    const handleSetMissing = async (evt) => {
        try {
            let client = new DemosClient();
            const command = new MarkDemoAsMissingCommand();
            command.demoId = props.demo.id;
            const response = await client.markDemoAsMissing(command);
            props.setChangedDemoId(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const handleUpload = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        const upload: FileParameter = {
            data: evt.target.files[0],
            fileName: evt.target.files[0].name
        };
        setFile(upload);
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='border-white mb-3'>
                        <CardBody>
                            <CardTitle tag="h3">{props.demo.gameName}</CardTitle>
                            <CardSubtitle tag="h4">{props.demo.demoSeason}</CardSubtitle>
                            <Form>
                                <FormGroup>
                                    <Label for="File">Zipped Demo Files</Label>
                                    <Input type="file" name="file" id="file" key={props.demo.id} onChange={handleUpload} />
                                    <FormText color="muted" className='mb-3'>
                                        Max upload: 200MB
                                        <br />
                                        All demo files for the game must be included, and all files MUST be zipped.
                                    </FormText>
                                    {isUploading && (
                                        <React.Fragment>
                                            <Spinner color="light" />
                                            <h3>Uploading...</h3>
                                        </React.Fragment>
                                    )}
                                </FormGroup>
                            </Form>
                            <hr />
                            <div className="clearfix">
                                <Button color="primary" size="lg" className='float-left' disabled={!file} onClick={handleSubmit}>Upload Demo</Button>
                                <Button color="danger" size="lg" className='float-right' onClick={handleSetMissing}>Mark Demo as Missing</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DemoItem;