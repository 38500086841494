import * as React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { IMapTeamHomefieldsDto } from '../../WorldDoomLeague';

interface MapTeamHomefieldsProps {
    teamHomefields: IMapTeamHomefieldsDto[];
}

const MapTeamHomefields = (props: MapTeamHomefieldsProps) => {
    return (
        <Row>
            {props.teamHomefields.map((s, idx) => (
                <Col sm='3' className='mx-auto'>
                    <Card className='bg-secondary mb-3'>
                        <CardHeader>
                            <NavLink className='text-white' to={`/season/${s.seasonId}`}>{s.seasonName}</NavLink>
                        </CardHeader>
                        <CardBody>
                            <NavLink to={`/team/${s.teamId}`}>{s.teamName}</NavLink>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            {props.teamHomefields.length <= 0 && (
                <p>No team has picked this map for a homefield yet</p>
            )}
        </Row>
    );
};

export default MapTeamHomefields;