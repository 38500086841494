import { useState, useEffect } from 'react';
import * as React from 'react';
import Roles from '../../Roles';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavLink,
    NavItem,
    Row,
    Col,
    Container
} from 'reactstrap';
import { Link } from 'react-router-dom';

/* eslint-disable no-unused-expressions */

interface AdminMenuProps {
    isAuthenticated: boolean,
    role: string[]
};

const AdminMenu = (props: AdminMenuProps) => {
    const [showAdminMenu, setShowAdminMenu] = useState(false);
    const [showAdmin, setShowAdmin] = useState(false);
    const [showEditGames, setShowEditGames] = useState(false);

    function toggleShowAdminMenu(roles) {
        if (roles.includes(Roles.Admin) ||
            roles.includes(Roles.DemoAdmin) ||
            roles.includes(Roles.StatsRunner)) {
            setShowAdminMenu(true);
        }
    };

    function toggleShowEditGames(roles) {
        if (roles.includes(Roles.Admin) ||
            roles.includes(Roles.StatsRunner)) {
            setShowEditGames(true);
        }
    };

    function toggleShowAdmin(roles) {
        if (roles.includes(Roles.Admin)) {
            setShowAdmin(true);
        }
    };

    useEffect(() => {
        if (props.isAuthenticated && props.role) {
            toggleShowAdminMenu(props.role);
            toggleShowEditGames(props.role);
            toggleShowAdmin(props.role);
        }
    }, []);

    return (
        <React.Fragment>
            {showAdminMenu && (
                <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                        Admin
                    </DropdownToggle>
                    <DropdownMenu style={{width:700}} right>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    {showEditGames && (
                                        <React.Fragment>
                                            <DropdownItem divider />
                                            <DropdownItem header>
                                                        Wizards
                                            </DropdownItem>
                                            <DropdownItem divider />
                                        </React.Fragment>
                                    )}
                                    {showAdmin && (
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/admin/newseasonwizard">New Season Wizard</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                    )}
                                    {showEditGames && (
                                        <DropdownItem>
                                            <NavItem>
                                                <NavLink tag={Link} to="/admin/processgamewizard">Process Game Wizard</NavLink>
                                            </NavItem>
                                        </DropdownItem>
                                    )}
                                    {showEditGames && (
                                        <div>
                                            <DropdownItem divider />
                                            <DropdownItem header>
                                                    Manage games
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/admin/undogame">Undo Game</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/admin/forfeitgame">Forfeit Game</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/admin/schedulegames">Schedule Games</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/admin/createplayoffgame">Create Playoff Game</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/admin/deleteplayoffgame">Delete Playoff Game</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                        </div>
                                            )}
                                        </Col>
                                        <Col sm="4">
                            {showAdmin && (
                                <div>
                                    <DropdownItem divider />
                                    <DropdownItem header>
                                        Manage Rosters
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/tradeplayertoteam">Trade Player To Team</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/tradeplayertofreeagency">Trade Player To Free Agency</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/promoteplayertocaptain">Promote Player to Captain</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/reverselasttrade">Reverse Last Trade</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem header>
                                        Modify League Data
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/editseasons">Edit Seasons</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/editplayers">Edit Players</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/editusers">Edit Users</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/editteams">Edit Teams</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/editmaps">Edit Maps</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/editmapimages">Edit Map Images</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/admin/selecthomefieldmaps">Select Homefield Maps</NavLink>
                                        </NavItem>
                                    </DropdownItem>
                                </div>
                                    )}
                                </Col>
                                <Col sm="4">
                                    {showAdminMenu && (
                                        <React.Fragment>
                                            <DropdownItem divider />
                                            <DropdownItem header>
                                            Demos
                                            </DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/admin/approvedemos">Approve Demos</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <NavItem>
                                                    <NavLink tag={Link} to="/admin/managedemos">Manage Demos</NavLink>
                                                </NavItem>
                                            </DropdownItem>
                                        </React.Fragment>
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )}
        </React.Fragment>
    );
};

export default AdminMenu;