import { useState } from 'react';
import * as React from 'react';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import SeasonBasics from './SeasonBasics';
import AddPlayers from './AddPlayers';
import AddTeams from './AddTeams';
import CreateWeeks from './CreateWeeks';
import RegisterDraft from './RegisterDraft';
import CreateGames from './CreateGames';
import { Jumbotron, Container,  } from 'reactstrap';
import { IPlayerDto } from '../../../WorldDoomLeague';
import { ICaptainList, IPlayerList, IWeekGameTeamList } from './NewSeasonWizardInterfaces';

const NewSeasonWizard = (props: StepWizardChildProps) => {
    const [seasonId, setSeasonId] = useState(0);
    const [regSeasonWeeks, setRegSeasonWeeks] = useState(0);
    const [playerList, setPlayerList] = useState<IPlayerList[]>([]);
    const [captainList, setCaptainList] = useState<ICaptainList[]>([]);
    const [weekTeamList, setWeekTeamList] = useState<IWeekGameTeamList[]>([]);

    return (
        <Container>
            <h3>New Season Wizard</h3>
            <Jumbotron>
                <StepWizard
                    isHashEnabled={false}
                >
                    <SeasonBasics {...props} setSeason={setSeasonId} />
                    <AddPlayers {...props} setPlayerList={setPlayerList} />
                    <AddTeams {...props} players={playerList} setCaptains={setCaptainList} setPlayers={setPlayerList} season={seasonId} />
                    <RegisterDraft {...props} captains={captainList} setCaptains={setCaptainList} players={playerList} setPlayers={setPlayerList} season={seasonId} />
                    <CreateWeeks {...props} season={seasonId} setRegularSeasonWeeks={setRegSeasonWeeks} />
                    <CreateGames {...props} season={seasonId} teams={weekTeamList} setTeams={setWeekTeamList} />
                </StepWizard>
            </Jumbotron>
        </Container>
    );
};

export default NewSeasonWizard;