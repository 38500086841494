import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { formatdecider, FormatHhMmSs, FormatShortTimeString, getHighestAssistTable } from '../../../../helpers';
import { GameModeType, IAdHocMatchStatsDto, IAdHocRoundBoxScoreDto } from '../../../../WorldDoomLeague';

interface AdHocMatchOverallStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto,
    gameMode: GameModeType
};

const AdHocMatchOverallStats = (props: AdHocMatchOverallStatsProps) => {
    const createAssistRows = (amountAssistRows: number) => {
        var assistRows = [];
        for (var i = 0; i < amountAssistRows; i++) {
            assistRows.push(<th>Assist #{i + 1}</th>);
        }
        return assistRows;
    };

    const createPadRows = (amountPadRows: number) => {
        var padRows = [];
        for (var i = 0; i < amountPadRows; i++) {
            padRows.push(<td></td>);
        }
        return padRows;
    };

    const CreateTeamHeader = (gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (<tr>
                <th>Team</th>
                <th>Points</th>
                <th>Captures</th>
                <th>P. Captures</th>
                <th>Touches</th>
                <th>P. Touches</th>
                <th>Assists</th>
                <th>Frags</th>
                <th>Deaths</th>
                <th>KDR</th>
                <th>Flag Defs</th>
                <th>Damage</th>
                <th>Powerups</th>
            </tr>);
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (<tr>
                <th>Team</th>
                <th>Points</th>
                <th>Frags</th>
                <th>Team Frags</th>
                <th>Deaths</th>
                <th>KDR</th>
                <th>Damage</th>
                <th>Powerups</th>
            </tr>);
        }
        else
        {
            return (<tr>
                <th>Points</th>
                <th>Frags</th>
                <th>Deaths</th>
                <th>KDR</th>
                <th>Damage</th>
                <th>Powerups</th>
            </tr>);
        }
    };

    const CreatePlayerHeader = (gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (<tr>
                <th>Name</th>
                <th>Pts</th>
                <th>Caps</th>
                <th>PCaps</th>
                <th>Tchs</th>
                <th>PTchs</th>
                <th>Asts</th>
                <th>Frags</th>
                <th>Deaths</th>
                <th>KDR</th>
                <th>FlagDefs</th>
                <th>Dmg</th>
                <th>Pow</th>
                <th>RAT</th>
            </tr>);
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (<tr>
                <th>Name</th>
                <th>Pts</th>
                <th>Frags</th>
                <th>Team Frags</th>
                <th>Deaths</th>
                <th>KDR</th>
                <th>Dmg</th>
                <th>Pow</th>
            </tr>);
        }
        else {
            return (<tr>
                <th>Name</th>
                <th>Frags</th>
                <th>Deaths</th>
                <th>KDR</th>
                <th>Damage</th>
                <th>Powerups</th>
            </tr>);
        }
    };

    const CreateTeamStatsRows = (boxscore: IAdHocMatchStatsDto, gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (
                <React.Fragment>
                    <td>{boxscore.points}</td>
                    <td>{boxscore.captures}</td>
                    <td>{boxscore.pickupCaptures}</td>
                    <td>{boxscore.flagTouches}</td>
                    <td>{boxscore.pickupFlagTouches}</td>
                    <td>{boxscore.assists}</td>
                    <td>{boxscore.frags}</td>
                    <td>{boxscore.deaths}</td>
                    <td>{formatdecider(boxscore.kdr)}</td>
                    <td>{boxscore.flagDefenses}</td>
                    <td>{boxscore.damage}</td>
                    <td>{boxscore.powerups}</td>
                </React.Fragment>
            );
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (
                <React.Fragment>
                    <td>{boxscore.points}</td>
                    <td>{boxscore.frags}</td>
                    <td>{boxscore.teamKills}</td>
                    <td>{boxscore.deaths}</td>
                    <td>{formatdecider(boxscore.kdr)}</td>
                    <td>{boxscore.damage}</td>
                    <td>{boxscore.powerups}</td>
                </React.Fragment>
            );
        }
        else {
            return (<React.Fragment>
                <td>{boxscore.points}</td>
                <td>{boxscore.frags}</td>
                <td>{boxscore.deaths}</td>
                <td>{formatdecider(boxscore.kdr)}</td>
                <td>{boxscore.damage}</td>
                <td>{boxscore.powerups}</td>
            </React.Fragment>);
        }
    };

    const CreatePlayerStatsRows = (boxscore: IAdHocMatchStatsDto, gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (
                <React.Fragment>
                    <td>{boxscore.points}</td>
                    <td>{boxscore.captures}</td>
                    <td>{boxscore.pickupCaptures}</td>
                    <td>{boxscore.flagTouches}</td>
                    <td>{boxscore.pickupFlagTouches}</td>
                    <td>{boxscore.assists}</td>
                    <td>{boxscore.frags}</td>
                    <td>{boxscore.deaths}</td>
                    <td>{formatdecider(boxscore.kdr)}%</td>
                    <td>{boxscore.flagDefenses}</td>
                    <td>{boxscore.damage}</td>
                    <td>{boxscore.powerups}</td>
                    <td>{formatdecider(boxscore.wdlrat)}</td>
                </React.Fragment>
            );
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (
                <React.Fragment>
                    <td>{boxscore.points}</td>
                    <td>{boxscore.frags}</td>
                    <td>{boxscore.teamKills}</td>
                    <td>{boxscore.deaths}</td>
                    <td>{formatdecider(boxscore.kdr)}</td>
                    <td>{boxscore.damage}</td>
                    <td>{boxscore.powerups}</td>
                </React.Fragment>
            );
        }
        else {
            return (<React.Fragment>
                <td>{boxscore.frags}</td>
                <td>{boxscore.deaths}</td>
                <td>{formatdecider(boxscore.kdr)}</td>
                <td>{boxscore.damage}</td>
                <td>{boxscore.powerups}</td>
            </React.Fragment>);
        }
    };

    return (
        <React.Fragment>
            {(props.gameMode === GameModeType.CaptureTheFlag || props.gameMode === GameModeType.TeamDeathmatch) && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                {CreateTeamHeader(props.gameMode)}
                            </thead>
                            <tbody>
                                {props.finalBoxScore.redTeamPlayerRoundBoxScore.length > 0 && (
                                    <tr className='bg-danger'>
                                        <th scope="row">Red Team</th>
                                        {CreateTeamStatsRows(props.finalBoxScore.redTeamBoxScore, props.gameMode)}
                                    </tr>
                                )}
                                {props.finalBoxScore.blueTeamPlayerRoundBoxScore.length > 0 && (
                                    <tr className='bg-primary'>
                                        <th scope="row">Blue Team</th>
                                        {CreateTeamStatsRows(props.finalBoxScore.blueTeamBoxScore, props.gameMode)}
                                    </tr>
                                )}
                                {props.finalBoxScore.greenTeamPlayerRoundBoxScore.length > 0 && (
                                    <tr className='bg-success'>
                                        <th scope="row">Green Team</th>
                                        {CreateTeamStatsRows(props.finalBoxScore.greenTeamBoxScore, props.gameMode)}
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            {CreatePlayerHeader(props.gameMode)}
                        </thead>
                        <tbody>
                            {props.finalBoxScore.redTeamPlayerRoundBoxScore.map((s, idx) => (
                                <tr className='bg-danger'>
                                    <th scope="row">{s.playerName}</th>
                                    {CreatePlayerStatsRows(s.matchStats, props.gameMode)}
                                </tr>
                            ))}
                            {props.finalBoxScore.blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                <tr className='bg-primary'>
                                    <th scope="row">{s.playerName}</th>
                                    {CreatePlayerStatsRows(s.matchStats, props.gameMode)}
                                </tr>
                            ))}
                            {props.finalBoxScore.greenTeamPlayerRoundBoxScore.map((s, idx) => (
                                <tr className='bg-success'>
                                    <th scope="row">{s.playerName}</th>
                                    {CreatePlayerStatsRows(s.matchStats, props.gameMode)}
                                </tr>
                            ))}
                            {props.finalBoxScore.nonTeamPlayerRoundBoxScore.map((s, idx) => (
                                <tr className='bg-secondary'>
                                    <th scope="row">{s.playerName}</th>
                                    {CreatePlayerStatsRows(s.matchStats, props.gameMode)}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {props.finalBoxScore.captures.length > 0 && (
                <Row>
                    <Col xs="auto" md={{ size: 10, offset: 1 }}>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Capture</th>
                                    {createAssistRows(getHighestAssistTable(props.finalBoxScore.captures))}
                                </tr>
                            </thead>
                            <tbody>
                                {props.finalBoxScore.captures.map((s, idx) => (
                                    <tr className={s.teamColor == "r" ? 'bg-danger' : 'bg-primary'}>
                                        <td>{s.captureNumber}</td>
                                        <td>{FormatHhMmSs(s.eventTime)} - {s.playerName} ({FormatShortTimeString(s.captureAmountTime)})</td>
                                        {s.assists.filter(f => f !== s.assists[0]).map((t, _idx) => (
                                            <td>{FormatHhMmSs(t.eventTime)} - {t.playerName}</td>
                                        ))}
                                        {s.assists.length - 1 < getHighestAssistTable(props.finalBoxScore.captures) &&
                                            createPadRows(getHighestAssistTable(props.finalBoxScore.captures) - (s.assists.length - 1))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default AdHocMatchOverallStats;