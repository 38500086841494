import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { ITeamGameDto } from '../../WorldDoomLeague';

interface TeamGameProps {
    game: ITeamGameDto;
}

const TeamGame = (props: TeamGameProps) => {
    return (
        <React.Fragment>
            <Card className='border-white mb-3 gamecard'>
                <CardBody>
                    <NavLink className='text-white text-decoration-none' to={`/matches/${props.game.idGame}`}>
                        {props.game.gamePlayed == false && (
                            <React.Fragment>
                                <div className='clearfix'>
                                    <span className='float-left h4 text-danger'>{props.game.redTeamName}</span>
                                    <span className='float-right h4 text-info'>{props.game.blueTeamName}</span>
                                </div>
                                <p className='text-center'>vs.</p>
                            </React.Fragment>
                            )}
                        {props.game.gamePlayed == true && (
                            <React.Fragment>
                            <div className='clearfix'>
                                <span className='float-left h4 text-danger'>{props.game.redTeamName}</span>
                                <span className='float-right h4 text-info'>{props.game.blueTeamName}</span>
                            </div>
                                <h3 className='text-center text-white'><span className={(props.game.teamWinnerColor == 'r' && 'font-weight-bold')}>{props.game.redTeamScore}</span> - <span className={(props.game.teamWinnerColor == 'b' && 'font-weight-bold')}>{props.game.blueTeamScore}</span></h3>
                            </React.Fragment>
                            )}
                            {props.game.gameDatetime && (
                            <div className='text-right'>{new Intl.DateTimeFormat('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions).format(new Date(props.game.gameDatetime))}</div>
                            )}
                            {!props.game.gameDatetime && (
                            <div className='text-right'>TBD</div>
                            )}
                        </NavLink>
                    </CardBody>
                </Card>
        </React.Fragment>
    );
};

export default TeamGame;