import * as React from 'react';
import { useState } from 'react';
import MatchCategoryTabs from './MatchCategoryTabs';
import MatchOverviewRoundCards from './MatchOverviewRoundCards';
import { IMatchSummaryVm } from '../../WorldDoomLeague';
import MatchView from './MatchView';

interface MatchCategoryRoundViewProps {
    gameData: IMatchSummaryVm
}

const MatchCategoryRoundView = (props: MatchCategoryRoundViewProps) => {
    const [activeTab, setActiveTab] = useState(1);
    const [activeRound, setActiveRound] = useState(1); // 1 is always the overview, rounds start at 2

    return (
        <React.Fragment>
            <MatchOverviewRoundCards
                gameWinnerColor={props.gameData.gameWinningColor}
                gameTimeTotal={props.gameData.gameTimeTotal}
                activeRound={activeRound} setActiveRound={setActiveRound}
                teamInfo={props.gameData.teamInfo}
                lineScore={props.gameData.lineScore}
            />
            <br />
            <MatchCategoryTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <br />
            <MatchView activeRound={activeRound} activeTab={activeTab} matchInfo={props.gameData} />
        </React.Fragment>);
};

export default MatchCategoryRoundView;