import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { IRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import { formatdecider, MatchRoundFragStatsCategories, FragWeaponCategories } from '../../../../../helpers'
import { useEffect, useState } from 'react';

interface MatchRoundWeaponsFragTableProps {
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    perRoundBoxScore: IRoundBoxScoreDto;
};

const MatchRoundWeaponsFragTable = (props: MatchRoundWeaponsFragTableProps) => {
    const [redPlayers, setRedPlayers] = useState<string[]>([]);
    const [bluePlayers, setBluePlayers] = useState<string[]>([]);

    const getRedTeamPlayers = (boxscore: IRoundBoxScoreDto) => {
        var redPlayers = [] as string[];
        boxscore.redTeamPlayerRoundBoxScore.map((s, idx) => {
            redPlayers.push(s.playerName);
        });

        return redPlayers;
    };

    const getBlueTeamPlayers = (boxscore: IRoundBoxScoreDto) => {
        var bluePlayers = [] as string[];
        boxscore.blueTeamPlayerRoundBoxScore.map((s, idx) => {
            bluePlayers.push(s.playerName);
        });

        return bluePlayers;
    };

    const createRedPlayerTableRow = () => {
        var redRows = [];
        var redRow = [];
        redPlayers.map((s, idx) => {
            redRow.push(<th scope="row">{s}</th>);

            bluePlayers.map((u, __idx) => {
                var playerRoundStats = props.perRoundBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerName === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    }
                } else {
                    redRow.push(<td>-</td>);
                }
            });

            redRows.push(<tr className='bg-danger'>{redRow}</tr>);
            redRow = [];
        });

        return (redRows);
    };

    const createBluePlayerTableRow = () => {
        var blueRows = [];
        var blueRow = [];
        bluePlayers.map((s, idx) => {
            blueRow.push(<th scope="row">{s}</th>);

            redPlayers.map((u,  __idx) => {
                var playerRoundStats = props.perRoundBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerName === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetName === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    }
                } else {
                    blueRow.push(<td>-</td>);
                }
            });

            blueRows.push(<tr className='bg-primary'>{blueRow}</tr>);
            blueRow = [];
        });

        return (blueRows);
    };

    useEffect(() => {
        setRedPlayers(getRedTeamPlayers(props.perRoundBoxScore));
        setBluePlayers(getBlueTeamPlayers(props.perRoundBoxScore));

    }, [props.categoryObject, props.activeWeapon]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th> </th>
                                {bluePlayers.map((s, idx) => (
                                    <th>{s}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {createRedPlayerTableRow()}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th> </th>
                                {redPlayers.map((s, idx) => (
                                    <th>{s}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {createBluePlayerTableRow()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MatchRoundWeaponsFragTable;