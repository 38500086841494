import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { IMatchFinalBoxScoreDto } from '../../../../WorldDoomLeague';
import { FormatMinMaxAvg } from '../../../../helpers'

interface MatchOverallPerformanceDamageStatsProps {
    finalBoxScore: IMatchFinalBoxScoreDto
};

const MatchOverallPerformanceDamageStats = (props: MatchOverallPerformanceDamageStatsProps) => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button color="primary" block id="damagetoggler" style={{ marginBottom: '1rem' }}>
                        Damage
                    </Button>
                    <UncontrolledCollapse toggler="#damagetoggler">
                        <Row>
                            <Col>
                                <h2>Damage</h2>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>HP Damage</th>
                                            <th>Green Armor Damage</th>
                                            <th>Blue Armor Damage</th>
                                            <th>Damage to Flag Carriers</th>
                                            <th>Damage with Flag</th>
                                            <th>Damage to Flag Carriers w/ Flag</th>
                                            <th>Env. Damage</th>
                                            <th>Env. Damage w/ Flag</th>
                                            <th>Min/Max/Avg Dmg {'->'} Cap</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.finalBoxScore.redTeamPlayerFinalBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.damage}</td>
                                                <td>{s.matchStats.damageToGreenArmor}</td>
                                                <td>{s.matchStats.damageToBlueArmor}</td>
                                                <td>{s.matchStats.damageToFlagCarriers}</td>
                                                <td>{s.matchStats.damageAsFlagCarrier}</td>
                                                <td>{s.matchStats.damageAsFlagCarrierWhileHoldingFlag}</td>
                                                <td>{s.matchStats.damageTakenFromEnvironment}</td>
                                                <td>{s.matchStats.damageTakenFromEnvironmentAsFlagCarrier}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.damageBetweenTouchAndCaptureMin)} / {FormatMinMaxAvg(s.matchStats.damageBetweenTouchAndCaptureMax)} / {FormatMinMaxAvg(s.matchStats.damageBetweenTouchAndCaptureAverage)}</td>
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.blueTeamPlayerFinalBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.damage}</td>
                                                <td>{s.matchStats.damageToGreenArmor}</td>
                                                <td>{s.matchStats.damageToBlueArmor}</td>
                                                <td>{s.matchStats.damageToFlagCarriers}</td>
                                                <td>{s.matchStats.damageAsFlagCarrier}</td>
                                                <td>{s.matchStats.damageAsFlagCarrierWhileHoldingFlag}</td>
                                                <td>{s.matchStats.damageTakenFromEnvironment}</td>
                                                <td>{s.matchStats.damageTakenFromEnvironmentAsFlagCarrier}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.damageBetweenTouchAndCaptureMin)} / {FormatMinMaxAvg(s.matchStats.damageBetweenTouchAndCaptureMax)} / {FormatMinMaxAvg(s.matchStats.damageBetweenTouchAndCaptureAverage)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MatchOverallPerformanceDamageStats;