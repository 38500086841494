import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, CardHeader } from 'reactstrap';
import { ITeamMapDto, ITeamRecordDto } from '../../WorldDoomLeague';

interface TeamSummaryProps {
    teamCaptainId: number;
    teamCaptainName: string;
    seasonName: string;
    seasonId: number;
    teamName: string;
    didTeamWinSeason: boolean;
    record: ITeamRecordDto;
    homefield: ITeamMapDto;
}

const TeamSummary = (props: TeamSummaryProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className='text-center'>{props.teamName}{props.didTeamWinSeason && ('👑')}</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className='text-center mb-3'><NavLink className='text-white' to={`/season/${props.seasonId}`}>{props.seasonName}</NavLink></h4>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className='text-center mb-3'>{props.record.win} - {props.record.loss} - {props.record.tie}</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h4 className='text-center mb-3'>Team Captain: <NavLink className='text-white' to={`/player/${props.teamCaptainId}`}>{props.teamCaptainName}</NavLink></h4>
                </Col>
            </Row>
            {props.homefield && props.homefield.idMap > 0 && (
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Card className='border-dark mb-3'>
                            <CardHeader tag="h4">Homefield Selection</CardHeader>
                            {props.homefield.mapImagePath !== "" && (<CardImg top width="100%" src={props.homefield.mapImagePath} alt="Card image cap" />)}
                            <CardBody>
                                <CardTitle tag="h5"><NavLink className='text-white' to={`/map/${props.homefield.idMap}`}>{props.homefield.mapName}</NavLink></CardTitle>
                                <CardText>{props.homefield.mapPack}</CardText>
                                <CardSubtitle tag="h6" className="mb-2 text-muted">{props.homefield.mapNumber}</CardSubtitle>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            )}
        </React.Fragment>);
};

export default TeamSummary;