import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundFragStatsCategories } from '../../../../../helpers';
import { IRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchRoundWeaponsFragTable from './MatchRoundWeaponsFragTable';

interface MatchRoundWeaponCategoryFragTabsViewProps {
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    perRoundBoxScore: IRoundBoxScoreDto;
};

const MatchRoundWeaponCategoryFragTabsView = (props: MatchRoundWeaponCategoryFragTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeWeapon}>
                {props.categoryObject && props.categoryObject.FragWeaponCategories.map((s, idx) => (
                    <TabPane tabId={s}>
                        <MatchRoundWeaponsFragTable activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                    </TabPane>
                    ))}
            </TabContent>
        </React.Fragment>);
};

export default MatchRoundWeaponCategoryFragTabsView;