import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import {
    Label,
    Input,
    FormGroup,
    Form,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    FormText,
    Col
} from 'reactstrap';
import {
    MapsClient,
    IMapImagesDto,
    IMapImagesVm,
    FileParameter,
    FilesClient,
    DeleteMapImageFileCommand
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';
import Select from 'react-select';
import './mapcarousel.css';
import SetImagePriority from './SetImagePriority';

interface UploadMapImagesProps {
    mapId: number;
}

const UploadMapImages = (props: UploadMapImagesProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IMapImagesDto[]>([]);
    const [caption, setCaption] = useState<string>("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [file, setFile] = useState<FileParameter>(null);
    const [newMapImageId, setNewMapImageId] = useState(0);
    const [newPriorityId, setNewPriorityId] = useState(0);
    const [selectedImageId, setSelectedImageId] = useState(0);
    const [deletedImageId, setDeletedImageId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (props.mapId > 0) {
                setLoading(true);
                try {
                    let client = new MapsClient();
                    const response = await client.getImages(props.mapId)
                        .then(response => response.toJSON() as Promise<IMapImagesVm>);
                    const data = response.mapImages;
                    setData(data);
                } catch (e) {
                    error.setError(JSON.parse(e.response));
                }
                setLoading(false);
            }
        };

        fetchData();
    }, [props.mapId, newMapImageId, newPriorityId, deletedImageId]);

    const handleNewImageSubmit = async (evt) => {
        try {
            let client = new FilesClient();
            const response = await client.createMapImage(file, caption, props.mapId);
            setNewMapImageId(response);
            setCaption("");
            setFile(null);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const handleDeleteMapImageSubmit = async (evt) => {
        try {
            let client = new FilesClient();
            const command = new DeleteMapImageFileCommand;
            command.mapImageId = selectedImageId;
            const response = await client.deleteMapImage(selectedImageId, command);
            setDeletedImageId(response);
            setSelectedImageId(0);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === data.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? data.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = data.map((item) => {
        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={item.imagePath}
            >
                <img src={item.imagePath} alt={item.imageCaption} />
                <CarouselCaption captionText={item.imageCaption} captionHeader={''} />
            </CarouselItem>
        );
    });

    const handleUpload = async (evt) => {
        const upload: FileParameter = {
            data: evt.target.files[0],
            fileName: evt.target.files[0].name
        };
        setFile(upload);
    };

    // A way to view the uploaded images.
    const renderMapImageCarousel = () => {
        var jsx = null;
        {
            !loading && props.mapId > 0 && data.length > 0 && (
            jsx = (
                <React.Fragment>
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        <CarouselIndicators items={data} activeIndex={activeIndex} onClickHandler={goToIndex} />
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                    </Carousel>
                </React.Fragment>
            ))
        };

        {
            !loading && props.mapId > 0 && data.length <= 0 && (
                jsx = (
                    <React.Fragment>
                        <h2>No map image files for this map.</h2>
                    </React.Fragment>
                ))
        };


        {
            loading && (
            jsx = (
                <React.Fragment>
                    <h2>Loading...</h2>
                </React.Fragment>
            ))
        };

        {
            props.mapId <= 0 && (
                jsx = (
                    <React.Fragment>
                        <h2>No Map Selected.</h2>
                    </React.Fragment>
                ))
        };


        return jsx;
    };

    // create a list for each map.
    const renderDeleteMapImage = () => {
        let select = null;
        if (data.length > 0) {
            select = (
                <React.Fragment>
                <Select
                    options={data}
                    value={data.find(f => f.id == selectedImageId) || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.imagePath + " | " + label.imageCaption}
                    onChange={e => setSelectedImageId(e.id)}
                        isLoading={loading}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary25: 'dimgrey',
                                neutral0: 'black',
                                neutral80: 'white'
                            },
                        })}
                    />
                    <br />
                    <Button color="danger" size="lg" block disabled={selectedImageId <= 0} onClick={handleDeleteMapImageSubmit}>Delete map image</Button>
                    </React.Fragment>
            );
        }
        else {
            select = (
                <Select options={[{ label: "No map images in the system.", value: "Not" }]} theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'dimgrey',
                        neutral0: 'black',
                        neutral80: 'white'
                    },
                })} />
            );
        }
        return select;
    };

    // create a form for entering a new map.
    const renderNewMapImageForm = () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="File">Image File</Label>
                    <Input type="file" name="file" id="file" key={newMapImageId} onChange={handleUpload} />
                    <FormText color="muted">
                        Max upload: 10MB
                    </FormText>
                    <Label for="caption">Caption</Label>
                    <Input type="text" name="caption" id="caption" value={caption} placeholder="Middle" onChange={e => setCaption(e.target.value)} />
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!file || !caption} onClick={handleNewImageSubmit}>Upload new map image</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Col xs="6" sm="4">
                <h2 className='text-center'>Upload Map Image</h2>
                <Form>
                    {renderMapImageCarousel()}
                    <hr />
                    {renderNewMapImageForm()}
                    <br />
                    {renderDeleteMapImage()}
                </Form>
            </Col>
            <Col xs="6" sm="4">
                <SetImagePriority mapId={props.mapId} mapData={data} setNewPriorityId={setNewPriorityId}/>
            </Col>
        </React.Fragment>
    );
};

export default UploadMapImages;