import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    IMatchSummaryVm,
    MatchesClient,
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';

import PostMatchSummary from './PostMatchSummary'
import MatchMetadata from './MatchMetadata';
import MatchCategoryRoundView from './MatchCategoryRoundView';
import MatchPreviewSummary from './MatchPreviewSummary';
import MatchForfeitSummary from './MatchForfeitSummary';
import MatchPreviewPlayers from './MatchPreviewPlayers';

interface MatchRouterProps {
    id: string;   // id of the match (coming from the router)
}

interface MatchProps extends RouteComponentProps<MatchRouterProps> {
    // Add your regular properties here
}

const Match = (props: MatchProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [matchData, setMatchData] = useState<IMatchSummaryVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MatchesClient();
                const response = await client.getMatchSummary(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<IMatchSummaryVm>);
                setMatchData(response);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            {matchData && (
                <React.Fragment>
                    {matchData.gamePlayed && !matchData.gameForfeit && (<PostMatchSummary lineScore={matchData.lineScore} teamInfo={matchData.teamInfo} seasonName={matchData.seasonName} winningTeam={matchData.winningTeamInfo} />)}
                    {!matchData.gamePlayed && !matchData.gameForfeit && (<MatchPreviewSummary teamInfo={matchData.teamInfo} seasonName={matchData.seasonName} />)}
                    {matchData.gameForfeit && (<MatchForfeitSummary teamInfo={matchData.teamInfo} seasonName={matchData.seasonName} winningTeam={matchData.winningTeamInfo} doubleForfeit={matchData.doubleForfeit} />)}
                    <MatchMetadata gameDateTime={matchData.gamePlayedTime} gameTimeTotal={matchData.gameTimeTotal} roundsPlayed={matchData.roundsPlayed} mapsPlayed={matchData.mapsPlayed} forfeit={matchData.gameForfeit} played={matchData.gamePlayed} />
                    {matchData.gamePlayed && !matchData.gameForfeit && (<MatchCategoryRoundView gameData={matchData} />)}
                    {!matchData.gamePlayed && !matchData.gameForfeit && (<MatchPreviewPlayers teamInfo={matchData.teamInfo} />)}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Match;