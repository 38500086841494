import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { IPlayoffWeeksDto, IRegularSeasonWeeksDto, ISeasonCombinedStatsDto, ISeasonDraftDto, ISeasonStandingsDto, ISeasonTeamDto, ISeasonTradesDto } from '../../WorldDoomLeague';
import SeasonCombinedStats from './SeasonCombinedStats';
import SeasonDraft from './SeasonDraft';
import SeasonTeams from './SeasonTeams';
import SeasonTrades from './SeasonTrades';
import SeasonWeeks from './SeasonWeeks';

interface SeasonViewProps {
    activeTab: number,
    draft: ISeasonDraftDto[];
    trades: ISeasonTradesDto[];
    regSeasonWeeks: IRegularSeasonWeeksDto[];
    playoffWeeks: IPlayoffWeeksDto[];
    finalsWeek: IPlayoffWeeksDto;
    teams: ISeasonTeamDto[];
    combinedStats: ISeasonCombinedStatsDto;
};

const SeasonView = (props: SeasonViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeTab}>
                <TabPane tabId={1}>
                    <SeasonDraft draft={props.draft} />
                </TabPane>
                <TabPane tabId={2}>
                    <SeasonTeams teams={props.teams} />
                </TabPane>
                <TabPane tabId={3}>
                    <SeasonTrades trades={props.trades} />
                </TabPane>
                <TabPane tabId={4}>
                    <SeasonWeeks regSeasonWeeks={props.regSeasonWeeks} playoffWeeks={props.playoffWeeks} finalsWeek={props.finalsWeek} />
                </TabPane>
                <TabPane tabId={5}>
                    <SeasonCombinedStats combinedStats={props.combinedStats} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default SeasonView;