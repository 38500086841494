import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { IMatchCapturesDto, IMatchTeamInfoDto, IRoundBoxScoreDto } from '../../../../WorldDoomLeague';
import { formatdecider, FormatHhMmSs, FormatShortTimeString, getHighestAssistTable } from '../../../../helpers'

interface MatchRoundStatsProps {
    activeRound: number,
    teamInfo: IMatchTeamInfoDto,
    perRoundBoxScore: IRoundBoxScoreDto[]
};

const MatchRoundStats = (props: MatchRoundStatsProps) => {
    const createAssistRows = (amountAssistRows: number) => {
        var assistRows = [];
        for (var i = 0; i < amountAssistRows; i++) {
            assistRows.push(<th>Assist #{i + 1}</th>);
        }
        return assistRows;
    };

    const createPadRows = (amountPadRows: number) => {
        var padRows = [];
        for (var i = 0; i < amountPadRows; i++) {
            padRows.push(<td></td>);
        }
        return padRows;
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Team</th>
                                <th>Points</th>
                                <th>Captures</th>
                                <th>P. Captures</th>
                                <th>Touches</th>
                                <th>P. Touches</th>
                                <th>Assists</th>
                                <th>Frags</th>
                                <th>Deaths</th>
                                <th>KDR</th>
                                <th>Flag Defs</th>
                                <th>Damage</th>
                                <th>Powerups</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='bg-danger'>
                                <th scope="row">{props.teamInfo.redTeamName}</th>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.points}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.captures}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.pickupCaptures}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.flagTouches}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.pickupFlagTouches}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.assists}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.frags}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.deaths}</td>
                                <td>{formatdecider(props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.kdr)}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.flagDefenses}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.damage}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].redTeamBoxScore.powerups}</td>
                            </tr>
                            <tr className='bg-primary'>
                                <th scope="row">{props.teamInfo.blueTeamName}</th>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.points}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.captures}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.pickupCaptures}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.flagTouches}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.pickupFlagTouches}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.assists}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.frags}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.deaths}</td>
                                <td>{formatdecider(props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.kdr)}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.flagDefenses}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.damage}</td>
                                <td>{props.perRoundBoxScore[props.activeRound - 2].blueTeamBoxScore.powerups}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Pts</th>
                                <th>Caps</th>
                                <th>PCaps</th>
                                <th>Tchs</th>
                                <th>PTchs</th>
                                <th>Asts</th>
                                <th>Frags</th>
                                <th>Deaths</th>
                                <th>KDR</th>
                                <th>FlagDefs</th>
                                <th>Dmg</th>
                                <th>Pow</th>
                                <th>RAT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.perRoundBoxScore[props.activeRound - 2].redTeamPlayerRoundBoxScore.map((s, idx) => (
                                <tr className='bg-danger'>
                                    <th scope="row"><NavLink className='text-white' to={`/player/${s.playerId}`}>{s.playerName}</NavLink></th>
                                    <td>{s.matchStats.points}</td>
                                    <td>{s.matchStats.captures}</td>
                                    <td>{s.matchStats.pickupCaptures}</td>
                                    <td>{s.matchStats.flagTouches}</td>
                                    <td>{s.matchStats.pickupFlagTouches}</td>
                                    <td>{s.matchStats.assists}</td>
                                    <td>{s.matchStats.frags}</td>
                                    <td>{s.matchStats.deaths}</td>
                                    <td>{formatdecider(s.matchStats.kdr)}%</td>
                                    <td>{s.matchStats.flagDefenses}</td>
                                    <td>{s.matchStats.damage}</td>
                                    <td>{s.matchStats.powerups}</td>
                                    <td>{formatdecider(s.matchStats.wdlrat)}</td>
                                </tr>
                            ))}
                            {props.perRoundBoxScore[props.activeRound - 2].blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                <tr className='bg-primary'>
                                    <th scope="row"><NavLink className='text-white' to={`/player/${s.playerId}`}>{s.playerName}</NavLink></th>
                                    <td>{s.matchStats.points}</td>
                                    <td>{s.matchStats.captures}</td>
                                    <td>{s.matchStats.pickupCaptures}</td>
                                    <td>{s.matchStats.flagTouches}</td>
                                    <td>{s.matchStats.pickupFlagTouches}</td>
                                    <td>{s.matchStats.assists}</td>
                                    <td>{s.matchStats.frags}</td>
                                    <td>{s.matchStats.deaths}</td>
                                    <td>{formatdecider(s.matchStats.kdr)}%</td>
                                    <td>{s.matchStats.flagDefenses}</td>
                                    <td>{s.matchStats.damage}</td>
                                    <td>{s.matchStats.powerups}</td>
                                    <td>{formatdecider(s.matchStats.wdlrat)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {props.perRoundBoxScore[props.activeRound - 2].captures.length > 0 && (
                <Row>
                    <Col xs="auto" md={{ size: 10, offset: 1 }}>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Capture</th>
                                    {createAssistRows(getHighestAssistTable(props.perRoundBoxScore[props.activeRound - 2].captures))}
                                </tr>
                            </thead>
                            <tbody>
                                {props.perRoundBoxScore[props.activeRound - 2].captures.map((s, idx) => (
                                    <tr className={s.teamColor == "r" ? 'bg-danger' : 'bg-primary'}>
                                        <td>{s.captureNumber}</td>
                                        <td>{FormatHhMmSs(s.eventTime)} - {s.playerName} ({FormatShortTimeString(s.captureAmountTime)})</td>
                                        {s.assists.filter(f => f !== s.assists[0]).map((t, _idx) => (
                                            <td>{FormatHhMmSs(t.eventTime)} - {t.playerName}</td>
                                        ))}
                                        {s.assists.length - 1 < getHighestAssistTable(props.perRoundBoxScore[props.activeRound - 2].captures) &&
                                            createPadRows(getHighestAssistTable(props.perRoundBoxScore[props.activeRound - 2].captures) - (s.assists.length - 1))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default MatchRoundStats;