import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { ISeasonStandingsDto } from '../../WorldDoomLeague';
import SeasonTeamStandings from './SeasonTeamStandings';

interface SeasonSummaryProps {
    seasonName: string;
    winningTeamId?: number;
    winningTeamName: string;
    signupDate: Date;
    seasonStandings: ISeasonStandingsDto[];
}

const SeasonSummary = (props: SeasonSummaryProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className='text-center'>{props.seasonName}</h2>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <p className='text-right'>Signups began: {new Intl.DateTimeFormat('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions).format(new Date(props.signupDate))}</p>
                </Col>
            </Row>
            {props.winningTeamId && (
                <Row className='mb-3'>
                    <Col>
                        <p className='text-left'>Congratulations to season winner <NavLink to={`/team/${props.winningTeamId}`}>{props.winningTeamName}👑</NavLink></p>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <SeasonTeamStandings seasonStandings={props.seasonStandings} />
                </Col>
            </Row>
        </React.Fragment>);
};

export default SeasonSummary;