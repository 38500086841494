import * as React from 'react';
import { Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, Button, Row, Col, CardDeck } from 'reactstrap';
import { FormatHhMmSs } from '../../helpers';
import { IMatchLineScoreDto, IMatchTeamInfoDto } from '../../WorldDoomLeague';

interface MatchOverviewRoundCardsProps {
    activeRound: number,
    setActiveRound: React.Dispatch<React.SetStateAction<number>>,
    teamInfo: IMatchTeamInfoDto,
    lineScore: IMatchLineScoreDto,
    gameTimeTotal: string,
    gameWinnerColor: string
};

const MatchOverviewRoundCards = (props: MatchOverviewRoundCardsProps) => {
    const toggle = (card: number) => {
        if (props.activeRound !== card) props.setActiveRound(card);
    };

    const getWinnerClass = (color: string) => {
        if (color === 'r') {
            return "red";
        } else if (color === 'b') {
            return "blue";
        } else if (color === 't') {
            return "tie";
        } else {
            return "tie";
        }
    };

    var cards = [];
    cards = props.lineScore.roundScore.map((s, idx) => (
        <Col>
            <Card className='border-white'>
                <CardBody>
                    <hr className={getWinnerClass(s.roundWinner)} />
                    <CardTitle tag="h5" className='text-center'>{s.round}</CardTitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted text-center">{FormatHhMmSs(s.roundDuration)}</CardSubtitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted text-center">{s.mapPlayedName}</CardSubtitle>
                    <CardSubtitle tag="h6" className="mb-2 text-muted text-center"><span className={s.roundWinner === 'r' ? 'text-danger' : ''}>{props.teamInfo.redTeamAbv}</span>
                        &nbsp;<span className={s.roundWinner === 'r' ? 'font-weight-bold' : ''}>{s.redScore}</span> -&nbsp;
                        <span className={s.roundWinner === 'b' ? 'font-weight-bold' : ''}>{s.blueScore}</span>&nbsp;
                        <span className={s.roundWinner === 'b' ? 'text-info' : ''}>{props.teamInfo.blueTeamAbv}</span>
                    </CardSubtitle>
                    <div className='text-center'>
                        <Button className='text-center' disabled={props.activeRound === idx + 2} onClick={() => toggle(idx + 2)}>Toggle</Button>
                    </div>
                </CardBody>
            </Card>
        </Col>
    ));

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='border-white h-100 d-flex'>
                        <CardBody className='d-flex flex-column'>
                            <hr className={getWinnerClass(props.gameWinnerColor) + ' w-100'} />
                            <CardTitle tag="h5" className='text-center'>Overview</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted text-center">{FormatHhMmSs(props.gameTimeTotal)}</CardSubtitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted text-center"><span className={props.gameWinnerColor === 'r' ? 'text-danger' : ''}>{props.teamInfo.redTeamAbv}</span>
                                &nbsp;<span className={props.gameWinnerColor === 'r' ? 'font-weight-bold' : ''}>{props.lineScore.matchResult.redRoundScore}</span> -&nbsp;
                                <span className={props.gameWinnerColor === 'b' ? 'font-weight-bold' : ''}>{props.lineScore.matchResult.blueRoundScore}</span>&nbsp;
                                <span className={props.gameWinnerColor === 'b' ? 'text-info' : ''}>{props.teamInfo.blueTeamAbv}</span>
                            </CardSubtitle>
                            <div className='text-center mt-auto'>
                                <Button disabled={props.activeRound === 1} onClick={() => toggle(1)}>Toggle</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                {cards}
            </Row>
        </React.Fragment>
    );
};

export default MatchOverviewRoundCards;