import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundFragStatsCategories, FragWeaponCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsFragTabsView from './AdHocMatchOverallWeaponsFragTabsView';
import AdHocMatchOverallWeaponsFragTabs from './AdHocMatchOverallWeaponsFragTabs';

interface AdHocMatchOverallWeaponsFragCategoryTabsViewProps {
    category: FragWeaponCategories;
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    finalsBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponsFragCategoryTabsView = (props: AdHocMatchOverallWeaponsFragCategoryTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.category}>
                <TabPane tabId={FragWeaponCategories.Frags}>
                    <AdHocMatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.FragsWithFlag}>
                    <AdHocMatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.CarrierFrags}>
                    <AdHocMatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.CarrierFragsWithFlag}>
                    <AdHocMatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default AdHocMatchOverallWeaponsFragCategoryTabsView;