import * as React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { formatdecider, FormatHhMmSs } from '../../helpers';
import { IMapStatsDto } from '../../WorldDoomLeague';

interface MapStatsProps {
    mapStats: IMapStatsDto;
}

const MapStats = (props: MapStatsProps) => {
    return (
        <React.Fragment>
            <Card className='bg-danger mb-3'>
                <CardHeader>
                    <div className='text-center'>Combined Map Stats</div>
                </CardHeader>
                <CardBody>
                    <Table className='table-sm'>
                        <thead>
                            <tr>
                                <th>Games Played</th>
                                <th>Rounds Played</th>
                                <th>Time Played</th>
                                <th>Points</th>
                                <th>Captures</th>
                                <th>P. Captures</th>
                                <th>Assists</th>
                                <th>Touches</th>
                                <th>P. Touches</th>
                                <th>Frags</th>
                                <th>Deaths</th>
                                <th>Flag Defs</th>
                                <th>Damage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatdecider(props.mapStats.gamesPlayed)}</td>
                                <td>{formatdecider(props.mapStats.roundsPlayed)}</td>
                                <td>{FormatHhMmSs(props.mapStats.timePlayed)}</td>
                                <td>{formatdecider(props.mapStats.points)}</td>
                                <td>{formatdecider(props.mapStats.captures)}</td>
                                <td>{formatdecider(props.mapStats.pickupCaptures)}</td>
                                <td>{formatdecider(props.mapStats.assists)}</td>
                                <td>{formatdecider(props.mapStats.flagTouches)}</td>
                                <td>{formatdecider(props.mapStats.pickupFlagTouches)}</td>
                                <td>{formatdecider(props.mapStats.frags)}</td>
                                <td>{formatdecider(props.mapStats.deaths)}</td>
                                <td>{formatdecider(props.mapStats.flagDefenses)}</td>
                                <td>{formatdecider(props.mapStats.damage)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default MapStats;