import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { ITeamPlayerDto } from '../../WorldDoomLeague';

interface TeamInitialPlayersProps {
    initialPlayers: ITeamPlayerDto[];
}

const TeamInitialPlayers = (props: TeamInitialPlayersProps) => {
    return (
        <React.Fragment>
            <Card className='bg-primary mb-3'>
                <CardHeader>
                    Initial Players
                </CardHeader>
                <CardBody>
                    {props.initialPlayers.map((s, idx) => (
                        <p className='text-center'><NavLink className='text-white' to={`/player/${s.playerId}`}>{s.playerName}</NavLink></p>
                    ))}
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default TeamInitialPlayers;