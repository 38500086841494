import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { IPlayerCaptainRecordDto } from '../../WorldDoomLeague';
import PlayerRecordsTable from './PlayerRecordsTable';

interface PlayerSummaryProps {
    playerName: string;
    totalRecord: IPlayerCaptainRecordDto;
    regularSeason: IPlayerCaptainRecordDto;
    playoffs: IPlayerCaptainRecordDto;
    finals: IPlayerCaptainRecordDto;
}

const PlayerSummary = (props: PlayerSummaryProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <h2 className='text-center mb-3'>{props.playerName}</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <PlayerRecordsTable totalRecord={props.totalRecord} regularSeason={props.regularSeason} playoffs={props.playoffs} finals={props.finals} />
                </Col>
            </Row>
        </React.Fragment>);
};

export default PlayerSummary;