import * as React from 'react';
import { IRoundBoxScoreDto } from '../../../../WorldDoomLeague';
import MatchRoundWeaponsDamageStats from './damage/MatchRoundWeaponsDamageStats';
import MatchRoundWeaponsFragsStats from './frags/MatchRoundWeaponsFragStats';

interface MatchRoundWeaponsStatsProps {
    activeRound: number,
    perRoundBoxScore: IRoundBoxScoreDto[]
};

const MatchRoundWeaponsStats = (props: MatchRoundWeaponsStatsProps) => {

    return (
        <React.Fragment>
            <MatchRoundWeaponsFragsStats activeRound={props.activeRound} perRoundBoxScore={props.perRoundBoxScore} />
            <MatchRoundWeaponsDamageStats activeRound={props.activeRound} perRoundBoxScore={props.perRoundBoxScore} />
        </React.Fragment>
    );
};

export default MatchRoundWeaponsStats;