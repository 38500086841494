import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { IMatchSummaryVm } from '../../WorldDoomLeague';
import MatchOverallPerformanceStats from './overall/performance/MatchOverallPerformanceStats';
import MatchOverallStats from './overall/stats/MatchOverallStats';
import MatchOverallWeaponsStats from './overall/weapons/MatchOverallWeaponsStats';
import MatchRoundPerformanceStats from './round/performance/MatchRoundPerformanceStats';
import MatchRoundStats from './round/stats/MatchRoundStats';
import MatchRoundWeaponsStats from './round/weapons/MatchRoundWeaponsStats';

interface MatchViewProps {
    activeTab: number,
    activeRound: number,
    matchInfo: IMatchSummaryVm
};

const MatchView = (props: MatchViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeTab}>
                <TabPane tabId={1}>
                    {props.activeRound === 1 && (<MatchOverallStats teamInfo={props.matchInfo.teamInfo} finalBoxScore={props.matchInfo.finalBoxScore} />)}
                    {props.activeRound >= 2 && (<MatchRoundStats activeRound={props.activeRound} teamInfo={props.matchInfo.teamInfo} perRoundBoxScore={props.matchInfo.perRoundBoxScore} />)}
                </TabPane>
                <TabPane tabId={2}>
                    {props.activeRound === 1 && (<MatchOverallPerformanceStats finalBoxScore={props.matchInfo.finalBoxScore} />)}
                    {props.activeRound >= 2 && (<MatchRoundPerformanceStats activeRound={props.activeRound} perRoundBoxScore={props.matchInfo.perRoundBoxScore} />)}
                </TabPane>
                <TabPane tabId={3}>
                    {props.activeRound === 1 && (<MatchOverallWeaponsStats activeRound={props.activeRound} finalBoxScore={props.matchInfo.finalBoxScore} />)}
                    {props.activeRound >= 2 && (<MatchRoundWeaponsStats activeRound={props.activeRound} perRoundBoxScore={props.matchInfo.perRoundBoxScore} />)}
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default MatchView;