import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';
import { ISeasonTeamDto } from '../../WorldDoomLeague';

interface SeasonTeamsProps {
    teams: ISeasonTeamDto[];
}

const SeasonTeams = (props: SeasonTeamsProps) => {
    return (
        <React.Fragment>
            <Row>
                {props.teams.map((s, idx) => (
                    <Card className='mb-4 border-white mx-auto' style={{ width: '14rem' }}>
                        <CardBody>
                            <CardTitle tag="h5" className='text-center'><NavLink to={`/team/${s.idTeam}`}>{s.teamName}</NavLink></CardTitle>
                            <CardSubtitle tag="h6" className='text-center'>{s.teamAbbreviation}</CardSubtitle>
                            <CardText>
                                {s.players.map((t, _idx) => (
                                    <React.Fragment>
                                        <NavLink to={`/player/${t.playerId}`}>{t.playerName}</NavLink>
                                        <br />
                                    </React.Fragment>
                                ))}
                            </CardText>
                        </CardBody>
                    </Card>
                ))}
            </Row>
        </React.Fragment>
    );
};

export default SeasonTeams;