import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { formatdecider } from '../../../../helpers';
import { IMatchFinalBoxScoreDto, IMatchTeamInfoDto } from '../../../../WorldDoomLeague';

interface MatchOverallStatsProps {
    teamInfo: IMatchTeamInfoDto,
    finalBoxScore: IMatchFinalBoxScoreDto,
};

const MatchOverallStats = (props: MatchOverallStatsProps) => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Team</th>
                                <th>Points</th>
                                <th>Captures</th>
                                <th>P. Captures</th>
                                <th>Touches</th>
                                <th>P. Touches</th>
                                <th>Assists</th>
                                <th>Frags</th>
                                <th>Deaths</th>
                                <th>KDR</th>
                                <th>Flag Defs</th>
                                <th>Damage</th>
                                <th>Powerups</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='bg-danger'>
                                <th scope="row">{props.teamInfo.redTeamName}</th>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.points}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.captures}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.pickupCaptures}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.flagTouches}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.pickupFlagTouches}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.assists}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.frags}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.deaths}</td>
                                <td>{formatdecider(props.finalBoxScore.redTeamFinalBoxScore.kdr)}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.flagDefenses}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.damage}</td>
                                <td>{props.finalBoxScore.redTeamFinalBoxScore.powerups}</td>
                            </tr>
                            <tr className='bg-primary'>
                                <th scope="row">{props.teamInfo.blueTeamName}</th>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.points}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.captures}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.pickupCaptures}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.flagTouches}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.pickupFlagTouches}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.assists}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.frags}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.deaths}</td>
                                <td>{formatdecider(props.finalBoxScore.blueTeamFinalBoxScore.kdr)}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.flagDefenses}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.damage}</td>
                                <td>{props.finalBoxScore.blueTeamFinalBoxScore.powerups}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Pts</th>
                                <th>Caps</th>
                                <th>PCaps</th>
                                <th>Tchs</th>
                                <th>PTchs</th>
                                <th>Asts</th>
                                <th>Frags</th>
                                <th>Deaths</th>
                                <th>KDR</th>
                                <th>FlagDefs</th>
                                <th>Dmg</th>
                                <th>Pow</th>
                                <th>RAT</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.finalBoxScore.redTeamPlayerFinalBoxScore.map((s, idx) => (
                                <tr className='bg-danger'>
                                    <th scope="row"><NavLink className='text-white' to={`/player/${s.playerId}`}>{s.playerName}</NavLink></th>
                                    <td>{s.matchStats.points}</td>
                                    <td>{s.matchStats.captures}</td>
                                    <td>{s.matchStats.pickupCaptures}</td>
                                    <td>{s.matchStats.flagTouches}</td>
                                    <td>{s.matchStats.pickupFlagTouches}</td>
                                    <td>{s.matchStats.assists}</td>
                                    <td>{s.matchStats.frags}</td>
                                    <td>{s.matchStats.deaths}</td>
                                    <td>{formatdecider(s.matchStats.kdr)}%</td>
                                    <td>{s.matchStats.flagDefenses}</td>
                                    <td>{s.matchStats.damage}</td>
                                    <td>{s.matchStats.powerups}</td>
                                    <td>{formatdecider(s.matchStats.wdlrat)}</td>
                                </tr>
                            ))}
                            {props.finalBoxScore.blueTeamPlayerFinalBoxScore.map((s, idx) => (
                                <tr className='bg-primary'>
                                    <th scope="row"><NavLink className='text-white' to={`/player/${s.playerId}`}>{s.playerName}</NavLink></th>
                                    <td>{s.matchStats.points}</td>
                                    <td>{s.matchStats.captures}</td>
                                    <td>{s.matchStats.pickupCaptures}</td>
                                    <td>{s.matchStats.flagTouches}</td>
                                    <td>{s.matchStats.pickupFlagTouches}</td>
                                    <td>{s.matchStats.assists}</td>
                                    <td>{s.matchStats.frags}</td>
                                    <td>{s.matchStats.deaths}</td>
                                    <td>{formatdecider(s.matchStats.kdr)}%</td>
                                    <td>{s.matchStats.flagDefenses}</td>
                                    <td>{s.matchStats.damage}</td>
                                    <td>{s.matchStats.powerups}</td>
                                    <td>{formatdecider(s.matchStats.wdlrat)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MatchOverallStats;