import * as React from 'react';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Col, Form, FormGroup, Row } from 'reactstrap';
import { ErrorContext } from '../../../state';
import {
    IOutstandingDemoDto,
} from '../../../WorldDoomLeague';

interface OutstandingDemoListProps {
    demo: IOutstandingDemoDto;

}

const OutstandingDemoList = (props: OutstandingDemoListProps) => {
    return (
        <React.Fragment>
            <Row className='mb-3'>
                <Col>
                    <Card className='border-white mb-3'>
                        <CardBody>
                            <CardTitle tag="h3">{props.demo.gameName}</CardTitle>
                                <h4>Player: <NavLink to={`/player/${props.demo.playerId}`}>{props.demo.playerName}</NavLink></h4>
                                <h4>Team: <NavLink to={`/player/${props.demo.teamId}`}>{props.demo.teamName}</NavLink></h4>
                                {props.demo.isUploaded && (
                                    <p>Demo File: <NavLink to={props.demo.demoLink}>Download</NavLink></p>
                                )}
                                
                                {!props.demo.isUploaded && (
                                    <p className='text-danger'>Player has yet to upload the demo.</p>
                                )}
                                
                                {props.demo.playerLostDemo && (
                                    <p className='text-danger'>Player lost the demo file.</p>
                                )}
                                {!props.demo.demoAdminApproved && (
                                    <p className='text-danger'>This demo has not yet been approved.</p>
                                )}
                                <div className="clearfix">
                                    <Button color="primary" size="lg" className='float-left'>Select Demo</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OutstandingDemoList;