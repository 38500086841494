import * as React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';

interface MapAppearancesTableProps {
    regularSeasonGamesPlayed: number;
    playoffsGamesPlayed: number;
    finalsGamesPlayed: number;
}

const MapAppearancesTable = (props: MapAppearancesTableProps) => {
    return (
        <React.Fragment>
            <Card className='bg-primary mb-3'>
                <CardHeader>
                    <div className='text-center'>Map Appearances (in games)</div>
                </CardHeader>
                <CardBody>
                    <Table className='table-sm'>
                        <thead>
                            <tr>
                                <th>Regular Season</th>
                                <th>Playoffs</th>
                                <th>Finals</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{props.regularSeasonGamesPlayed}</td>
                                <td>{props.playoffsGamesPlayed}</td>
                                <td>{props.finalsGamesPlayed}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default MapAppearancesTable;