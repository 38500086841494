import * as React from 'react';
import { IPlayerSummaryDraftDto, IPlayerSummaryStatsDto, IPlayerSummaryTradeDto, ISeasonsPlayedInDto } from '../../WorldDoomLeague';
import PlayerView from './PlayerView';
import PlayerViewTabs from './PlayerViewTabs';

interface PlayerDetailsProps {
    draft: IPlayerSummaryDraftDto[];
    captainDraft: IPlayerSummaryDraftDto[];
    trades: IPlayerSummaryTradeDto[];
    combinedStats: IPlayerSummaryStatsDto;
    seasonsPlayedIn: ISeasonsPlayedInDto[];
}

const PlayerDetails = (props: PlayerDetailsProps) => {
    const [activeTab, setActiveTab] = React.useState(4);
    return (
        <React.Fragment>
            <PlayerViewTabs setActiveTab={setActiveTab} activeTab={activeTab} />
            <PlayerView
                activeTab={activeTab}
                captainDraft={props.captainDraft}
                draft={props.draft}
                trades={props.trades}
                seasonsPlayedIn={props.seasonsPlayedIn}
                combinedStats={props.combinedStats}
            />
        </React.Fragment>);
};

export default PlayerDetails;