import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { IPlayerSummaryTradeDto } from '../../WorldDoomLeague';

interface PlayerTradesProps {
    trades: IPlayerSummaryTradeDto[];
}

const PlayerTrades = (props: PlayerTradesProps) => {
    return (
        <Row>
            {props.trades.map((s, idx) => (
                <Col sm='3' className='mx-auto'>
                    <Card className='border-info mb-3'>
                        <CardHeader>
                            <NavLink className='text-white' to={`/season/${s.seasonId}`}>{s.seasonName}</NavLink>
                        </CardHeader>
                        <CardBody>
                                <h5 className='text-center'>Week {s.weekNumber}</h5>
                                <p>{s.teamTradedFrom} trades {s.tradedPlayerName} to {s.teamTradedTo} for {s.playerTradedFor}. {s.playerPromotedCaptain == true && ('(And promoted captain)')}</p>
                        </CardBody>
                    </Card>
                </Col>
            ))}
            {props.trades.length <= 0 && (
                <Col>
                    <p className='text-center'>No trades recorded for this player.</p>
                </Col>
            )}
        </Row>
    );
};

export default PlayerTrades;