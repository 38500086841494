import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { IMatchFinalBoxScoreDto } from '../../../../WorldDoomLeague';
import { FormatShortTimeString, FormatMinMaxAvg } from '../../../../helpers'

interface MatchOverallPerformanceCaptureStatsProps {
    finalBoxScore: IMatchFinalBoxScoreDto
};

const MatchOverallPerformanceCaptureStats = (props: MatchOverallPerformanceCaptureStatsProps) => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button color="primary" block id="capturetoggler" style={{ marginBottom: '1rem' }}>
                        Captures
                    </Button>
                    <UncontrolledCollapse toggler="#capturetoggler">
                        <Row>
                            <Col>
                                <h2>Captures</h2>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Points</th>
                                            <th>Captures</th>
                                            <th>P. Captures</th>
                                            <th>Assists</th>
                                            <th>Captures via Powerup</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.finalBoxScore.redTeamPlayerFinalBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.points}</td>
                                                <td>{s.matchStats.captures}</td>
                                                <td>{s.matchStats.pickupCaptures}</td>
                                                <td>{s.matchStats.assists}</td>
                                                <td>{s.matchStats.capturesWithSuperPickups}</td>
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.blueTeamPlayerFinalBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.points}</td>
                                                <td>{s.matchStats.captures}</td>
                                                <td>{s.matchStats.pickupCaptures}</td>
                                                <td>{s.matchStats.assists}</td>
                                                <td>{s.matchStats.capturesWithSuperPickups}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Min/Max/Avg Cap Time</th>
                                            <th>Min/Max/Avg PCap Time</th>
                                            <th>Min/Max/Avg Cap HP</th>
                                            <th>Min/Max/Avg Cap Green Armor</th>
                                            <th>Min/Max/Avg Cap Blue Armor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.finalBoxScore.redTeamPlayerFinalBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{FormatShortTimeString(s.matchStats.captureTimeMin)} / {FormatShortTimeString(s.matchStats.captureTimeMax)} / {FormatShortTimeString(s.matchStats.captureTimeAverage)}</td>
                                                <td>{FormatShortTimeString(s.matchStats.pickupCaptureTimeMin)} / {FormatShortTimeString(s.matchStats.pickupCaptureTimeMax)} / {FormatShortTimeString(s.matchStats.pickupCaptureTimeAverage)}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.captureHealthMin)} / {FormatMinMaxAvg(s.matchStats.captureHealthMax)} / {FormatMinMaxAvg(s.matchStats.captureHealthAverage)}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.captureGreenArmorMin)} / {FormatMinMaxAvg(s.matchStats.captureGreenArmorMax)} / {FormatMinMaxAvg(s.matchStats.captureGreenArmorAverage)}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.captureBlueArmorMin)} / {FormatMinMaxAvg(s.matchStats.captureBlueArmorMax)} / {FormatMinMaxAvg(s.matchStats.captureBlueArmorAverage)}</td>
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.blueTeamPlayerFinalBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{FormatShortTimeString(s.matchStats.captureTimeMin)} / {FormatShortTimeString(s.matchStats.captureTimeMax)} / {FormatShortTimeString(s.matchStats.captureTimeAverage)}</td>
                                                <td>{FormatShortTimeString(s.matchStats.pickupCaptureTimeMin)} / {FormatShortTimeString(s.matchStats.pickupCaptureTimeMax)} / {FormatShortTimeString(s.matchStats.pickupCaptureTimeAverage)}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.captureHealthMin)} / {FormatMinMaxAvg(s.matchStats.captureHealthMax)} / {FormatMinMaxAvg(s.matchStats.captureHealthAverage)}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.captureGreenArmorMin)} / {FormatMinMaxAvg(s.matchStats.captureGreenArmorMax)} / {FormatMinMaxAvg(s.matchStats.captureGreenArmorAverage)}</td>
                                                <td>{FormatMinMaxAvg(s.matchStats.captureBlueArmorMin)} / {FormatMinMaxAvg(s.matchStats.captureBlueArmorMax)} / {FormatMinMaxAvg(s.matchStats.captureBlueArmorAverage)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MatchOverallPerformanceCaptureStats;