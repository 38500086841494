import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import {
    Label,
    Input,
    FormGroup,
    Form,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    FormText
} from 'reactstrap';
import {
    MapsClient,
    IMapImagesDto,
    IMapImagesVm,
    FileParameter,
    FilesClient,
    ChangeMapImagesPriorityCommand,
    ImagePriorityObject
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface SetImagePriorityProps {
    mapId: number;
    mapData: IMapImagesDto[];
    setNewPriorityId: React.Dispatch<React.SetStateAction<number>>;
}

const SetImagePriority = (props: SetImagePriorityProps) => {
    const error = useContext(ErrorContext);
    const [items, setItems] = useState<IMapImagesDto[]>([]);

    useEffect(() => {
        if (props.mapData.length > 0) {
            setItems(props.mapData);
        }
        else {
            setItems([]);
        }
    }, [props.mapData]);

    const handleSubmit = async (evt) => {
        try {
            let client = new FilesClient();
            const command = new ChangeMapImagesPriorityCommand;
            command.mapId = props.mapId;

            var priorityArray = [] as ImagePriorityObject[];

            items.map((s, idx) => {
                var p = new ImagePriorityObject;
                p.id = s.id;
                p.priority = idx + 1;
                priorityArray.push(p);
            });

            command.imagePriority = priorityArray;
            const response = await client.changeImagePriority(command);
            props.setNewPriorityId(response);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(newItems);
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: 250
    });

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    return (
        <React.Fragment>
            <h2 className='text-center'>Set Image Priority</h2>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            {item.imageCaption}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <p>Image priority sets what order the images are shown.</p>
            <p>For pages that display only 1 image, priority 1 (the top) will be shown.</p>
            <Button color="primary" size="lg" block disabled={!(items.length > 0)} onClick={handleSubmit}>Set Image Priority</Button>
        </React.Fragment>
    );
};

export default SetImagePriority;