import * as React from 'react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { MatchRoundDamageStatsCategories, DamageWeaponCategories } from '../../../../../helpers';

interface MatchOverallWeaponsDamageCategoriesTabsProps {
    activeCategoryTab: DamageWeaponCategories,
    setActiveCategoryTab: React.Dispatch<React.SetStateAction<DamageWeaponCategories>>;
    categories: MatchRoundDamageStatsCategories[];
    setCategory: React.Dispatch<React.SetStateAction<MatchRoundDamageStatsCategories>>;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
};

const MatchOverallWeaponsDamageCategoriesTabs = (props: MatchOverallWeaponsDamageCategoriesTabsProps) => {

    const toggle = (tab: DamageWeaponCategories, category: MatchRoundDamageStatsCategories) => {
        if (props.activeCategoryTab !== tab)
        {
            // Change the weapon tab if that weapon doesn't exist in our new selected category.
            if (category.DamageWeaponCategories.find(f => f === props.activeWeapon) === undefined) {
                props.setActiveWeapon(category.DamageWeaponCategories[0] || "");
            }
            props.setActiveCategoryTab(tab);
            props.setCategory(category);
        }
    };

    return (
        <React.Fragment>
            {props.categories.length > 0 && (
                <Row className='mb-3'>
                    <Col>
                        <Nav tabs>
                            {props.categories.map((s, idx) => (
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: props.activeCategoryTab === s.category })}
                                        onClick={() => { toggle(s.category, s); }}
                                    >
                                        {s.category}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </Col>
                </Row>
            )}
        </React.Fragment>);
};

export default MatchOverallWeaponsDamageCategoriesTabs;