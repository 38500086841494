import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, CardImg, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';
import { ISeasonPlayoffWeekDto, ISeasonRegularSeasonWeekDto } from '../../WorldDoomLeague';
import SeasonGame from './SeasonGame';

interface SeasonWeeksProps {
    regSeasonWeeks: ISeasonRegularSeasonWeekDto[];
    playoffWeeks: ISeasonPlayoffWeekDto[];
    finalsWeek: ISeasonPlayoffWeekDto;
}

const SeasonWeeks = (props: SeasonWeeksProps) => {
    const FormatMap = (week: ISeasonRegularSeasonWeekDto) => {
        if (week.weekMap) {
            return (
                <Row>
                    <Col>
                        <Card className='bg-secondary mb-3'>
                            <CardHeader>
                                <div className='text-center'>Week #{week.weekNumber}</div>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col sm='9'>
                                        {week.games.map((t, _idx) => (
                                            <SeasonGame game={t} />
                                        ))}
                                    </Col>
                                    <Col sm='3'>
                                        <NavLink className='text-white text-decoration-none' to={`/map/${week.weekMap.idMap}`}>
                                            <Card className='border-dark h-100 resultcard'>
                                                {week.weekMap.mapImagePath !== "" && (<CardImg top width="100%" src={week.weekMap.mapImagePath} alt="Card image cap" />)}
                                                <CardBody>
                                                    <CardTitle tag="h5">{week.weekMap.mapName}</CardTitle>
                                                    <CardText>{week.weekMap.mapPack}</CardText>
                                                    <CardSubtitle tag="h6" className="mb-2 text-muted">{week.weekMap.mapNumber}</CardSubtitle>
                                                </CardBody>
                                            </Card>
                                        </NavLink>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            );
        }
        else
        {
            return (
                <React.Fragment>
                    {week.games.length > 0 && (
                        <Row>
                            <Col>
                                <Card className='bg-secondary mb-3'>
                                    <CardHeader>
                                        <div className='text-center'>Week #{week.weekNumber}</div>
                                    </CardHeader>
                                    <CardBody>
                                        {week.games.map((t, _idx) => (
                                            <Row>
                                                <Col sm='9'>
                                                    <SeasonGame game={t} />
                                                </Col>
                                                <Col sm='3'>
                                                    {t.gameMap.map((u, __idx) => (
                                                        <NavLink className='text-white text-decoration-none' to={`/map/${u.idMap}`}>
                                                            <Card className='border-dark mb-3 resultcard'>
                                                                {u.mapImagePath !== "" && (<CardImg top width="100%" src={u.mapImagePath} alt="Card image cap" />)}
                                                                <CardBody>
                                                                    <CardTitle tag="h5">{u.mapName}</CardTitle>
                                                                    <CardText>{u.mapPack}</CardText>
                                                                    <CardSubtitle tag="h6" className="mb-2 text-muted">{u.mapNumber}</CardSubtitle>
                                                                </CardBody>
                                                            </Card>
                                                        </NavLink>
                                                    ))}
                                                    {t.gameMap.length <= 0 && (
                                                        <Card className='border-dark mb-3 resultcard'>
                                                            <CardBody>
                                                                <CardTitle tag="h5">Map not set yet!</CardTitle>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </Col>
                                            </Row>
                                        ))}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </React.Fragment>
            );
        }
    };

    return (
        <React.Fragment>
            {props.regSeasonWeeks.every(element => element.games.length > 0) && (
                <React.Fragment>
                    <h2 className='text-center'>Regular Season</h2>
                        {props.regSeasonWeeks.map((s, idx) => (
                            FormatMap(s)
                        ))}
                </React.Fragment>
            )}
            {props.playoffWeeks.some(element => element.games.length > 0) && (
                <React.Fragment>
                    <h2 className='text-center'>Playoffs</h2>
                    {props.playoffWeeks.map((s, idx) => (
                        <React.Fragment>
                            {s.games.length > 0 && (
                                <React.Fragment>
                                    <Row>
                                        <Col>
                                            <Card className='bg-secondary mb-3'>
                                                <CardHeader>
                                                    <div className='text-center'>Week #{s.weekNumber}</div>
                                                </CardHeader>
                                                <CardBody>
                                                    {s.games.map((t, _idx) => (
                                                        <Row>
                                                            <Col sm='9'>
                                                                <SeasonGame game={t} />
                                                            </Col>
                                                            <Col sm='3'>
                                                                {t.gameMap.map((u, __idx) => (
                                                                    <NavLink className='text-white text-decoration-none' to={`/map/${u.idMap}`}>
                                                                        <Card className='border-dark mb-3 resultcard'>
                                                                            {u.mapImagePath !== "" && (<CardImg top width="100%" src={u.mapImagePath} alt="Card image cap" />)}
                                                                            <CardBody>
                                                                                <CardTitle tag="h5">{u.mapName}</CardTitle>
                                                                                <CardText>{u.mapPack}</CardText>
                                                                                <CardSubtitle tag="h6" className="mb-2 text-muted">{u.mapNumber}</CardSubtitle>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </NavLink>
                                                                ))}
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}
            {props.finalsWeek.games.length > 0 && (
                <React.Fragment>
                    <h2 className='text-center'>Finals</h2>
                    <Row>
                        <Col>
                            <Card className='bg-secondary mb-3'>
                                <CardHeader>
                                    <div className='text-center'>Week #{props.finalsWeek.weekNumber}</div>
                                </CardHeader>
                                <CardBody>
                                        <Row>
                                            <Col sm='9'>
                                            <SeasonGame game={props.finalsWeek.games[0]} />
                                            </Col>
                                            <Col sm='3'>
                                            {props.finalsWeek.games[0].gameMap.map((u, __idx) => (
                                                <NavLink className='text-white text-decoration-none' to={`/map/${u.idMap}`}>
                                                    <Card className='border-dark mb-3 resultcard'>
                                                        {u.mapImagePath !== "" && (<CardImg top width="100%" src={u.mapImagePath} alt="Card image cap" />)}
                                                        <CardBody>
                                                            <CardTitle tag="h5">{u.mapName}</CardTitle>
                                                            <CardText>{u.mapPack}</CardText>
                                                            <CardSubtitle tag="h6" className="mb-2 text-muted">{u.mapNumber}</CardSubtitle>
                                                        </CardBody>
                                                    </Card>
                                                </NavLink>
                                                ))}
                                            </Col>
                                        </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default SeasonWeeks;