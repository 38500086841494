import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { OrdinalSuffixOf } from '../../helpers';
import { IPlayerCaptainDraftRecordSeasonDto } from '../../WorldDoomLeague';

interface PlayerCaptainDraftProps {
    captainDraft: IPlayerCaptainDraftRecordSeasonDto[];
}

const PlayerCaptainDraft = (props: PlayerCaptainDraftProps) => {
    return (
        <Row>
            {props.captainDraft.map((s, idx) => (
                <Col sm='3' className='mx-auto'>
                    <Card className='border-light mb-3'>
                        <CardHeader>
                            <NavLink className='text-white' to={`/season/${s.seasonId}`}>{s.seasonName}</NavLink>
                        </CardHeader>
                        <CardBody>
                            {s.draftList.map((t, _idx) => (
                                <ol start={t.draftNominationPosition}>
                                    <li>{t.playerNominating} nominates {t.playerNominated}</li>
                                    <ul>
                                        <li>Sold to {t.playerSoldTo} for {new Intl.NumberFormat('default', { style: 'currency', currency: 'USD' }).format(t.sellPrice)} ({OrdinalSuffixOf(t.teamDraftPosition)})</li>
                                </ul>
                            </ol>
                            ))}
                        </CardBody>
                    </Card>
                </Col>
            ))}
            {props.captainDraft.length <= 0 && (
                <Col>
                    <p className='text-center'>This player has never drafted as a captain.</p>
                </Col>
            )}
        </Row>
    );
};

export default PlayerCaptainDraft;