import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { MatchRoundDamageStatsCategories, DamageWeaponCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsDamageCategoryTabsView from './AdHocMatchOverallWeaponsDamageCategoryTabsView';
import AdHocMatchOverallWeaponsDamageCategoriesTabs from './AdHocMatchOverallWeaponsDamageCategoriesTabs';

interface AdHocMatchOverallWeaponsDamageStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto
};

const AdHocMatchOverallWeaponsDamageStats = (props: AdHocMatchOverallWeaponsDamageStatsProps) => {
    const [activeCategoryTab, setActiveCategoryTab] = useState<DamageWeaponCategories>();
    const [activeWeapon, setActiveWeapon] = useState<string>();
    const [category, setCategory] = useState<MatchRoundDamageStatsCategories>();
    const [categories, setCategories] = useState<MatchRoundDamageStatsCategories[]>([]);


    const CreateStatCategories = (match: IAdHocRoundBoxScoreDto ) => {
        var categories = [] as MatchRoundDamageStatsCategories[];

        match.redTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.damageStats.damageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.Damage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.Damage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.DamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.DamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        match.blueTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.damageStats.damageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.Damage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.Damage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.DamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.DamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        match.greenTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.damageStats.damageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.Damage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.Damage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.DamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.DamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        match.nonTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.damageStats.damageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.Damage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.Damage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.Damage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.damageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.DamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.DamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.damageByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.DamageWithFlag).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeapon.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamage)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamage, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.damageStats.carrierDamageByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === DamageWeaponCategories.CarrierDamage).DamageWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.damageStats.carrierDamageByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== DamageWeaponCategories.CarrierDamageWithFlag)) {
                    var newcat: MatchRoundDamageStatsCategories = { category: DamageWeaponCategories.CarrierDamageWithFlag, DamageWeaponCategories: [] as string[] } as MatchRoundDamageStatsCategories;
                    s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.DamageWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.DamageWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.damageStats.carrierDamageByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === DamageWeaponCategories.CarrierDamageWithFlag).DamageWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        return categories;
    };

    useEffect(() => {
        var newcats = CreateStatCategories(props.finalBoxScore);

        if (newcats.length > 0) {
            setCategories(newcats);
            setCategory(newcats[0]);
            setActiveCategoryTab(newcats[0].category);
            setActiveWeapon(newcats[0].DamageWeaponCategories[0]);
        }
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='bg-dark mb-3'>
                        <CardHeader>
                            Damage
                        </CardHeader>
                        <CardBody>
                            <AdHocMatchOverallWeaponsDamageCategoriesTabs activeCategoryTab={activeCategoryTab}
                                setActiveCategoryTab={setActiveCategoryTab}
                                categories={categories}
                                setCategory={setCategory}
                                activeWeapon={activeWeapon}
                                setActiveWeapon={setActiveWeapon}
                            />
                            <AdHocMatchOverallWeaponsDamageCategoryTabsView activeWeapon={activeWeapon}
                                setActiveWeapon={setActiveWeapon}
                                category={activeCategoryTab}
                                categoryObject={category}
                                finalBoxScore={props.finalBoxScore}
                                    />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdHocMatchOverallWeaponsDamageStats;