import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { MatchRoundFragStatsCategories, FragWeaponCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsFragCategoryTabsView from './AdHocMatchOverallWeaponsFragCategoryTabsView';
import AdHocMatchOverallWeaponsFragCategoriesTabs from './AdHocMatchOverallWeaponsFragCategoriesTabs';

interface AdHocMatchOverallWeaponsFragsStatsProps {
    finalsBoxScore: IAdHocRoundBoxScoreDto
};

const AdHocMatchOverallWeaponsFragsStats = (props: AdHocMatchOverallWeaponsFragsStatsProps) => {
    const [activeCategoryTab, setActiveCategoryTab] = useState<FragWeaponCategories>();
    const [activeWeapon, setActiveWeapon] = useState<string>();
    const [category, setCategory] = useState<MatchRoundFragStatsCategories>();
    const [categories, setCategories] = useState<MatchRoundFragStatsCategories[]>([]);


    const CreateStatCategories = (match: IAdHocRoundBoxScoreDto) => {
        var categories = [] as MatchRoundFragStatsCategories[];

        match.redTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.killStats.killsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.Frags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.Frags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.killsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.FragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.FragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFrags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFrags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        match.blueTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.killStats.killsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.Frags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.Frags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.killsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.FragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.FragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFrags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFrags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        match.greenTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.killStats.killsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.Frags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.Frags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.killsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.FragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.FragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFrags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFrags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        match.nonTeamPlayerRoundBoxScore.map((s, idx) => {
            if (s.killStats.killsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.Frags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.Frags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.Frags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.killsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.FragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.FragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.killsByWeaponWithFlag.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.FragsWithFlag).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeapon.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFrags)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFrags, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                } else {
                    s.killStats.carrierKillsByWeapon.map((t, _idx) => {
                        if (categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.every(e => e !== t.weapon)) {
                            categories.find(f => f.category === FragWeaponCategories.CarrierFrags).FragWeaponCategories.push(t.weapon);
                        }
                    });
                }
            }

            if (s.killStats.carrierKillsByWeaponWithFlag.length > 0) {
                if (categories.every(e => e.category !== FragWeaponCategories.CarrierFragsWithFlag)) {
                    var newcat: MatchRoundFragStatsCategories = { category: FragWeaponCategories.CarrierFragsWithFlag, FragWeaponCategories: [] as string[] } as MatchRoundFragStatsCategories;
                    s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                        if (newcat.FragWeaponCategories.every(e => e !== t.weapon)) {
                            newcat.FragWeaponCategories.push(t.weapon);
                        }
                    });
                    categories.push(newcat);
                }
            } else {
                s.killStats.carrierKillsByWeaponWithFlag.map((t, _idx) => {
                    if (categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.every(e => e !== t.weapon)) {
                        categories.find(f => f.category === FragWeaponCategories.CarrierFragsWithFlag).FragWeaponCategories.push(t.weapon);
                    }
                });
            }
        });

        return categories;
    };

    useEffect(() => {
        var newcats = CreateStatCategories(props.finalsBoxScore);

        if (newcats.length > 0) {
            setCategories(newcats);
            setCategory(newcats[0]);
            setActiveCategoryTab(newcats[0].category);
            setActiveWeapon(newcats[0].FragWeaponCategories[0]);
        }
    }, []);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='bg-dark mb-3'>
                        <CardHeader>
                            Frags
                        </CardHeader>
                        <CardBody>
                            <AdHocMatchOverallWeaponsFragCategoriesTabs activeCategoryTab={activeCategoryTab}
                                setActiveCategoryTab={setActiveCategoryTab}
                                categories={categories}
                                setCategory={setCategory}
                                activeWeapon={activeWeapon}
                                setActiveWeapon={setActiveWeapon}
                            />
                            <AdHocMatchOverallWeaponsFragCategoryTabsView activeWeapon={activeWeapon}
                                setActiveWeapon={setActiveWeapon}
                                category={activeCategoryTab}
                                categoryObject={category}
                                finalsBoxScore={props.finalsBoxScore}
                                    />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdHocMatchOverallWeaponsFragsStats;