import * as React from 'react';
import { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { IMapSeasonsDto, IMapStatsDto, IMapTeamHomefieldsDto } from '../../WorldDoomLeague';
import MapAppearancesTable from './MapAppearancesTable';
import MapSeasonsPlayed from './MapSeasonsPlayed';
import MapStats from './MapStats';
import MapTeamHomefields from './MapTeamHomefields';

interface MapViewProps {
    seasonsFeaturingMap: IMapSeasonsDto[];
    teamHomefields: IMapTeamHomefieldsDto[];
    regularSeasonGamesPlayed: number;
    playoffsGamesPlayed: number;
    finalsGamesPlayed: number;
    mapStats: IMapStatsDto;
    activeTab: number;
};

const MapView = (props: MapViewProps) => {
    return (
        <React.Fragment>
            <TabContent activeTab={props.activeTab}>
                <TabPane tabId={1}>
                    <MapTeamHomefields teamHomefields={props.teamHomefields} />
                </TabPane>
                <TabPane tabId={2}>
                    <MapSeasonsPlayed mapSeasonsPlayed={props.seasonsFeaturingMap} />
                </TabPane>
                <TabPane tabId={3}>
                    <MapStats mapStats={props.mapStats} />
                    <MapAppearancesTable regularSeasonGamesPlayed={props.regularSeasonGamesPlayed} playoffsGamesPlayed={props.playoffsGamesPlayed} finalsGamesPlayed={props.finalsGamesPlayed} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default MapView;