import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { FormGroup, Form, Row, Col } from 'reactstrap';
import {
    MapsClient,
    IMapsDto,
    IMapsVm,
} from '../../../WorldDoomLeague';
import Select from 'react-select';
import { ErrorContext } from '../../../state';
import UploadMapImages from './UploadMapImages';

const EditMapImages = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IMapsDto[]>([]);
    const [mapObject, setMapObject] = useState<IMapsDto>();
    const [mapId, setMapId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MapsClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IMapsVm>);
                const data = response.mapList;
                setData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    // create a list for each map.
    const renderMapDropdown = () => {
        let select = null;
        if (data.length > 0) {
            select = (
                <Select
                    options={data}
                    value={mapObject || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.mapName + " | " + label.mapPack}
                    onChange={e => handleSetObject(e)}
                    isLoading={loading}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                />
            );
        }
        else
        {
            select = (
                <Select options={[{ label: "No maps found in the system.", value: "Not" }]} theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'dimgrey',
                        neutral0: 'black',
                        neutral80: 'white'
                    },
                })}/>
            );
        }
        return select;
    };

    const handleSetObject = (obj: IMapsDto) => {
        setMapId(obj.id);
        setMapObject(obj);
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs="6" sm="4">
                    <Form>
                        <FormGroup>
                            <h2 className='text-center'>Select Map</h2>
                            {renderMapDropdown()}
                        </FormGroup>
                    </Form>
                </Col>
                <UploadMapImages mapId={mapId} />
            </Row>
        </React.Fragment>
    );
};

export default EditMapImages;