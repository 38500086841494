import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Row, Table } from 'reactstrap';
import ReactPaginate from 'react-paginate';

import {
    IPlayedMatchResultsDto,
    IPlayedMatchResultsVm,
    MatchesClient
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import { NavLink } from 'react-router-dom';

export interface ILocalizedMatches {
    weekDay: Date;
    matchInfo: IPlayedMatchResultsDto[];
};

const Results = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageCount, setPageCount] = useState<number>(0);
    const [offset, setOffset] = useState<number>(0);
    const perPage = 10;

    const [playedMatches, setPlayedMatches] = useState<ILocalizedMatches[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MatchesClient();
                const response = await client.getPlayedMatchResults(offset, perPage)
                    .then(response => response.toJSON() as Promise<IPlayedMatchResultsVm>);
                const data = response.playedMatches;
                setPageCount(Math.ceil(response.totalCount / response.perPage));
                localizeMatches(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [offset]);

    const localizeMatches = (matchData: IPlayedMatchResultsDto[]) => {
        var localizedMatches = [];

        for (var i = 0; i < matchData.length; i++) {
            if (localizedMatches.length <= 0) {
                const newDate = {
                    weekDay: matchData[i].scheduledTime,
                    matchInfo: [matchData[i]]
                };

                const newMatches = localizedMatches.concat(newDate);

                localizedMatches = newMatches;
            } else {
                if (localizedMatches.find(f => new Date(f.weekDay).toLocaleDateString() == new Date(matchData[i].scheduledTime).toLocaleDateString())) {
                    const oldMatchInfo = localizedMatches
                        .find(f => new Date(f.weekDay).toLocaleDateString() == new Date(matchData[i].scheduledTime).toLocaleDateString())
                        .matchInfo as IPlayedMatchResultsDto[];

                    const matchInfo = oldMatchInfo
                        .concat(matchData[i]);

                    localizedMatches
                        .find(f => new Date(f.weekDay).toLocaleDateString() == new Date(matchData[i].scheduledTime).toLocaleDateString())
                        .matchInfo = matchInfo;
                } else {
                    const matchInfo = matchData[i];
                    const matches = localizedMatches.concat({
                        weekDay: matchInfo.scheduledTime,
                        matchInfo: [matchInfo]
                    });

                    localizedMatches = matches;
                }
            }
        }

        setPlayedMatches(localizedMatches);
    };

    const displayPlayedMatches = () => {
        var matchGroups = [];

        playedMatches.map((s, idx) => {
            var matches = [];
            s.matchInfo.map((t, _idx) => {
                matches = matches.concat(
                    <React.Fragment>
                        <NavLink className='text-decoration-none text-reset' to={`/matches/${t.id}`}>
                            <CardBody className='resultcard'>
                                <div className="container-fluid">
                                <Row>
                                        <Col xs="6" sm="4">
                                            <Card className='bg-secondary border-white mb-3'>
                                                <div className="container-fluid">
                                                    <h4 className='text-left text-danger mt-1'>{t.redTeamName}</h4>
                                                    <h3 className='text-center'>
                                                    {t.winningTeam === t.redTeam && (
                                                        <span className='font-weight-bold'>{t.redTeamScore}</span>
                                                    )}
                                                    {t.winningTeam === t.blueTeam && (
                                                        <span>{t.redTeamScore}</span>
                                                    )}
                                                    {t.winningTeam === 0 && (
                                                        <span>{t.redTeamScore}</span>
                                                    )}
                                                    -
                                                    {t.winningTeam === t.blueTeam && (
                                                        <span className='font-weight-bold'>{t.blueTeamScore}</span>
                                                    )}
                                                    {t.winningTeam === t.redTeam && (
                                                        <span>{t.blueTeamScore}</span>
                                                    )}
                                                    {t.winningTeam === 0 && (
                                                        <span>{t.blueTeamScore}</span>
                                                    )}
                                                    </h3>
                                                    <h4 className='text-right text-info'>{t.blueTeamName}</h4>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col xs="6" sm="4">
                                            <h4 className='text-center'>{t.seasonName}</h4>
                                            <h5 className='text-center'>{t.gameType}</h5>
                                        </Col>
                                        <Col xs="6" sm="4">
                                            <div className="card-group mb-3">
                                            {t.maps.map((u, __idx) => (
                                                <div className="card bg-dark text-white">
                                                    <img src={u.mapImagePath} className="card-img" />
                                                    <div className="card-img-overlay">
                                                        <h5 className="card-title text-center">{u.mapName}</h5>
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        </Col>
                                </Row>
                                <Row xs="4">
                                {t.topPerformers.mostCaptures &&
                                    t.topPerformers.mostDamage &&
                                    t.topPerformers.mostFlagDefenses &&
                                        t.topPerformers.mostKills && (
                                            <React.Fragment>
                                            <Col className='border-right'>
                                                <p className='text-center font-weight-bold'>Most Frags</p>
                                                <p className='text-center'>{t.topPerformers.mostKills.playerName} [{t.topPerformers.mostKills.teamAbv}]</p>
                                                <p className='text-center'>{t.topPerformers.mostKills.kills}</p>
                                            </Col>
                                            <Col className='border-right'>
                                                <p className='text-center font-weight-bold'>Most Damage</p>
                                                <p className='text-center'>{t.topPerformers.mostDamage.playerName} [{t.topPerformers.mostDamage.teamAbv}]</p>
                                                <p className='text-center'>{t.topPerformers.mostDamage.damage}</p>
                                            </Col>
                                            <Col className='border-right'>
                                                <p className='text-center font-weight-bold'>Most Captures</p>
                                                <p className='text-center'>{t.topPerformers.mostCaptures.playerName} [{t.topPerformers.mostCaptures.teamAbv}]</p>
                                                <p className='text-center'>{t.topPerformers.mostCaptures.captures}</p>
                                            </Col>
                                            <Col>
                                                <p className='text-center font-weight-bold'>Most Flag Defenses</p>
                                                <p className='text-center'>{t.topPerformers.mostFlagDefenses.playerName} [{t.topPerformers.mostFlagDefenses.teamAbv}]</p>
                                                <p className='text-center'>{t.topPerformers.mostFlagDefenses.flagDefenses}</p>
                                            </Col>
                                            </React.Fragment>
                                        )}
                                    </Row>
                                </div>
                            </CardBody>
                        </NavLink>
                        <CardFooter>
                            
                        </CardFooter>
                    </React.Fragment>
                );
            });
            matchGroups = matchGroups.concat(
            <Card className='bg-primary mb-3'>
                <CardHeader>
                    {new Intl.DateTimeFormat('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions).format(new Date(s.weekDay))}
                </CardHeader>
                {matches}
            </Card>
            );
        });

        return (matchGroups);
    };

    const handlePageClick = (data) => {
        setOffset(Math.ceil(data.selected * perPage));
    };

    return (
        <div>
            <h1>Recently Played Matches</h1>
            <ReactPaginate
                previousLabel={"← Previous"}
                nextLabel={"Next →"}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                disabledClassName={"page-item disabled"}
                activeClassName={"page-item active"}
                activeLinkClassName={'page-link'}
                nextClassName={'page-item'}
                previousClassName={'page-item'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
            />
            {playedMatches.length > 0 && displayPlayedMatches()}
        </div>
    );
};

export default Results;