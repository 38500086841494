import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import Roles from '../../Roles';
import AdminMenu from '../navmenu/AdminMenu';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            roles: []
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name,
            roles: user && user.role
        });
    }

    render() {
        const { isAuthenticated, userName, roles } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath, roles);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, roles) {
        return (<Fragment>
            {roles && roles.includes(Roles.Admin || Roles.DemoAdmin || Roles.StatsRunner) && (<AdminMenu isAuthenticated={true} role={roles} />)}
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Hello {userName}
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem>
                        <NavItem className="ml-auto">
                            <NavLink tag={Link} to={profilePath}>View Profile</NavLink>
                        </NavItem>
                    </DropdownItem>
                    {roles && roles.includes(Roles.Player) && (
                        <DropdownItem>
                            <NavItem className="ml-auto">
                                <NavLink tag={Link} to={'/mydemos'}>My Demos</NavLink>
                            </NavItem>
                        </DropdownItem>
                    )}
                    <DropdownItem divider />
                    <DropdownItem>
                        <NavItem className="ml-auto">
                            <NavLink tag={Link} to={logoutPath}>Logout</NavLink>
                        </NavItem>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem className="ml-auto">
                <NavLink tag={Link} to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem className="ml-auto">
                <NavLink tag={Link} to={loginPath}>Login</NavLink>
            </NavItem>
        </Fragment>);
    }
}
