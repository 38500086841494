import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardImg, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';
import {
    IUpcomingMatchesDto,
    IUpcomingMatchesVm,
    MatchesClient
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import { NavLink } from 'react-router-dom';

export interface ILocalizedMatches {
    weekDay: Date;
    matchInfo: IUpcomingMatchesDto[];
};

const UpcomingMatches = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [upcomingMatches, setUpcomingMatches] = useState<ILocalizedMatches[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MatchesClient();
                const response = await client.getUpcomingGames()
                    .then(response => response.toJSON() as Promise<IUpcomingMatchesVm>);
                const data = response.upcomingMatches;

                localizeMatches(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const localizeMatches = (matchData: IUpcomingMatchesDto[]) => {
        var localizedMatches = [];

        for (var i = 0; i < matchData.length; i++) {
            if (localizedMatches.length <= 0) {
                const newDate = {
                    weekDay: matchData[i].scheduledTime,
                    matchInfo: [matchData[i]]
                };

                const newMatches = localizedMatches.concat(newDate);

                localizedMatches = newMatches;
            } else {
                if (localizedMatches.find(f => new Date(f.weekDay).toLocaleDateString() == new Date(matchData[i].scheduledTime).toLocaleDateString())) {
                    const oldMatchInfo = localizedMatches
                        .find(f => new Date(f.weekDay).toLocaleDateString() == new Date(matchData[i].scheduledTime).toLocaleDateString())
                        .matchInfo as IUpcomingMatchesDto[];

                    const matchInfo = oldMatchInfo
                        .concat(matchData[i]);

                    localizedMatches
                        .find(f => new Date(f.weekDay).toLocaleDateString() == new Date(matchData[i].scheduledTime).toLocaleDateString())
                        .matchInfo = matchInfo;
                } else {
                    const matchInfo = matchData[i];
                    const matches = localizedMatches.concat({
                        weekDay: matchInfo.scheduledTime,
                        matchInfo: [matchInfo]
                    });

                    localizedMatches = matches;
                }
            }
        }

        setUpcomingMatches(localizedMatches);
    };

    const displayUpcomingMatches = () => {
        var matchGroups = [];

        upcomingMatches.map((s, idx) => {
            var matches = [];
            s.matchInfo.map((t, _idx) => {
                matches = matches.concat(
                    <React.Fragment>
                        <NavLink className='text-decoration-none text-reset' to={`/matches/${t.id}`}>
                            <CardBody className='resultcard'>
                                <div className="container-fluid">
                                    <Row className='h-100'>
                                        <Col className='my-auto'>
                                            <h4>{new Intl.DateTimeFormat('default', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' } as Intl.DateTimeFormatOptions).format(new Date(t.scheduledTime))}</h4>
                                        </Col>
                                        <Col className='my-auto'>
                                            <Card className='bg-secondary border-white mb-3'>
                                                <div className="container-fluid">
                                                    <h4 className='text-center'><span className='text-info'>{t.blueTeamName}</span></h4>
                                                    <p className='text-center'><span className='text-info'>({t.blueTeamRecord})</span></p>
                                                    <p className='text-center'>vs.</p>
                                                    <h4 className='text-center'><span className='text-danger'>{t.redTeamName}</span></h4>
                                                    <p className='text-center'><span className='text-danger'>({t.redTeamRecord})</span></p>
                                                </div>
                                            </Card>
                                        </Col>
                                        <Col className='my-auto'>
                                            <p className='text-center'>{t.seasonName}</p>
                                            <p className='text-center'>{t.gameType}</p>
                                        </Col>
                                        <Col xs="4" className='my-auto'>
                                            <div className="card-group mb-3 h-100">
                                                {t.maps && t.maps.length > 0 && t.maps.map((u, __idx) => (
                                                    <Card className='border-dark mr-2'>
                                                        {u.mapImagePath !== "" && (<CardImg top width="100%" src={u.mapImagePath} alt="Card image cap" />)}
                                                        <CardBody>
                                                            <CardTitle tag="h5">{u.mapName}</CardTitle>
                                                            <CardText>{u.mapPack}</CardText>
                                                            <CardSubtitle tag="h6" className="mb-2 text-muted">{u.mapNumber}</CardSubtitle>
                                                        </CardBody>
                                                    </Card>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </CardBody>
                        </NavLink>
                    <CardFooter>

                    </CardFooter>
                </React.Fragment>
                );
            });
            matchGroups = matchGroups.concat(
                <Card className='bg-primary mb-3'>
                    <CardHeader>
                        {new Intl.DateTimeFormat('default', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as Intl.DateTimeFormatOptions).format(new Date(s.weekDay))}
                    </CardHeader>
                    {matches}
                </Card>
            );
        });

        return (matchGroups);
    };

    return (
        <div>
            <h1>Upcoming Matches</h1>
            <p className='text-center'>All match date and times are shown in your device's local time zone.</p>
            {upcomingMatches.length > 0 && displayUpcomingMatches()}
            {upcomingMatches.length <= 0 && !loading && (<h3 className='text-center'>There are currently no matches scheduled.</h3>)}
            {loading && (<h3 className='text-center'>Loading...</h3>)}
        </div>
    );
};

export default UpcomingMatches;