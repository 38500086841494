import * as React from 'react';
import { useState, useEffect } from 'react';
import { Spinner, ListGroup, ListGroupItem, Label, Input, FormText, Button, Collapse } from 'reactstrap';
import DateTimePicker from 'react-datetime-picker';
import { ErrorContext } from '../../../state';
import {
    ISeasonDto,
    SeasonsClient,
    CreateSeasonCommand
} from '../../../WorldDoomLeague';
import Select from 'react-select';

interface SelectSeasonProps {
    loading: boolean;
    seasonData: ISeasonDto[];
    season: ISeasonDto;
    setSeason: React.Dispatch<React.SetStateAction<ISeasonDto>>;
}

const SelectSeason = (props: SelectSeasonProps) => {
    const renderSelectSeason = () => {
        let select = null;
        if (props.seasonData && props.seasonData.length > 0) {
            select = (
                <Select
                    options={props.seasonData}
                    onChange={e => props.setSeason(e)}
                    isSearchable={true}
                    value={props.seasonData.find(o => o == props.season) || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.seasonName}
                    isLoading={props.loading}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                />);
        } else {
            select = (<Select options={[{ label: "No seasons!", value: "0" }]} theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'dimgrey',
                    neutral0: 'black',
                    neutral80: 'white'
                },
            })} />);
        }

        return (select);
    };

    return (
        <React.Fragment>
            {renderSelectSeason()}
        </React.Fragment>
    );
};

export default SelectSeason;