import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { ErrorContext } from '../../state';
import {
    SeasonsClient,
    GameType,
    DemosClient,
    IDemoVm,
    IDemoDto,
    ISeasonWithDemosDto,
    IPlayerWithDemosDto,
    PlayersClient,
    IPlayersWithDemosVm,
    ISeasonsWithDemosVm
} from '../../WorldDoomLeague';
import { Col, Row, Table } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';

const Demos = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [season, setSeason] = useState<number>(null);
    const [player, setPlayer] = useState<number>(null);
    const [gameType, setGameType] = useState<GameType>(GameType.All);
    const [seasonData, setSeasonData] = useState<ISeasonWithDemosDto[]>([]);
    const [demoData, setDemoData] = useState<IDemoDto[]>([]);
    const [playerData, setPlayerData] = useState<IPlayerWithDemosDto[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new DemosClient();
                const response = await client.getDemos(season, gameType, player)
                    .then(response => response.toJSON() as Promise<IDemoVm>);
                const data = response.demoList;
                setDemoData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [gameType, season, player]);

    useEffect(() => {
        const fetchData = async () => {
        setLoading(true);
        try {
            let client = new SeasonsClient();
            const response = await client.getSeasonsWithDemos(gameType)
                .then(response => response.toJSON() as Promise<ISeasonsWithDemosVm>);
            const data = response.seasonList;
            if ((data.find(f => f.id == season) || null) === null) {
                setSeason(null);
            }
            setSeasonData(data);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
        setLoading(false);
        };

        fetchData();
    }, [gameType]);

    useEffect(() => {
        const fetchData = async () => {
        setLoading(true);
        try {
            let client = new PlayersClient();
            const response = await client.getPlayersWithDemos(season, gameType)
                .then(response => response.toJSON() as Promise<IPlayersWithDemosVm>);
            const data = response.playerList;
            if ((data.find(f => f.id == player) || null) === null) {
                setPlayer(null);
            }
            setPlayerData(data);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
        setLoading(false);
        };

        fetchData();
    }, [gameType, season]);

    const renderDemosTable = () => {
        var tableArray = null;
        if (!loading) {
            tableArray = (
                <React.Fragment>
                    <Row>
                        <Col>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>Player Name</th>
                                        <th>File Name</th>
                                        <th>Game Name</th>
                                        <th>Demo Season</th>
                                        <th>Engine Played</th>
                                        <th>Wad Played</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {demoData.length > 0 && demoData.map((s, idx) => (
                                        <tr key={s.id}>
                                            <td><NavLink to={`/player/${s.playerId}`}>{s.playerName}</NavLink></td>
                                            <td><Link to={s.demoLink} target="_blank" rel="noopener noreferrer">Download</Link></td>
                                            <td>{s.gameName}</td>
                                            <td>{s.demoSeason}</td>
                                            <td><a href={s.engineLink} target="_blank" rel="noopener noreferrer">{s.enginePlayed}</a></td>
                                            <td><Link to={s.wadLink} target="_blank" rel="noopener noreferrer">{s.wadPlayed}</Link></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </React.Fragment>);
        } else {
            tableArray = (<span>Loading...</span>);
        }
        return (tableArray);
    };

    const handleModeChange = (e) => {
        setGameType(e.target.value);
    };

    function renderSeasonSelect() {
        return (
            <select value={season} onChange={(e) => setSeason(parseInt(e.target.value))}>
                <option key={null} value={null}>
                    All Seasons
                </option>
                {seasonData.length > 0 && seasonData.map(season => (
                    <option key={season.id} value={season.id}>
                        {season.seasonName}
                    </option>
                ))}
            </select>
        );
    };

    const renderPlayerSelect = () => {
        return (
            <select value={player} onChange={(e) => setPlayer(parseInt(e.target.value))}>
                <option key={null} value={null}>
                    All Players
                </option>
                {playerData.length > 0 && playerData.map(player => (
                    <option key={player.id} value={player.id}>
                        {player.playerName}
                    </option>
                ))}
            </select>
        );
    };

    const renderModeSelect = () => {
        return (
            <select value={gameType} onChange={(e) => handleModeChange(e)}>
                {Object.keys(GameType).map(key => (
                    <option key={key} value={key}>
                        {GameType[key]}
                    </option>
                ))}
            </select>
        );
    };

    const renderDropdownBar = () => {
        return (
            <Row>
                <Col>
                    <div className='clearfix'>
                        {renderSeasonSelect()} {renderModeSelect()} {renderPlayerSelect()}
                    </div>
                    <br />
                </Col>
            </Row>
        );
    };

    return (
        <React.Fragment>
            <h1 id="tabelLabel">Demos</h1>
            {renderDropdownBar()}
            {renderDemosTable()}
        </React.Fragment>
    );
};

export default Demos;