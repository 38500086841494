import * as React from 'react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

interface MatchCategoryTabsProps {
    activeTab: number,
    setActiveTab: React.Dispatch<React.SetStateAction<number>>
};

const MatchCategoryTabs = (props: MatchCategoryTabsProps) => {

    const toggle = (tab: number) => {
        if (props.activeTab !== tab) props.setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 1 })}
                                onClick={() => { toggle(1); }}
                            >
                                Overview
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 2 })}
                                onClick={() => { toggle(2); }}
                            >
                                Performance
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 3 })}
                                onClick={() => { toggle(3); }}
                            >
                                Weapons
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>
        </React.Fragment>);
};

export default MatchCategoryTabs;