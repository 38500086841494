import StepButtons from './StepButtons'
import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, Row, Col, Button, Pagination, PaginationLink, PaginationItem, FormGroup, Form } from 'reactstrap';
import Select from 'react-select';
import {
    ITeamsVm,
    ITeamsDto,
    IWeeklyRequest,
    IRegularSeasonWeeksVm,
    IRegularSeasonWeeksDto,
    MatchesClient,
    WeeksClient,
    CreateMatchesCommand,
    WeeklyRequest,
    NewGame,
    TeamsClient,
    INewGame,
    IMapsVm,
    IMapsDto,
    MapsClient,
    CreateMapCommand,
    MapsDto
} from '../../../WorldDoomLeague';
import { useHistory } from 'react-router-dom';
import { ErrorContext } from '../../../state';
import { StepWizardChildProps } from 'react-step-wizard';
import { ITeamList, IWeekGameTeamList } from './NewSeasonWizardInterfaces';

interface CreateGamesProps extends StepWizardChildProps {
    season: number,
    teams: IWeekGameTeamList[];
    setTeams: React.Dispatch<React.SetStateAction<IWeekGameTeamList[]>>,
};

const CreateGames = (props: CreateGamesProps) => {
    const error = useContext(ErrorContext);
    const [games, setGames] = useState<IWeeklyRequest[]>([{
        weekId: null,
        mapId: null,
        gameList: [] as NewGame[]
    }]);
    const [weeks, setWeeks] = useState<IRegularSeasonWeeksDto[]>([{
        id: null,
        weekNumber: null,
        weekStartDate: null
    }]);
    const [loading, setLoading] = useState(true);
    const [gamesPerWeek, setGamesPerWeek] = useState(1);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [canSubmitGames, setCanSubmitGames] = useState(false);
    const [useHomefieldMaps, setUseHomefieldMaps] = useState(false);
    const [completedGames, setCompletedGames] = useState(false);
    const [canCreateGames, setCanCreateGames] = useState(true);
    const [data, setData] = useState<IMapsDto[]>([]);
    const [mapPack, setMapPack] = useState<string>("");
    const [mapName, setMapName] = useState<string>("");
    const [mapNumber, setMapNumber] = useState(0);
    const [newMapId, setNewMapId] = useState(0);
    let history = useHistory();

    const redirect = () => {
        history.push('/');
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MapsClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IMapsVm>);
                const data = response.mapList;
                setData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [newMapId]);

    const getWeeks = async () => {
        const fetchData = async () => {
                setLoading(true);
                try {
                    let client = new WeeksClient();
                    const response = await client.getRegularSeasonWeeks(props.season)
                        .then(response => response.toJSON() as Promise<IRegularSeasonWeeksVm>);
                    const weekData = response.weekList;
                    setLoading(false);
                    return weekData;
                } catch (e) {
                    error.setError(JSON.parse(e.response));
                }
            };

            return fetchData();
    };

    const getTeams = async () => {
        const fetchData = async () => {
                setLoading(true);
                try {
                    let client = new TeamsClient();
                    const response = await client.getTeamsBySeasonId(props.season)
                        .then(response => response.toJSON() as Promise<ITeamsVm>);
                    const teamData = response.teamList;
                    setLoading(false);
                    return teamData;
                } catch (e) {
                    error.setError(JSON.parse(e.response));
                }
            };

            return fetchData();
    };

    const submitGames = async (evt) => {
        try {
            let client = new MatchesClient();
            const command = new CreateMatchesCommand;
            // Need to do this to get around an NSwag generator bug.
            // https://github.com/RicoSuter/NSwag/issues/2862
            let weekly = [] as WeeklyRequest[];
            for (var idx = 0; idx < games.length; idx++) {
                var addPick = new WeeklyRequest();
                addPick.weekId = games[idx].weekId;
                if (useHomefieldMaps === true) {
                    addPick.mapId = null;
                }
                else {
                    addPick.mapId = games[idx].mapId;
                }
                addPick.gameList = [] as NewGame[];

                games[idx].gameList.map((s, _sidx) => {
                    var addGame = new NewGame();
                    addGame.blueTeam = s.blueTeam;
                    addGame.redTeam = s.redTeam;
                    addGame.gameDateTime = s.gameDateTime;
                    addPick.gameList.push(addGame);
                });

                weekly.push(addPick);
            }

            command.seasonId = props.season;
            command.weeklyGames = weekly;
            const response = await client.createRegularSeason(command);
            setCanSubmitGames(false);
            setCompletedGames(true);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const submitMaps = async (evt) => {
        try {
            let client = new MapsClient();
            const command = new CreateMapCommand;
            command.mapName = mapName;
            command.mapPack = mapPack;
            command.mapNumber = mapNumber;
            const response = await client.create(command);
            setNewMapId(response);
            setMapPack('');
            setMapName('');
            setMapNumber(0);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const createGames = async () => {
        const pad_array = (arr, len, fill) => {
            return arr.concat(Array(len).fill(fill)).slice(0, len);
        };

        setCanCreateGames(false);
        const teams = await getTeams();
        const gameWeeks = await getWeeks();

        if (teams) {
            const maxGamesPerWeek = (teams.length / 2) * gamesPerWeek;

            var newGames = [] as IWeeklyRequest[];
            var newTeams = [] as IWeekGameTeamList[];

            for (var i = 0; i < gameWeeks.length; i++) {
                const buildGames = newGames.concat({
                    weekId: gameWeeks[i].id,
                    mapId: null,
                    gameList: pad_array([] as NewGame[], maxGamesPerWeek, {
                        redTeam: null,
                        blueTeam: null,
                        gameDateTime: null
                    })
                });

                newGames = buildGames;
            };

            const newTeamsArray: ITeamList[] = teams.map((s, _idx) => {
                return { ...s, gamesLeft: gamesPerWeek, isdisabled: false };
            });

            for (var i = 0; i < gameWeeks.length; i++) {
                var weekTeams: IWeekGameTeamList = {
                    WeekTeams: [] as ITeamList[]
                } as IWeekGameTeamList;

                weekTeams.WeekTeams = newTeamsArray;
                newTeams.push(weekTeams);
            };

            setGames(newGames);
            setWeeks(gameWeeks);
            props.setTeams(newTeams);
        }
    };

    const handleMapChange = (weekIndex, value) => {
        const newWeeks = games.map((game, _idx) => {
            if (_idx !== weekIndex) return game;
            return { ...game, mapId: value }
        });

        setGames(newWeeks);
        checkIfFormComplete(newWeeks);
    };

    const handleRedTeamSelected = (weekIndex: number, gameIndex: number, redTeamId: number) => {
        // subtract the gamesLeft to handle disables
        const newTeamsArray = props.teams.map((s, _idx) => {
            if (_idx !== weekIndex) return s;
            s.WeekTeams = s.WeekTeams.map((t, _sidx) => {
                if (t.id !== redTeamId) return t;
                const left = t.gamesLeft - 1;
                const disable = left <= 0;
                return { ...t, gamesLeft: left, isdisabled: disable };
            });
            return s;
        });

        // re-enable a captain if they are deselected.
        if (games[weekIndex].gameList[gameIndex].redTeam !== null) {
            const reenabledOldTeam = newTeamsArray.map((s, _idx) => {
                if (_idx !== weekIndex) return s;
                s.WeekTeams = s.WeekTeams.map((t, _sidx) => {
                    if (t.id !== games[weekIndex].gameList[gameIndex].redTeam) return t;
                    const left = t.gamesLeft + 1;
                    const disable = left <= 0;
                    return { ...t, gamesLeft: left, isdisabled: disable };
                });
                return s;
            });
            props.setTeams(reenabledOldTeam);
        } else {
            const newTeamList = newTeamsArray.map((s, _idx) => {
                if (_idx !== weekIndex) return s;
                s.WeekTeams = s.WeekTeams.map((t, _sidx) => {
                    if (t.id !== redTeamId) return t;
                    if (t.gamesLeft > 0) return { ...t, isdisabled: false };
                    return { ...t, isdisabled: true };
                });
                return s;
            });
            props.setTeams(newTeamList);
        }

        const newRedTeam = games.map((game, _idx) => {
            if (_idx !== weekIndex) return game;
            const newGameList = game.gameList.map((t, _sidx) => {
                if (_sidx !== gameIndex) return t;
                return { ...t, redTeam: redTeamId };
            }) as NewGame[];
            return { ...game, gameList: newGameList }
        });

        setGames(newRedTeam);
        checkIfFormComplete(newRedTeam);
    };

    const handleBlueTeamSelected = (weekIndex: number, gameIndex: number, blueTeamId: number) => {
        // subtract the gamesLeft to handle disables
        const newTeamsArray = props.teams.map((s, _idx) => {
            if (_idx !== weekIndex) return s;
            s.WeekTeams = s.WeekTeams.map((t, _sidx) => {
                if (t.id !== blueTeamId) return t;
                const left = t.gamesLeft - 1;
                const disable = left <= 0;
                return { ...t, gamesLeft: left, isdisabled: disable };
            });
            return s;
        });

        // re-enable a captain if they are deselected.
        if (games[weekIndex].gameList[gameIndex].blueTeam !== null) {
            const reenabledOldTeam = newTeamsArray.map((s, _idx) => {
                if (_idx !== weekIndex) return s;
                s.WeekTeams = s.WeekTeams.map((t, _sidx) => {
                    if (t.id !== games[weekIndex].gameList[gameIndex].blueTeam) return t;
                    const left = t.gamesLeft + 1;
                    const disable = left <= 0;
                    return { ...t, gamesLeft: left, isdisabled: disable };
                });
                return s;
            });
            props.setTeams(reenabledOldTeam);
        } else {
            const newTeamList = newTeamsArray.map((s, _idx) => {
                if (_idx !== weekIndex) return s;
                s.WeekTeams = s.WeekTeams.map((t, _sidx) => {
                    if (t.id !== blueTeamId) return t;
                    if (t.gamesLeft > 0) return { ...t, isdisabled: false };
                    return { ...t, isdisabled: true };
                });
                return s;
            });
            props.setTeams(newTeamList);
        }

        const newRedTeam = games.map((game, _idx) => {
            if (_idx !== weekIndex) return game;
            const newGameList = game.gameList.map((t, _sidx) => {
                if (_sidx !== gameIndex) return t;
                return { ...t, blueTeam: blueTeamId };
            }) as NewGame[];
            return { ...game, gameList: newGameList }
        });

        setGames(newRedTeam);
        checkIfFormComplete(newRedTeam);
    };

    const checkIfFormComplete = (weeks: IWeeklyRequest[]) => {
        if (weeks.every(element => (element.mapId || useHomefieldMaps) && element.weekId && element.gameList.every(game => game.blueTeam && game.redTeam))) {
            setCanSubmitGames(true);
        } else {
            setCanSubmitGames(false);
        }
    };

    // create a list for each map.
    const renderMapDropdown = (weekIndex) => {
        let select = null;
        if (data.length > 0) {
            let maps = [];

            for (var idx = 0; idx < data.length; idx++) {
                var map = new MapsDto();
                map.id = data[idx].id;
                map.mapName = data[idx].mapName;
                map.mapNumber = data[idx].mapNumber;
                map.mapPack = data[idx].mapPack;
                maps.push(map);
            };

            select = (<Select
                options={maps}
                onChange={e => handleMapChange(weekIndex, e.id)}
                isOptionDisabled={(option) => option.isdisabled}
                isDisabled={completedGames || useHomefieldMaps}
                isSearchable={true}
                value={maps.find(o => o.id == games[weekIndex].mapId) || null}
                getOptionValue={value => value.id}
                getOptionLabel={label => label.mapName + " | " + label.mapPack }
                isLoading={loading}
                theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'dimgrey',
                        neutral0: 'black',
                        neutral80: 'white'
                    },
                })}
            />);
        } else {
            select = (<Select options={[{ label: "No maps left!", value: "Not" }]} isDisabled={completedGames} theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'dimgrey',
                    neutral0: 'black',
                    neutral80: 'white'
                },
            })} />);
        }
        return (select);
    };

    // create a form for entering a new engine.
    const renderNewMapForm = () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="mapName">Map Name</Label>
                    <Input type="text" name="mapName" id="mapName" value={mapName} placeholder="N's Base of Boppin'" onChange={e => setMapName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="mapPack">Pack</Label>
                    <Input type="text" name="mapPack" id="mapPack" value={mapPack} placeholder="32in24-4" onChange={e => setMapPack(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="mapNumber">Map Number</Label>
                    <Input placeholder="4" min={1} max={32} type="number" step="1" value={mapNumber} onChange={e => setMapNumber(parseInt(e.target.value, 10))} />
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!mapName || !mapName || !mapNumber || completedGames} onClick={submitMaps}>Create New Map</Button>
            </React.Fragment>
        );
    };

    const renderMapSelect = (weekIndex) => {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Form>
                            <FormGroup>
                                <Input type="checkbox" onChange={(e) => setUseHomefieldMaps(e.target.checked)} /><Label for="checkbox">Use Homefield Maps?</Label>
                                <br />
                                <Label for="engine">Map</Label>
                                    {renderMapDropdown(weekIndex)}
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    const renderMapCreate = () => {
        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Form>
                            {renderNewMapForm()}
                        </Form>
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    // create a list for each nominating captain.
    const renderRedTeamSelection = (weekIndex: number, gameIndex: number) => {
        let select = null;
        if (props.teams[weekIndex] && props.teams[weekIndex].WeekTeams && props.teams[weekIndex].WeekTeams.length > 0) {
            select = (
                <Select
                    options={props.teams[weekIndex].WeekTeams}
                    onChange={e => handleRedTeamSelected(weekIndex, gameIndex, e.id)}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.teamAbbreviation}
                    isOptionDisabled={(option) => option.isdisabled}
                    isDisabled={completedGames}
                    value={props.teams[weekIndex].WeekTeams.find(o => o.id == games[weekIndex].gameList[gameIndex].redTeam) || null}
                    isSearchable={true}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                />)
        } else {
            select = (<Select options={[{ label: "No teams left!", value: "Not" }]} isDisabled={completedGames} theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'dimgrey',
                    neutral0: 'black',
                    neutral80: 'white'
                },
            })} />);
        }

        return (select);
    };

    // create a list for each nominating captain.
    const renderBlueTeamSelection = (weekIndex: number, gameIndex: number) => {
        let select = null;
        if (props.teams[weekIndex] && props.teams[weekIndex].WeekTeams && props.teams[weekIndex].WeekTeams.length > 0) {
            select = (
                <Select
                    options={props.teams[weekIndex].WeekTeams}
                    onChange={e => handleBlueTeamSelected(weekIndex, gameIndex, e.id)}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.teamAbbreviation}
                    isOptionDisabled={(option) => option.isdisabled}
                    isDisabled={completedGames}
                    value={props.teams[weekIndex].WeekTeams.find(o => o.id == games[weekIndex].gameList[gameIndex].blueTeam) || null}
                    isSearchable={true}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                />)
        } else {
            select = (<Select options={[{ label: "No teams left!", value: "Not" }]} isDisabled={completedGames} theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'dimgrey',
                    neutral0: 'black',
                    neutral80: 'white'
                },
            })} />);
        }

        return (select);
    };

    const renderPagination = (weekIndex: number) => {
        let select = null;
        select = (
            <Row>
                <Col sm="3" md={{ size: 6, offset: 3 }}>
                    <Pagination size="lg" aria-label="Page navigation example">
                        {weeks && weeks.map((week, idx) =>
                            <PaginationItem active={week.weekNumber - 1 == weekIndex}>
                                <PaginationLink key={week.id} onClick={e => setCurrentWeek(week.weekNumber - 1)}>
                                    {week.weekNumber}
                                </PaginationLink>
                            </PaginationItem>
                        )}
                        </Pagination>
                </Col>
            </Row>);
        return (select);
    };

    // render the game list
    const renderGamesList = (weekIndex: number) => {
        let gameList = (
            <React.Fragment>
                {games[weekIndex] && canCreateGames == false && (games[weekIndex].gameList.map((game, gameIndex) => (
                    <Row>
                        <Col xs="6">
                            <div><p className="text-danger">Red Team: </p>{renderRedTeamSelection(weekIndex, gameIndex)}</div>
                            <br />
                        </Col>
                        <Col xs="6">
                            <div><p className="text-primary">Blue Team: </p>{renderBlueTeamSelection(weekIndex, gameIndex)}</div>
                            <br />
                        </Col>
                    </Row>
                )))}
            </React.Fragment>);

        return (gameList);
    };

    // render the game list container.
    const renderGamesListContainer = (weekIndex: number) => {
        let games = (
            <React.Fragment>
            <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <h4 className="text-center">Week #{weeks[weekIndex] && weeks[weekIndex].weekNumber}</h4>
                </Col>
            </Row>
                {renderGamesList(weekIndex)}
                <br />
                {renderMapSelect(weekIndex)}
                <br />
                {renderPagination(weekIndex)}
                <br />
                {renderMapCreate()}
                <br />
            </React.Fragment>);
        return (games);
    };

    return (
        <React.Fragment>
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <h3 className='text-center'>Create Regular Season Games</h3>
                    <p>Please input the games that will be scheduled for each week in the regular season.</p>
                    <Label for="amountTeams">Amount of games per team</Label>
                    <Input placeholder="1" name="gamesPerTeam" min={1} max={4} type="number" step="1" value={gamesPerWeek} onChange={e => setGamesPerWeek(parseInt(e.target.value, 10))} />
                    <br />
                    <Button color="primary" size="lg" block disabled={!canCreateGames || loading} onClick={createGames}>Create Games</Button>
                    <hr />
                </Col>
            </Row>
            {games && canCreateGames == false && (
                renderGamesListContainer(currentWeek)
            )}
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <Button color="primary" size="lg" block disabled={!canSubmitGames} onClick={submitGames}>Finalize Games</Button>
                    <br />
                </Col>
            </Row>
        <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <Button color="secondary" size="lg" block disabled={!completedGames} onClick={redirect}>Finish</Button>
            </Col>
        </Row>
        </React.Fragment>
    );
};

export default CreateGames;