import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundFragStatsCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsFragTable from './AdHocMatchOverallWeaponsFragTable';

interface AdHocMatchOverallWeaponCategoryFragTabsViewProps {
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    finalsBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponCategoryFragTabsView = (props: AdHocMatchOverallWeaponCategoryFragTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeWeapon}>
                {props.categoryObject && props.categoryObject.FragWeaponCategories.map((s, idx) => (
                    <TabPane tabId={s}>
                        <AdHocMatchOverallWeaponsFragTable activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalsBoxScore} />
                    </TabPane>
                    ))}
            </TabContent>
        </React.Fragment>);
};

export default AdHocMatchOverallWeaponCategoryFragTabsView;