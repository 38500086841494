import * as React from 'react';
import { useState } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { IPlayerCaptainDraftRecordSeasonDto, IPlayerSummaryDraftDto, IPlayerSummaryStatsDto, IPlayerSummaryTradeDto, ISeasonsPlayedInDto } from '../../WorldDoomLeague';
import PlayerCaptainDraft from './PlayerCaptainDraft';
import PlayerCombinedStats from './PlayerCombinedStats';
import PlayerDraft from './PlayerDraft';
import PlayerSeasonsPlayedIn from './PlayerSeasonsPlayedIn';
import PlayerTrades from './PlayerTrades';

interface PlayerViewProps {
    draft: IPlayerSummaryDraftDto[];
    captainDraft: IPlayerCaptainDraftRecordSeasonDto[];
    trades: IPlayerSummaryTradeDto[];
    combinedStats: IPlayerSummaryStatsDto;
    seasonsPlayedIn: ISeasonsPlayedInDto[];
    activeTab: number;
};

const PlayerView = (props: PlayerViewProps) => {
    return (
        <React.Fragment>
            <TabContent activeTab={props.activeTab}>
                <TabPane tabId={1}>
                    <PlayerDraft draft={props.draft} />
                </TabPane>
                <TabPane tabId={2}>
                    <PlayerCaptainDraft captainDraft={props.captainDraft} />
                </TabPane>
                <TabPane tabId={3}>
                    <PlayerTrades trades={props.trades} />
                </TabPane>
                <TabPane tabId={4}>
                    <PlayerSeasonsPlayedIn seasonsPlayedIn={props.seasonsPlayedIn} />
                </TabPane>
                <TabPane tabId={5}>
                    <PlayerCombinedStats combinedStats={props.combinedStats} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default PlayerView;