import * as React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { OrdinalSuffixOf } from '../../helpers';
import { ISeasonDraftDto } from '../../WorldDoomLeague';

interface SeasonDraftProps {
    draft: ISeasonDraftDto[];
}

const SeasonDraft = (props: SeasonDraftProps) => {
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card className='bg-primary mb-3'>
                        <CardHeader>
                            Draft
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <h4 className='text-center'>Round #1</h4>
                                </Col>
                                <Col>
                                    <h4 className='text-center'>Round #2</h4>
                                </Col>
                                <Col>
                                    <h4 className='text-center'>Round #3</h4>
                                </Col>
                            </Row>
                            <div className='draft-list'>
                            <ol>
                                {props.draft.map((s, idx) => (
                                    <React.Fragment>
                                        <li>{s.teamNominating} nominates {s.playerNominated}</li>
                                        <ul>
                                            <li>Sold to {s.teamSoldTo} for {new Intl.NumberFormat('default', { style: 'currency', currency: 'USD' }).format(s.sellPrice)} ({OrdinalSuffixOf(s.teamDraftPosition)})</li>
                                        </ul>
                                    </React.Fragment>
                                ))}
                                </ol>
                                </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SeasonDraft;