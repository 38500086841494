import * as React from 'react';
import { GameModeType, IAdHocRoundBoxScoreDto, IMatchFinalBoxScoreDto } from '../../../../WorldDoomLeague';
import AdHocMatchOverallPerformanceFragStats from './AdHocMatchOverallPerformanceFragStats';
import AdHocMatchOverallPerformanceCaptureStats from './AdHocMatchOverallPerformanceCaptureStats';
import AdHocMatchOverallPerformanceTouchesStats from './AdHocMatchOverallPerformanceTouchesStats';
import AdHocMatchOverallPerformanceDamageStats from './AdHocMatchOverallPerformanceDamageStats';
import AdHocMatchOverallPerformancePickupStats from './AdHocMatchOverallPerformancePickupStats';

interface AdHocMatchOverallPerformanceStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto,
    gameMode: GameModeType
};

const AdHocMatchOverallPerformanceStats = (props: AdHocMatchOverallPerformanceStatsProps) => {

    return (
        <React.Fragment>
            <AdHocMatchOverallPerformanceFragStats finalBoxScore={props.finalBoxScore} gameMode={props.gameMode} />
            <AdHocMatchOverallPerformanceDamageStats finalBoxScore={props.finalBoxScore} gameMode={props.gameMode} />
            <AdHocMatchOverallPerformanceCaptureStats finalBoxScore={props.finalBoxScore} gameMode={props.gameMode} />
            <AdHocMatchOverallPerformanceTouchesStats finalBoxScore={props.finalBoxScore} gameMode={props.gameMode} />
            <AdHocMatchOverallPerformancePickupStats finalBoxScore={props.finalBoxScore} />
        </React.Fragment>
    );
};

export default AdHocMatchOverallPerformanceStats;