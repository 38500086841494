import * as React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { formatdecider, FormatHhMmSs } from '../../helpers';
import { IPlayerSummaryStatsDto } from '../../WorldDoomLeague';

interface PlayerCombinedStatsProps {
    combinedStats: IPlayerSummaryStatsDto;
}

const PlayerCombinedStats = (props: PlayerCombinedStatsProps) => {
    return (
        <React.Fragment>
            <Card className='bg-danger mb-3'>
                <CardHeader>
                    <div className='text-center'>Combined Player Stats</div>
                </CardHeader>
                <CardBody>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Games Played</th>
                                <th>Rounds Played</th>
                                <th>Time Played</th>
                                <th>Points</th>
                                <th>Captures</th>
                                <th>P. Captures</th>
                                <th>Assists</th>
                                <th>Touches</th>
                                <th>P. Touches</th>
                                <th>Frags</th>
                                <th>Deaths</th>
                                <th>Flag Defs</th>
                                <th>Damage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatdecider(props.combinedStats.gamesPlayed)}</td>
                                <td>{formatdecider(props.combinedStats.roundsPlayed)}</td>
                                <td>{FormatHhMmSs(props.combinedStats.totalTimePlayed)}</td>
                                <td>{formatdecider(props.combinedStats.points)}</td>
                                <td>{formatdecider(props.combinedStats.captures)}</td>
                                <td>{formatdecider(props.combinedStats.pickupCaptures)}</td>
                                <td>{formatdecider(props.combinedStats.assists)}</td>
                                <td>{formatdecider(props.combinedStats.flagTouches)}</td>
                                <td>{formatdecider(props.combinedStats.pickupFlagTouches)}</td>
                                <td>{formatdecider(props.combinedStats.totalFrags)}</td>
                                <td>{formatdecider(props.combinedStats.deaths)}</td>
                                <td>{formatdecider(props.combinedStats.flagCarrierFrags)}</td>
                                <td>{formatdecider(props.combinedStats.damage)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default PlayerCombinedStats;