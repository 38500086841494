import * as React from 'react';
import { useState } from 'react';
import { ITeamDraftDto, ITeamGameDto, ITeamPlayerDto, ITeamStatsDto, ITeamTradesDto } from '../../WorldDoomLeague';
import TeamView from './TeamView';
import TeamViewTabs from './TeamViewTabs';

interface TeamDetailsProps {
    draft: ITeamDraftDto[];
    trades: ITeamTradesDto[];
    regularSeasonGames: ITeamGameDto[];
    playoffsGames: ITeamGameDto[];
    finalsGames: ITeamGameDto[];
    combinedStats: ITeamStatsDto;
    initialPlayers: ITeamPlayerDto[];
    totalGamePlayers: ITeamPlayerDto[];
    currentPlayers: ITeamPlayerDto[];
}

const TeamDetails = (props: TeamDetailsProps) => {
    const [activeTab, setActiveTab] = useState(1);
    return (
        <React.Fragment>
            <TeamViewTabs activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <TeamView draft={props.draft}
                trades={props.trades}
                regularSeasonGames={props.regularSeasonGames}
                playoffsGames={props.playoffsGames}
                finalsGames={props.finalsGames}
                combinedStats={props.combinedStats}
                initialPlayers={props.initialPlayers}
                currentPlayers={props.currentPlayers}
                totalGamePlayers={props.totalGamePlayers}
                activeTab={activeTab}
            />
        </React.Fragment>);
};

export default TeamDetails;