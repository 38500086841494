import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundDamageStatsCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsDamageTable from './AdHocMatchOverallWeaponsDamageTable';

interface AdHocMatchOverallWeaponCategoryDamageTabsViewProps {
    categoryObject: MatchRoundDamageStatsCategories;
    activeWeapon: string;
    finalBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponCategoryDamageTabsView = (props: AdHocMatchOverallWeaponCategoryDamageTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeWeapon}>
                {props.categoryObject && props.categoryObject.DamageWeaponCategories.map((s, idx) => (
                    <TabPane tabId={s}>
                        <AdHocMatchOverallWeaponsDamageTable activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                    </TabPane>
                    ))}
            </TabContent>
        </React.Fragment>);
};

export default AdHocMatchOverallWeaponCategoryDamageTabsView;