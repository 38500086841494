import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, FormGroup, Form, Button, Alert, Row, Col } from 'reactstrap';
import {
    MapsClient,
    IMapsDto,
    IMapsVm,
    EditMapCommand
} from '../../../WorldDoomLeague';
import Select from 'react-select';
import { ErrorContext } from '../../../state';
import NewMap from './NewMap';
import DeleteMap from './DeleteMap';

const EditMap = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IMapsDto[]>([]);
    const [mapObject, setMapObject] = useState<IMapsDto>();
    const [mapFormName, setMapFormName] = useState<string>("");
    const [mapFormPack, setMapFormPack] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [mapId, setMapId] = useState(0);
    const [mapNumber, setMapNumber] = useState(0);
    const [newMapId, setNewMapId] = useState(0);
    const [deletedMapId, setDeletedMapId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MapsClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IMapsVm>);
                const data = response.mapList;
                setData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [newMapId, deletedMapId]);

    const handleSubmit = async (evt) => {
        try {
            let client = new MapsClient();
            const command = new EditMapCommand;
            command.mapId = mapObject.id
            command.mapName = mapFormName;
            command.mapPack = mapFormPack;
            command.mapNumber = mapNumber;
            const response = await client.edit(mapObject.id, command);
            setNewMapId(response);
            setMapFormName('');
            setMapFormPack('');
            setMapNumber(0);
            setCanSubmit(false);
            setMapObject(null);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    // create a list for each map.
    const renderMapDropdown = () => {
        let select = null;
        if (data.length > 0) {
            select = (
                <Select
                    options={data}
                    value={mapObject || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.mapName + " | " + label.mapPack}
                    onChange={e => handleSetObject(e)}
                    isLoading={loading}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                />
            );
        }
        else
        {
            select = (
                <Select options={[{ label: "No maps found in the system.", value: "Not" }]} theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'dimgrey',
                        neutral0: 'black',
                        neutral80: 'white'
                    },
                })} />
            );
        }
        return select;
    };

    const handleMapNameChange = (mapName: string) => {
        setMapFormName(mapName);
        if (mapName !== mapObject.mapName || mapFormPack !== mapObject.mapPack || mapNumber !== mapObject.mapNumber) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    };

    const handleMapPackChange = (mapPack: string) => {
        setMapFormPack(mapPack);
        if (mapFormName !== mapObject.mapName || mapPack !== mapObject.mapPack || mapNumber !== mapObject.mapNumber) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    };

    const handleMapNumberChange = (mapnum: number) => {
        setMapNumber(mapnum);
        if (mapFormName !== mapObject.mapName || mapFormPack !== mapObject.mapPack || mapnum !== mapObject.mapNumber) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    };

    const handleSetObject = (obj: IMapsDto) => {
        setMapFormName(obj.mapName);
        setMapFormPack(obj.mapPack);
        setMapNumber(obj.mapNumber);
        setMapId(obj.id);
        setMapObject(obj);
    };

    // create a form for entering a new engine.
    const renderEditMapForm = () => {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label for="mapName">Map Name</Label>
                    <Input type="text" name="mapName" id="mapName" value={mapFormName} disabled={mapId <= 0} placeholder="Something Epic" onChange={e => handleMapNameChange(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="mapPack">Map Pack</Label>
                    <Input type="text" name="mapPack" id="mapPack" value={mapFormPack} disabled={mapId <= 0} placeholder="ZDCTFMP1" onChange={e => handleMapPackChange(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label for="mapNumber">Map Number</Label>
                    <Input placeholder="Amount" name="mapNumber" min={1} max={32} type="number" step="1" value={mapNumber} disabled={mapId <= 0} onChange={e => handleMapNumberChange(parseInt(e.target.value, 10))} />
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!canSubmit} onClick={handleSubmit}>Edit Map</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs="6" sm="4">
                    <Form>
                        <FormGroup>
                            <h2 className='text-center'>Edit Map</h2>
                            {renderMapDropdown()}
                            {renderEditMapForm()}
                        </FormGroup>
                    </Form>
                </Col>
                <Col xs="6" sm="4">
                    <Form>
                        <h2 className='text-center'>Create New Map</h2>
                        <NewMap setNewMapId={setNewMapId} />
                    </Form>
                </Col>
                <Col xs="6" sm="4">
                    <Form>
                        <h2 className='text-center'>Delete Unplayed Map</h2>
                        <p>Only maps that haven't been played or aren't slated to play in a season can be deleted.</p>
                        <DeleteMap newMapId={newMapId} setDeletedMapId={setDeletedMapId} />
                    </Form>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default EditMap;