import * as React from 'react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { MatchRoundFragStatsCategories } from '../../../../../helpers';

interface MatchRoundWeaponsFragTabsProps {
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    category: MatchRoundFragStatsCategories;
};

const MatchRoundWeaponsFragTabs = (props: MatchRoundWeaponsFragTabsProps) => {

    const toggle = (tab: string) => {
        if (props.activeWeapon !== tab) {
            props.setActiveWeapon(tab);
        }
    };

    return (
        <React.Fragment>
            {props.category && props.category.FragWeaponCategories.length > 0 && (
                <Row className='mb-3'>
                    <Col>
                        <Nav tabs>
                            {props.category.FragWeaponCategories.map((s, idx) => (
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: props.activeWeapon === s })}
                                        onClick={() => { toggle(s); }}
                                    >
                                        {s}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </Col>
                </Row>
            )}
        </React.Fragment>);
};

export default MatchRoundWeaponsFragTabs;