import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    IAdHocMatchSummaryVm,
    AdHocGamesClient,
} from '../../WorldDoomLeague';
import {ErrorContext} from '../../state';

import AdHocPostMatchSummary from './AdHocPostMatchSummary'
import AdHocMatchMetadata from './AdHocMatchMetadata';
import AdHocMatchCategoryRoundView from './AdHocMatchCategoryRoundView';
import { IErrorResponse } from '../../ErrorResponse';
import { match } from 'assert';

interface AdHodMatchRouterProps {
    id: string;   // id of the match (coming from the router)
}

interface AdHocMatchProps extends RouteComponentProps<AdHodMatchRouterProps> {
    // Add your regular properties here
}

const AdHocMatch = (props: AdHocMatchProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const error = useContext(ErrorContext);
    const [matchData, setMatchData] = useState<IAdHocMatchSummaryVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new AdHocGamesClient();
                const response = await client.getAdHocGameById(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<IAdHocMatchSummaryVm>);
                setMatchData(response);
            } catch (e) {
                error.setError(JSON.parse(e.response) as IErrorResponse);
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            {matchData && (
                <React.Fragment>
                    <AdHocPostMatchSummary
                        lineScore={matchData.lineScore}
                        winningTeam={matchData.winnerInfo}
                        gameMode={matchData.gameMode}
                        greenCount={matchData.roundBoxScore.greenTeamPlayerRoundBoxScore.length}
                    />

                    <AdHocMatchMetadata
                        gameDateTime={matchData.gamePlayedTime}
                        serverPlayed={matchData.serverName}
                        gameTimeTotal={matchData.gameTimeTotal}
                        mapPlayed={matchData.mapPlayed}
                        gameType={matchData.gameMode}
                    />

                    <AdHocMatchCategoryRoundView
                        gameData={matchData}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default AdHocMatch;