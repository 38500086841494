import * as React from 'react';
import { Table } from 'reactstrap';
import { FormatHhMmSs, pcntgformat } from '../../helpers';
import { IPlayerCaptainRecordDto } from '../../WorldDoomLeague';

interface PlayerRecordsTableProps {
    totalRecord: IPlayerCaptainRecordDto;
    regularSeason: IPlayerCaptainRecordDto;
    playoffs: IPlayerCaptainRecordDto;
    finals: IPlayerCaptainRecordDto;
}

const PlayerRecordsTable = (props: PlayerRecordsTableProps) => {
    return (
        <React.Fragment>
            <h2 className='text-center mb-2'>Player Game/Round Records</h2>
            <Table className='table-sm mb-3'>
                <thead>
                    <tr>
                        <th rowSpan={2} colSpan={1} >
                            Record Type
                        </th>
                        <th colSpan={4} >
                            Total
                        </th>
                        <th colSpan={4}>
                            As Captain
                        </th>
                    </tr>
                    <tr>
                        <th>Rounds</th>
                        <th>Win %</th>
                        <th>Matches</th>
                        <th>Win %</th>
                        <th>Rounds</th>
                        <th>Win %</th>
                        <th>Matches</th>
                        <th>Win %</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Total Record</td>
                        <td>{props.totalRecord.total.rounds.wins}-{props.totalRecord.total.rounds.losses}-{props.totalRecord.total.rounds.ties}</td>
                        <td>{pcntgformat(props.totalRecord.total.rounds.winPercentage)}</td>
                        <td>{props.totalRecord.total.matches.wins}-{props.totalRecord.total.matches.losses}-{props.totalRecord.total.matches.ties}</td>
                        <td>{pcntgformat(props.totalRecord.total.matches.winPercentage)}</td>
                        <td>{props.totalRecord.asCaptain.rounds.wins}-{props.totalRecord.asCaptain.rounds.losses}-{props.totalRecord.asCaptain.rounds.ties}</td>
                        <td>{pcntgformat(props.totalRecord.asCaptain.rounds.winPercentage)}</td>
                        <td>{props.totalRecord.asCaptain.matches.wins}-{props.totalRecord.asCaptain.matches.losses}-{props.totalRecord.asCaptain.matches.ties}</td>
                        <td>{pcntgformat(props.totalRecord.asCaptain.matches.winPercentage)}</td>
                    </tr>
                    <tr>
                        <td>Regular Season Record</td>
                        <td>{props.regularSeason.total.rounds.wins}-{props.regularSeason.total.rounds.losses}-{props.regularSeason.total.rounds.ties}</td>
                        <td>{pcntgformat(props.regularSeason.total.rounds.winPercentage)}</td>
                        <td>{props.regularSeason.total.matches.wins}-{props.regularSeason.total.matches.losses}-{props.regularSeason.total.matches.ties}</td>
                        <td>{pcntgformat(props.regularSeason.total.matches.winPercentage)}</td>
                        <td>{props.regularSeason.asCaptain.rounds.wins}-{props.regularSeason.asCaptain.rounds.losses}-{props.regularSeason.asCaptain.rounds.ties}</td>
                        <td>{pcntgformat(props.regularSeason.asCaptain.rounds.winPercentage)}</td>
                        <td>{props.regularSeason.asCaptain.matches.wins}-{props.regularSeason.asCaptain.matches.losses}-{props.regularSeason.asCaptain.matches.ties}</td>
                        <td>{pcntgformat(props.regularSeason.asCaptain.matches.winPercentage)}</td>
                    </tr>
                    <tr>
                        <td>Playoff Record</td>
                        <td>{props.playoffs.total.rounds.wins}-{props.playoffs.total.rounds.losses}-{props.playoffs.total.rounds.ties}</td>
                        <td>{pcntgformat(props.playoffs.total.rounds.winPercentage)}</td>
                        <td>{props.playoffs.total.matches.wins}-{props.playoffs.total.matches.losses}-{props.playoffs.total.matches.ties}</td>
                        <td>{pcntgformat(props.playoffs.total.matches.winPercentage)}</td>
                        <td>{props.playoffs.asCaptain.rounds.wins}-{props.playoffs.asCaptain.rounds.losses}-{props.playoffs.asCaptain.rounds.ties}</td>
                        <td>{pcntgformat(props.playoffs.asCaptain.rounds.winPercentage)}</td>
                        <td>{props.playoffs.asCaptain.matches.wins}-{props.playoffs.asCaptain.matches.losses}-{props.playoffs.asCaptain.matches.ties}</td>
                        <td>{pcntgformat(props.playoffs.asCaptain.matches.winPercentage)}</td>
                    </tr>
                    <tr>
                        <td>Finals Record</td>
                        <td>{props.finals.total.rounds.wins}-{props.finals.total.rounds.losses}-{props.finals.total.rounds.ties}</td>
                        <td>{pcntgformat(props.finals.total.rounds.winPercentage)}</td>
                        <td>{props.finals.total.matches.wins}-{props.finals.total.matches.losses}-{props.finals.total.matches.ties}</td>
                        <td>{pcntgformat(props.finals.total.matches.winPercentage)}</td>
                        <td>{props.finals.asCaptain.rounds.wins}-{props.finals.asCaptain.rounds.losses}-{props.finals.asCaptain.rounds.ties}</td>
                        <td>{pcntgformat(props.finals.asCaptain.rounds.winPercentage)}</td>
                        <td>{props.finals.asCaptain.matches.wins}-{props.finals.asCaptain.matches.losses}-{props.finals.asCaptain.matches.ties}</td>
                        <td>{pcntgformat(props.finals.asCaptain.matches.winPercentage)}</td>
                    </tr>
                </tbody>
            </Table>
        </React.Fragment>
    );
};

export default PlayerRecordsTable;