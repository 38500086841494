import * as React from 'react';
import { IMatchFinalBoxScoreDto } from '../../../../WorldDoomLeague';
import MatchOverallWeaponsDamageStats from './damage/MatchOverallWeaponsDamageStats';
import MatchOverallWeaponsFragsStats from './frags/MatchOverallWeaponsFragStats';

interface MatchOverallWeaponsStatsProps {
    activeRound: number,
    finalBoxScore: IMatchFinalBoxScoreDto
};

const MatchOverallWeaponsStats = (props: MatchOverallWeaponsStatsProps) => {

    return (
        <React.Fragment>
            <MatchOverallWeaponsFragsStats activeRound={props.activeRound} finalsBoxScore={props.finalBoxScore} />
            <MatchOverallWeaponsDamageStats activeRound={props.activeRound} finalBoxScore={props.finalBoxScore} />
        </React.Fragment>
    );
};

export default MatchOverallWeaponsStats;