import * as React from 'react';
import { IMatchFinalBoxScoreDto } from '../../../../WorldDoomLeague';
import MatchOverallPerformanceFragStats from './MatchOverallPerformanceFragStats';
import MatchOverallPerformanceCaptureStats from './MatchOverallPerformanceCaptureStats';
import MatchOverallPerformanceTouchesStats from './MatchOverallPerformanceTouchesStats';
import MatchOverallPerformanceDamageStats from './MatchOverallPerformanceDamageStats';
import MatchOverallPerformancePickupStats from './MatchOverallPerformancePickupStats';

interface MatchOverallPerformanceStatsProps {
    finalBoxScore: IMatchFinalBoxScoreDto
};

const MatchOverallPerformanceStats = (props: MatchOverallPerformanceStatsProps) => {

    return (
        <React.Fragment>
            <MatchOverallPerformanceFragStats finalBoxScore={props.finalBoxScore} />
            <MatchOverallPerformanceDamageStats finalBoxScore={props.finalBoxScore} />
            <MatchOverallPerformanceCaptureStats finalBoxScore={props.finalBoxScore} />
            <MatchOverallPerformanceTouchesStats finalBoxScore={props.finalBoxScore} />
            <MatchOverallPerformancePickupStats finalBoxScore={props.finalBoxScore} />
        </React.Fragment>
    );
};

export default MatchOverallPerformanceStats;