import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { GameModeType, IAdHocMatchStatsDto, IAdHocRoundBoxScoreDto } from '../../../../WorldDoomLeague';

interface AdHocMatchOverallPerformanceFragStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto,
    gameMode: GameModeType
};

const AdHocMatchOverallPerformanceFragStats = (props: AdHocMatchOverallPerformanceFragStatsProps) => {
    const CreateHeader = (gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (<tr>
                <th>Name</th>
                <th>Frags</th>
                <th>Max Frags before cap</th>
                <th>Flag Defs</th>
                <th>Flag Defs w/ Flag</th>
                <th>Deaths</th>
                <th>Env. Deaths</th>
                <th>Env. Flag Deaths</th>
                <th>Suicides</th>
                <th>Suicides w/ flag</th>
                <th>Team Kills</th>
                <th>Longest Spree</th>
                <th>Highest Multi Kill</th>
            </tr>);
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (<tr>
                <th>Name</th>
                <th>Frags</th>
                <th>Deaths</th>
                <th>Env. Deaths</th>
                <th>Suicides</th>
                <th>Team Kills</th>
                <th>Longest Spree</th>
                <th>Highest Multi Kill</th>
            </tr>);
        }
        else {
            return (<tr>
                <th>Name</th>
                <th>Frags</th>
                <th>Deaths</th>
                <th>Env. Deaths</th>
                <th>Suicides</th>
                <th>Longest Spree</th>
                <th>Highest Multi Kill</th>
            </tr>);
        }
    };

    const CreateFragStatsRows = (boxscore: IAdHocMatchStatsDto, gamemode: GameModeType) => {
        if (gamemode == GameModeType.CaptureTheFlag) {
            return (
                <React.Fragment>
                    <td>{boxscore.frags}</td>
                    <td>{boxscore.highestKillsBeforeCapturing}</td>
                    <td>{boxscore.flagDefenses}</td>
                    <td>{boxscore.flagCarriersKilledWhileHoldingFlag}</td>
                    <td>{boxscore.deaths}</td>
                    <td>{boxscore.environmentalDeaths}</td>
                    <td>{boxscore.environmentalFlagCarrierDeaths}</td>
                    <td>{boxscore.suicides}</td>
                    <td>{boxscore.suicidesWithFlag}</td>
                    <td>{boxscore.teamKills}</td>
                    <td>{boxscore.longestSpree}</td>
                    <td>{boxscore.highestMultiKill}</td>
                </React.Fragment>
            );
        }
        else if (gamemode == GameModeType.TeamDeathmatch) {
            return (
                <React.Fragment>
                    <td>{boxscore.frags}</td>
                    <td>{boxscore.deaths}</td>
                    <td>{boxscore.environmentalDeaths}</td>
                    <td>{boxscore.suicides}</td>
                    <td>{boxscore.teamKills}</td>
                    <td>{boxscore.longestSpree}</td>
                    <td>{boxscore.highestMultiKill}</td>
                </React.Fragment>
            );
        }
        else {
            return (<React.Fragment>
                <td>{boxscore.frags}</td>
                <td>{boxscore.deaths}</td>
                <td>{boxscore.environmentalDeaths}</td>
                <td>{boxscore.suicides}</td>
                <td>{boxscore.longestSpree}</td>
                <td>{boxscore.highestMultiKill}</td>
            </React.Fragment>);
        }
    };
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button color="primary" block id="fragtoggler" style={{ marginBottom: '1rem' }}>
                        Frags
                    </Button>
                    <UncontrolledCollapse toggler="#fragtoggler">
                        <Row>
                            <Col>
                                <h2>Frags</h2>
                                <Table responsive>
                                    <thead>
                                        {CreateHeader(props.gameMode)}
                                    </thead>
                                    <tbody>
                                        {props.finalBoxScore.redTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateFragStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateFragStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.greenTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-success'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateFragStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                        {props.finalBoxScore.nonTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-dark'>
                                                <th scope="row">{s.playerName}</th>
                                                {CreateFragStatsRows(s.matchStats, props.gameMode)}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AdHocMatchOverallPerformanceFragStats;