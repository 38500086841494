import StepButtons from './StepButtons'
import * as React from 'react';
import { Row, Col } from 'reactstrap';
import PlayerList from './PlayerList';
import { StepWizardChildProps } from 'react-step-wizard';
import { useState } from 'react';
import { IPlayerDto } from '../../../WorldDoomLeague';
import { IPlayerList } from './NewSeasonWizardInterfaces';

interface AddPlayersProps extends StepWizardChildProps {
    setPlayerList: React.Dispatch<React.SetStateAction<IPlayerList[]>>;
}

const AddPlayers = (props: AddPlayersProps) => {
    const [players, setPlayers] = useState<IPlayerList[]>([]);

    const handleSetPlayers = (playerList: IPlayerList[]) => {
        setPlayers(playerList);
        props.setPlayerList(playerList);
    };

    return (
        <React.Fragment>
        <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
                <h3 className='text-center'>Add Signups</h3>
                <p>This next step will add player signups. This will allow you to search current players, and add a new player if they are not found.</p>
            </Col>
        </Row>
        <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <PlayerList setPlayerList={handleSetPlayers} />
                    <StepButtons {...props} step={2} disabled={players.length < 24}/>
            </Col>
        </Row>
        </React.Fragment>
    );
};

export default AddPlayers;