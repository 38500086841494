import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { DamageWeaponCategories, MatchRoundDamageStatsCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsDamageTabsView from './AdHocMatchOverallWeaponsDamageTabsView';
import AdHocMatchOverallWeaponsDamageTabs from './AdHocMatchOverallWeaponsDamageTabs';

interface AdHocMatchOverallWeaponsDamageCategoryTabsViewProps {
    category: DamageWeaponCategories;
    categoryObject: MatchRoundDamageStatsCategories;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    finalBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponsDamageCategoryTabsView = (props: AdHocMatchOverallWeaponsDamageCategoryTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.category}>
                <TabPane tabId={DamageWeaponCategories.Damage}>
                    <AdHocMatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.DamageWithFlag}>
                    <AdHocMatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.CarrierDamage}>
                    <AdHocMatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
                <TabPane tabId={DamageWeaponCategories.CarrierDamageWithFlag}>
                    <AdHocMatchOverallWeaponsDamageTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <AdHocMatchOverallWeaponsDamageTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default AdHocMatchOverallWeaponsDamageCategoryTabsView;