import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundAccuracyStatsCategories } from '../../../../../helpers';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsAccuracyTable from './AdHocMatchOverallWeaponsAccuracyTable';

interface AdHocMatchOverallWeaponCategoryAccuracyTabsViewProps {
    categoryObject: MatchRoundAccuracyStatsCategories;
    activeWeapon: string;
    finalBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponCategoryAccuracyTabsView = (props: AdHocMatchOverallWeaponCategoryAccuracyTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeWeapon}>
                {props.categoryObject && props.categoryObject.AccuracyWeaponCategories.map((s, idx) => (
                    <TabPane tabId={s}>
                        <AdHocMatchOverallWeaponsAccuracyTable activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalBoxScore} />
                    </TabPane>
                    ))}
            </TabContent>
        </React.Fragment>);
};

export default AdHocMatchOverallWeaponCategoryAccuracyTabsView;