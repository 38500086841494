import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundFragStatsCategories, FragWeaponCategories } from '../../../../../helpers';
import { IMatchFinalBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchOverallWeaponsFragTabsView from './MatchOverallWeaponsFragTabsView';
import MatchOverallWeaponsFragTabs from './MatchOverallWeaponsFragTabs';

interface MatchOverallWeaponsFragCategoryTabsViewProps {
    category: FragWeaponCategories;
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
    finalsBoxScore: IMatchFinalBoxScoreDto;
};

const MatchOverallWeaponsFragCategoryTabsView = (props: MatchOverallWeaponsFragCategoryTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.category}>
                <TabPane tabId={FragWeaponCategories.Frags}>
                    <MatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.FragsWithFlag}>
                    <MatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.CarrierFrags}>
                    <MatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
                <TabPane tabId={FragWeaponCategories.CarrierFragsWithFlag}>
                    <MatchOverallWeaponsFragTabs activeWeapon={props.activeWeapon} setActiveWeapon={props.setActiveWeapon} category={props.categoryObject} />
                    <MatchOverallWeaponsFragTabsView activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalsBoxScore={props.finalsBoxScore} />
                </TabPane>
            </TabContent>
        </React.Fragment>);
};

export default MatchOverallWeaponsFragCategoryTabsView;