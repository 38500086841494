import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import {
    IActiveInactiveSeasonVm,
    SeasonsClient
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import { DropdownItem, DropdownMenu, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

interface SeasonNavProps {
    // Add your regular properties here
}

const SeasonNav = (props: SeasonNavProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [seasonData, setSeasonData] = useState<IActiveInactiveSeasonVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SeasonsClient();
                const response = await client.getActiveInactive()
                    .then(response => response.toJSON() as Promise<IActiveInactiveSeasonVm>);
                setSeasonData(response);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            {seasonData && (
                <DropdownMenu right>
                    <DropdownItem header>
                        Current Seasons
                    </DropdownItem>
                    <DropdownItem divider />
                    {seasonData.activeSeasonList.length > 0 && seasonData.activeSeasonList.map((s, idx) => (
                        <DropdownItem key={s.id}>
                            <NavLink tag={Link} to={`/season/${s.id}`}>{s.seasonName}</NavLink>
                        </DropdownItem>
                    ))}
                    {seasonData.activeSeasonList.length <= 0 && (
                        <DropdownItem>
                            No active seasons!
                        </DropdownItem>
                    )}
                    <DropdownItem divider />
                    <DropdownItem header>
                        Previous Seasons
                    </DropdownItem>
                    <DropdownItem divider />
                    {seasonData.inactiveSeasonList.length > 0 && seasonData.inactiveSeasonList.map((s, idx) => (
                        <DropdownItem key={s.id}>
                            <NavLink tag={Link} to={`/season/${s.id}`}>{s.seasonName}</NavLink>
                        </DropdownItem>
                    ))}
                    {seasonData.inactiveSeasonList.length <= 0 && (
                        <DropdownItem>
                            No inactive seasons!
                        </DropdownItem>
                    )}
                </DropdownMenu>
            )}
            {loading && (
                <DropdownMenu right>
                    <DropdownItem header>
                        Loading...
                    </DropdownItem>
                </DropdownMenu>
            )}
        </React.Fragment>
    );
};

export default SeasonNav;