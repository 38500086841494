import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import {
    IMatchTeamInfoDto,
} from '../../WorldDoomLeague';

interface MatchPreviewPlayersProps {
    teamInfo: IMatchTeamInfoDto,
}

const MatchPreviewPlayers = (props: MatchPreviewPlayersProps) => {
    return (
        <React.Fragment>
            <Row xs="3">
                <Col>
                    <Card className='bg-primary border-white mb-3'>
                        <CardHeader>
                            {props.teamInfo.blueTeamName}
                        </CardHeader>
                        <CardBody>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.blueCaptainId}`}>{props.teamInfo.blueCaptain}</NavLink></p>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.bluePlayerOneId}`}>{props.teamInfo.bluePlayerOne}</NavLink></p>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.bluePlayerTwoId}`}>{props.teamInfo.bluePlayerTwo}</NavLink></p>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.bluePlayerThreeId}`}>{props.teamInfo.bluePlayerThree}</NavLink></p>
                        </CardBody>
                    </Card>
                </Col>
                <Col>
                    <h2 className='text-center'>VS.</h2>
                </Col>
                <Col>
                    <Card className='bg-danger border-white mb-3'>
                        <CardHeader>
                            {props.teamInfo.redTeamName}
                        </CardHeader>
                        <CardBody>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.redCaptainId}`}>{props.teamInfo.redCaptain}</NavLink></p>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.redPlayerOneId}`}>{props.teamInfo.redPlayerOne}</NavLink></p>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.redPlayerTwoId}`}>{props.teamInfo.redPlayerTwo}</NavLink></p>
                            <p><NavLink className='text-white text-decoration-none text-center' to={`/player/${props.teamInfo.redPlayerThreeId}`}>{props.teamInfo.redPlayerThree}</NavLink></p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>);
};

export default MatchPreviewPlayers;