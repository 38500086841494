import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'reactstrap';
import {
    SeasonsClient,
    IPlayedSeasonsVm,
    IPlayedSeasonDto
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';
import OutstandingDemoList from './OutstandingDemoList';
import Select from 'react-select';

interface ManageDemosProps {
    // Add your regular properties here
}

const ManageDemos = (props: ManageDemosProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState(true);

    const [season, setSeason] = useState<IPlayedSeasonDto>(null);
    const [seasons, setSeasons] = useState<IPlayedSeasonDto[]>(null);
    const [updatedDemoId, setUpdatedDemoId] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SeasonsClient();
                const response = await client.getPlayedSeasons()
                    .then(response => response.toJSON() as Promise<IPlayedSeasonsVm>);
                const data = await response.seasonList;
                setSeasons(data);
                if (season) {
                    if (data.find(f => f.id == season)) {
                        setSeason(data.find(f => f.id == season));
                    } else {
                        setSeason(null);
                    }
                }
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    const renderSelectSeason = () => {
        let select = null;
        if (seasons && seasons.length > 0) {
            select = (
                <Select
                    options={seasons}
                    onChange={e => setSeason(e)}
                    isSearchable={true}
                    value={seasons.find(o => o == season) || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.seasonName}
                    isLoading={loading}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                />);
        } else {
            select = (<Select options={[{ label: "No seasons!", value: "0" }]} theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'dimgrey',
                    neutral0: 'black',
                    neutral80: 'white'
                },
            })} />);
        }

        return (select);
    };

    return (
        <React.Fragment>
            <Row className='mb-3'>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <h3 className='text-center'>Manage Demos</h3>
                    <p className='text-center'>This page allows you to see, at a glance, the demo status for each season. The left pane allows you to edit any demo, and the right shows you demos with an outstanding status.</p>
                    {renderSelectSeason()}
                </Col>
            </Row>
            <Row>
                <Col>
                    Placeholder
                </Col>
                <Col>
                    {season && (
                        <OutstandingDemoList season={season.id} />
                    )}
                    {!season && !loading && (<h2 className='text-center'>Select a season to see outstanding demos here.</h2>)}
                </Col>
            </Row>

        </React.Fragment>
    );
};

export default ManageDemos;