import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { IAdHocRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import { formatdecider, MatchRoundFragStatsCategories, FragWeaponCategories } from '../../../../../helpers'
import { useEffect, useState } from 'react';

interface AdHocMatchOverallWeaponsFragTableProps {
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    finalBoxScore: IAdHocRoundBoxScoreDto;
};

const AdHocMatchOverallWeaponsFragTable = (props: AdHocMatchOverallWeaponsFragTableProps) => {
    const [redPlayers, setRedPlayers] = useState<number[]>([]);
    const [bluePlayers, setBluePlayers] = useState<number[]>([]);
    const [greenPlayers, setGreenPlayers] = useState<number[]>([]);
    const [nonePlayers, setNonePlayers] = useState<number[]>([]);

    const getRedTeamPlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var redPlayers = [] as number[];
        boxscore.redTeamPlayerRoundBoxScore.map((s, idx) => {
            redPlayers.push(s.playerId);
        });

        return redPlayers;
    };

    const getBlueTeamPlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var bluePlayers = [] as number[];
        boxscore.blueTeamPlayerRoundBoxScore.map((s, idx) => {
            bluePlayers.push(s.playerId);
        });

        return bluePlayers;
    };

    const getGreenTeamPlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var greenPlayers = [] as number[];
        boxscore.greenTeamPlayerRoundBoxScore.map((s, idx) => {
            greenPlayers.push(s.playerId);
        });

        return greenPlayers;
    };

    const getNoneTeamPlayers = (boxscore: IAdHocRoundBoxScoreDto) => {
        var nonePlayers = [] as number[];
        boxscore.nonTeamPlayerRoundBoxScore.map((s, idx) => {
            nonePlayers.push(s.playerId);
        });

        return nonePlayers;
    };

    const createRedPlayerTableRow = () => {
        var redRows = [];
        var redRow = [];
        redPlayers.map((s, idx) => {
            redRow.push(<th scope="row">{props.finalBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            bluePlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerId === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    }
                } else {
                    redRow.push(<td>-</td>);
                }
            });

            greenPlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerId === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                redRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    }
                } else {
                    redRow.push(<td>-</td>);
                }
            });

            redRows.push(<tr className='bg-danger'>{redRow}</tr>);
            redRow = [];
        });


        return (redRows);
    };

    const createBluePlayerTableRow = () => {
        var blueRows = [];
        var blueRow = [];
        bluePlayers.map((s, idx) => {
            blueRow.push(<th scope="row">{props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            redPlayers.map((u,  __idx) => {
                var playerRoundStats = props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    }
                } else {
                    blueRow.push(<td>-</td>);
                }
            });

            greenPlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                blueRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    }
                } else {
                    blueRow.push(<td>-</td>);
                }
            });

            blueRows.push(<tr className='bg-primary'>{blueRow}</tr>);
            blueRow = [];
        });

        return (blueRows);
    };

    const createGreenPlayerTableRow = () => {
        var greenRows = [];
        var greenRow = [];
        greenPlayers.map((s, idx) => {
            greenRow.push(<th scope="row">{props.finalBoxScore.greenTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            redPlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    }
                } else {
                    greenRow.push(<td>-</td>);
                }
            });

            bluePlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                greenRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                greenRow.push(<td>-</td>);
                            }
                        } else {
                            greenRow.push(<td>-</td>);
                        }
                    }
                } else {
                    greenRow.push(<td>-</td>);
                }
            });

            greenRows.push(<tr className='bg-success'>{greenRow}</tr>);
            greenRow = [];
        });

        return (greenRows);
    };

    const createNonePlayerTableRow = () => {
        var noneRows = [];
        var noneRow = [];
        nonePlayers.map((s, idx) => {
            noneRow.push(<th scope="row">{props.finalBoxScore.nonTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>);

            nonePlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.nonTeamPlayerRoundBoxScore.find(f => f.playerId === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === FragWeaponCategories.Frags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                noneRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                noneRow.push(<td>-</td>);
                            }
                        } else {
                            noneRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.FragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.killsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                noneRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                noneRow.push(<td>-</td>);
                            }
                        } else {
                            noneRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFrags) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                noneRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                noneRow.push(<td>-</td>);
                            }
                        } else {
                            noneRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === FragWeaponCategories.CarrierFragsWithFlag) {
                        var playerKillsByWeapon = playerRoundStats.killStats.carrierKillsByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerKillsByWeapon) {
                            var playerKills = playerKillsByWeapon.kills.find(f => f.targetId === u);
                            if (playerKills) {
                                noneRow.push(<td>{playerKills.kills ? formatdecider(playerKills.kills) : '-'}</td>);
                            } else {
                                noneRow.push(<td>-</td>);
                            }
                        } else {
                            noneRow.push(<td>-</td>);
                        }
                    }
                } else {
                    noneRow.push(<td>-</td>);
                }
            });

            noneRows.push(<tr className='bg-secondary'>{noneRow}</tr>);
            noneRow = [];
        });

        return (noneRows);
    };

    useEffect(() => {
        setRedPlayers(getRedTeamPlayers(props.finalBoxScore));
        setBluePlayers(getBlueTeamPlayers(props.finalBoxScore));
        setGreenPlayers(getGreenTeamPlayers(props.finalBoxScore));
        setNonePlayers(getNoneTeamPlayers(props.finalBoxScore));
    }, [props.categoryObject, props.activeWeapon]);

    return (
        <React.Fragment>
            {redPlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    {bluePlayers.map((s, idx) => (
                                        <th>{props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>
                                    ))}
                                    {greenPlayers.map((s, idx) => (
                                        <th>{props.finalBoxScore.greenTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {createRedPlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}

            {bluePlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    {redPlayers.map((s, idx) => (
                                        <th>{props.finalBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName }</th>
                                    ))}
                                    {greenPlayers.map((s, idx) => (
                                        <th>{props.finalBoxScore.greenTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {createBluePlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}

            {greenPlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    {redPlayers.map((s, idx) => (
                                        <th>{props.finalBoxScore.redTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>
                                    ))}
                                    {bluePlayers.map((s, idx) => (
                                        <th>{props.finalBoxScore.blueTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {createGreenPlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}

            {nonePlayers.length > 0 && (
                <Row>
                    <Col>
                        <Table responsive size="sm">
                            <thead>
                                <tr>
                                    <th> </th>
                                    {nonePlayers.map((s, idx) => (
                                        <th>{props.finalBoxScore.nonTeamPlayerRoundBoxScore.find(f => f.playerId === s).playerName}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {createNonePlayerTableRow()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default AdHocMatchOverallWeaponsFragTable;