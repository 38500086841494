import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundDamageStatsCategories } from '../../../../../helpers';
import { IRoundBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchRoundWeaponsDamageTable from './MatchRoundWeaponsDamageTable';

interface MatchRoundWeaponCategoryFragTabsViewProps {
    categoryObject: MatchRoundDamageStatsCategories;
    activeWeapon: string;
    perRoundBoxScore: IRoundBoxScoreDto;
};

const MatchRoundWeaponCategoryDamageTabsView = (props: MatchRoundWeaponCategoryFragTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeWeapon}>
                {props.categoryObject && props.categoryObject.DamageWeaponCategories.map((s, idx) => (
                    <TabPane tabId={s}>
                        <MatchRoundWeaponsDamageTable activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} perRoundBoxScore={props.perRoundBoxScore} />
                    </TabPane>
                    ))}
            </TabContent>
        </React.Fragment>);
};

export default MatchRoundWeaponCategoryDamageTabsView;