import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { IRoundBoxScoreDto } from '../../../../WorldDoomLeague';

interface MatchRoundPerformancePickupStatsProps {
    activeRound: number,
    perRoundBoxScore: IRoundBoxScoreDto[]
};

const MatchRoundPerformancePickupStats = (props: MatchRoundPerformancePickupStatsProps) => {

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Button color="primary" block id="pickuptoggler" style={{ marginBottom: '1rem' }}>
                        Item Pickups
                    </Button>
                    <UncontrolledCollapse toggler="#pickuptoggler">
                        <Row>
                            <Col>
                                <h2>Item Pickups</h2>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Total Power Pickups</th>
                                            <th>Power Pickup HP</th>
                                            <th>Minor Pickup HP</th>
                                            <th>Total HP from Pickups</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.perRoundBoxScore[props.activeRound - 2].redTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-danger'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.powerups}</td>
                                                <td>{s.matchStats.healthFromPowerPickups}</td>
                                                <td>{s.matchStats.healthFromNonPowerPickups}</td>
                                                <td>{s.matchStats.pickupHealthGained}</td>
                                            </tr>
                                        ))}
                                        {props.perRoundBoxScore[props.activeRound - 2].blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                            <tr className='bg-primary'>
                                                <th scope="row">{s.playerName}</th>
                                                <td>{s.matchStats.powerups}</td>
                                                <td>{s.matchStats.healthFromPowerPickups}</td>
                                                <td>{s.matchStats.healthFromNonPowerPickups}</td>
                                                <td>{s.matchStats.pickupHealthGained}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </UncontrolledCollapse>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MatchRoundPerformancePickupStats;