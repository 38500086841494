import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import {
    DemosClient,
    IMyDemoDto,
    IMyDemoVm
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import DemoList from './DemoList';

interface MyDemosProps {
    // Add your regular properties here
}

const MyDemos = (props: MyDemosProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [demoData, setDemoData] = useState<IMyDemoDto[]>([]);
    const [changedDemoId, setChangedDemoId] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new DemosClient();
                const response = await client.getMyDemos()
                    .then(response => response.toJSON() as Promise<IMyDemoVm>);
                setDemoData(response.demoList);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [changedDemoId]);

    return (
        <React.Fragment>
            {demoData.length > 0 && (
                <React.Fragment>
                    <h2 className='text-center'>All demos MUST be zipped prior to uploading. Failure to do so will result in an error. Please zip ALL of the demos from each game into 1 archive.</h2>
                    <DemoList demoList={demoData} setChangedDemoId={setChangedDemoId} />
                </React.Fragment>
            )}
            {demoData.length <= 0 && !loading && (
                <React.Fragment>
                    <h2 className='text-center'>You don't need to upload demos at this time. Thank you for being a responsible player! 😃</h2>
                </React.Fragment>
            )}
            {loading && (
                <React.Fragment>
                    <h2 className='text-center'>Loading...</h2>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MyDemos;