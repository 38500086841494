import * as React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { MatchRoundFragStatsCategories } from '../../../../../helpers';
import { IMatchFinalBoxScoreDto } from '../../../../../WorldDoomLeague';
import MatchOverallWeaponsFragTable from './MatchOverallWeaponsFragTable';

interface MatchOverallWeaponCategoryFragTabsViewProps {
    categoryObject: MatchRoundFragStatsCategories;
    activeWeapon: string;
    finalsBoxScore: IMatchFinalBoxScoreDto;
};

const MatchOverallWeaponCategoryFragTabsView = (props: MatchOverallWeaponCategoryFragTabsViewProps) => {

    return (
        <React.Fragment>
            <TabContent activeTab={props.activeWeapon}>
                {props.categoryObject && props.categoryObject.FragWeaponCategories.map((s, idx) => (
                    <TabPane tabId={s}>
                        <MatchOverallWeaponsFragTable activeWeapon={props.activeWeapon} categoryObject={props.categoryObject} finalBoxScore={props.finalsBoxScore} />
                    </TabPane>
                    ))}
            </TabContent>
        </React.Fragment>);
};

export default MatchOverallWeaponCategoryFragTabsView;