import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    ISeasonSummaryDto,
    ISeasonSummaryVm,
    SeasonsClient,
} from '../../WorldDoomLeague';
import { ErrorContext } from '../../state';
import SeasonSummary from './SeasonSummary';
import SeasonDetails from './SeasonDetails';

interface SeasonRouterProps {
    id: string;   // id of the match (coming from the router)
}

interface SeasonProps extends RouteComponentProps<SeasonRouterProps> {
    // Add your regular properties here
}

const Season = (props: SeasonProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);

    const [seasonData, setSeasonData] = useState<ISeasonSummaryDto>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SeasonsClient();
                const response = await client.getSeasonSummaryById(parseInt(props.match.params.id))
                    .then(response => response.toJSON() as Promise<ISeasonSummaryVm>);
                setSeasonData(response.seasonSummary);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [props.match.params.id]);

    return (
        <React.Fragment>
            {seasonData && (
                <React.Fragment>
                    <SeasonSummary
                        seasonName={seasonData.seasonName}
                        winningTeamId={seasonData.winningTeam}
                        winningTeamName={seasonData.winningTeamName}
                        signupDate={seasonData.signupsDateStart}
                        seasonStandings={seasonData.seasonStandings}
                    />
                    <SeasonDetails
                        draft={seasonData.seasonDraft}
                        trades={seasonData.seasonTrades}
                        regSeasonWeeks={seasonData.regularSeasonWeeks}
                        playoffWeeks={seasonData.playoffsWeeks}
                        finalsWeek={seasonData.finalsWeek}
                        teams={seasonData.seasonTeams}
                        combinedStats={seasonData.seasonStats}
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default Season;