import * as React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { formatdecider, FormatHhMmSs } from '../../helpers';
import { ITeamStatsDto } from '../../WorldDoomLeague';

interface TeamCombinedStatsProps {
    combinedStats: ITeamStatsDto;
}

const TeamCombinedStats = (props: TeamCombinedStatsProps) => {
    return (
        <React.Fragment>
            <Card className='bg-danger mb-3'>
                <CardHeader>
                    <div className='text-center'>Combined Team Stats</div>
                </CardHeader>
                <CardBody>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th>Rounds Played</th>
                                <th>Total Time Played</th>
                                <th>Captures</th>
                                <th>P. Captures</th>
                                <th>Assists</th>
                                <th>Touches</th>
                                <th>P. Touches</th>
                                <th>Frags</th>
                                <th>Flag Defs</th>
                                <th>Deaths</th>
                                <th>Damage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formatdecider(props.combinedStats.roundsPlayed)}</td>
                                <td>{FormatHhMmSs(props.combinedStats.timePlayed)}</td>
                                <td>{formatdecider(props.combinedStats.captures)}</td>
                                <td>{formatdecider(props.combinedStats.pickupCaptures)}</td>
                                <td>{formatdecider(props.combinedStats.assists)}</td>
                                <td>{formatdecider(props.combinedStats.flagTouches)}</td>
                                <td>{formatdecider(props.combinedStats.pickupFlagTouches)}</td>
                                <td>{formatdecider(props.combinedStats.frags)}</td>
                                <td>{formatdecider(props.combinedStats.flagDefenses)}</td>
                                <td>{formatdecider(props.combinedStats.deaths)}</td>
                                <td>{formatdecider(props.combinedStats.damage)}</td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default TeamCombinedStats;