import * as React from 'react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

interface SeasonViewTabsProps {
    activeTab: number,
    setActiveTab: React.Dispatch<React.SetStateAction<number>>
};

const SeasonViewTabs = (props: SeasonViewTabsProps) => {

    const toggle = (tab: number) => {
        if (props.activeTab !== tab) props.setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Nav tabs className='mb-3'>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 1 })}
                                onClick={() => { toggle(1); }}
                            >
                                Draft
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 2 })}
                                onClick={() => { toggle(2); }}
                            >
                                Teams
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 3 })}
                                onClick={() => { toggle(3); }}
                            >
                                Roster Changes
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 4})}
                                onClick={() => { toggle(4); }}
                            >
                                Games
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: props.activeTab === 5 })}
                                onClick={() => { toggle(5); }}
                            >
                                Stats
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>
        </React.Fragment>);
};

export default SeasonViewTabs;