import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Button } from 'reactstrap';
import {
    MapsClient,
    IUnplayedMapsVm,
    IUnplayedMapsDto,
    DeleteMapCommand
} from '../../../WorldDoomLeague';
import Select from 'react-select';
import { ErrorContext } from '../../../state';

interface DeleteMapProps {
    newMapId: number;
    setDeletedMapId: React.Dispatch<React.SetStateAction<number>>;
}

const DeleteMap = (props: DeleteMapProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IUnplayedMapsDto[]>([]);
    const [deletedMapId, setDeletedMapId] = useState(0);
    const [selectedMapId, setSelectedMapId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new MapsClient();
                const response = await client.getUnplayedMaps()
                    .then(response => response.toJSON() as Promise<IUnplayedMapsVm>);
                const data = response.mapList;
                setData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [deletedMapId, props.newMapId]);

    const handleSubmit = async (evt) => {
        try {
            let client = new MapsClient();
            const command = new DeleteMapCommand;
            command.mapId = selectedMapId;
            const response = await client.delete(selectedMapId, command);
            setDeletedMapId(response);
            props.setDeletedMapId(response);
            setSelectedMapId(0);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    // create a list for each map.
    const renderUnplayedMapDropdown = () => {
        let select = null;
        if (data.length > 0) {
            select = (
                <Select
                    options={data}
                    value={data.find(f => f.id == selectedMapId) || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => label.mapName + " | " + label.mapPack}
                    onChange={e => setSelectedMapId(e.id)}
                    isLoading={loading}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                />
            );
        }
        else {
            select = (
                <Select options={[{ label: "No maps found in the system.", value: "Not" }]} theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'dimgrey',
                        neutral0: 'black',
                        neutral80: 'white'
                    },
                })} />
            );
        }
        return select;
    };

    // create a form for deleting an unplayed map.
    const renderDeleteMap = () => {
        return (
            <React.Fragment>
                <Button color="danger" size="lg" block disabled={selectedMapId <= 0} onClick={handleSubmit}>Delete Map</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {renderUnplayedMapDropdown()}
            <br />
            {renderDeleteMap()}
        </React.Fragment>
    );
};

export default DeleteMap;