import * as React from 'react';

const Footer = () => {
    return (
        <React.Fragment>

            <footer className='pt-4 my-md-5 pt-md-5 border-top'>
                <div className="row">
                    <div className="col-12 col-md">
                        <img className="mb-2" src="/images/wdllogo-small.png" alt="WDL" width="72" height="50" />
                        <small className="d-block mb-3 text-muted">© 2014-2021</small>
                    </div>
                    <div className="col-6 col-md">
                        <h5>Follow Us</h5>
                        <ul className="list-unstyled text-small">
                            <li><a className="text-muted" href='https://twitter.com/WorldDoomLeague'>Twitter</a></li>
                            <li><a className="text-muted" href='https://discord.gg/TyF9NTj'>Discord</a></li>
                            <li><a className="text-muted" href='https://twitch.tv/WorldDoomLeague'>Twitch</a></li>
                            <li><a className="text-muted" href='https://www.youtube.com/c/DoomleagueOrg'>YouTube</a></li>
                            <li><a className="text-muted" href='https://doomleague.org/forums'>Forums</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5>Special Thanks To</h5>
                        <ul className="list-unstyled text-small">
                            <li><a className="text-muted" href="#">Xenaero</a></li>
                            <li><a className="text-muted" href="#">Ralphis</a></li>
                            <li><a className="text-muted" href="#">Revenant</a></li>
                            <li><a className="text-muted" href="#">Tony (Climhazzard)</a></li>
                            <li><a className="text-muted" href="#">WDL Team</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        <h5>About</h5>
                        <p className="text-small">
                            The World Doom League is a small, completely community driven league for competitve Doom CTF players.</p>

                        <p className="text-small">The World Doom League is always open to new players.
                            Please visit our forums or our Discord to learn how to join.
                        </p>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default Footer;