import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, FormGroup, Button, Row, Col, FormText } from 'reactstrap';
import Select from 'react-select';

import {
    IUsersVm,
    UsersClient,
    IUserDto,
    SetRolesForUserCommand,
    IRolesForUserVm
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';

const EditUsers = (props) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [editedUserId, setEditedUserId] = useState<string>("");
    const [editedUserName, setEditedUserName] = useState<string>("");
    const [userRoleChanged, setUserRoleChanged] = useState<boolean>(false);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isStatsRunner, setIsStatsRunner] = useState<boolean>(false);
    const [isDemoAdmin, setIsDemoAdmin] = useState<boolean>(false);

    const [userData, setUserData] = useState<IUserDto[]>([]);
    const [userRoles, setUserRoles] = useState<IRolesForUserVm>();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new UsersClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<IUsersVm>);
                const data = response.userList;
                setUserData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [editedUserId]);

    const editUser = async (evt) => {
        try {
            let client = new UsersClient();
            const command = new SetRolesForUserCommand;
            command.userId = userId;
            command.isAdmin = isAdmin;
            command.isStatsRunner = isStatsRunner;
            command.isDemoAdmin = isDemoAdmin;
            const response = await client.setRoleForUser(command);
            await loadUserRoles(userId);
            setEditedUserId(userId);
            setEditedUserName(userName);
            setUserRoleChanged(false);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const loadUserRoles = async (userId: string) => {
        try {
            let client = new UsersClient();
            const response = await client.getRolesForUsers(userId)
                .then(response => response.toJSON() as Promise<IRolesForUserVm>);
            setUserRoles(response);
            setUserId(userId);
            setUserName(response.userName);
            setIsAdmin(response.isAdmin);
            setIsDemoAdmin(response.isDemoAdmin);
            setIsStatsRunner(response.isStatsRunner);
            setUserRoleChanged(false);
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const handleSetIsAdmin = (admin: boolean) => {
        setIsAdmin(admin);
        checkAdminAgainstOriginal(admin);
    };

    const checkAdminAgainstOriginal = (admin: boolean) => {
        if (userRoles && userRoles.isAdmin === admin && userRoles.isDemoAdmin === isDemoAdmin && userRoles.isStatsRunner === isStatsRunner) {
            setUserRoleChanged(false);
        } else {
            setUserRoleChanged(true);
        }
    };

    const handleSetIsDemoAdmin = (demoadmin: boolean) => {
        setIsDemoAdmin(demoadmin);
        checkDemoAdminAgainstOriginal(demoadmin);
    };

    const checkDemoAdminAgainstOriginal = (demoadmin: boolean) => {
        if (userRoles && userRoles.isAdmin === isAdmin && userRoles.isDemoAdmin === demoadmin && userRoles.isStatsRunner === isStatsRunner) {
            setUserRoleChanged(false);
        } else {
            setUserRoleChanged(true);
        }
    };

    const handleSetIsStatsRunner = (statsrunner: boolean) => {
        setIsStatsRunner(statsrunner);
        checkStatsRunnerAgainstOriginal(statsrunner);
    };

    const checkStatsRunnerAgainstOriginal = (statsrunner: boolean) => {
        if (userRoles && userRoles.isAdmin === isAdmin && userRoles.isDemoAdmin === isDemoAdmin && userRoles.isStatsRunner === statsrunner) {
            setUserRoleChanged(false);
        } else {
            setUserRoleChanged(true);
        }
    };


    // create a form for editing a user's roles.
    const renderEditUserForm = () => {
        return (
            <React.Fragment>
                <FormGroup check>
                    <Label check for='Administrator'><Input type="checkbox" onChange={e => handleSetIsAdmin(e.target.checked)} disabled={!userRoles} checked={isAdmin} />{' '}
                        Administrator
                    </Label>
                    <FormText color="muted">
                        Administrators can manage the entire site, including stats, players, and seasons, including deletions.
                    </FormText>
                </FormGroup>
                <FormGroup check>
                    <Label check for='statsrunner'>
                        <Input type="checkbox" onChange={e => handleSetIsDemoAdmin(e.target.checked)} disabled={!userRoles} checked={isDemoAdmin} />{' '}
                        Stats Runner
                        </Label>
                    <FormText color="muted">
                        Stats Runners can run stats and set up playoff games, along with everything the Demo Administrator can do.
                    </FormText>
                </FormGroup>
                <FormGroup check>
                    <Label check for='demoadmin'>
                        <Input type="checkbox" onChange={e => handleSetIsStatsRunner(e.target.checked)} disabled={!userRoles} checked={isStatsRunner} />{' '}
                        Demo Admin
                    </Label>
                    <FormText color="muted" className='mb-3'>
                        Demo Administrators can approve, update, upload, reject and delete demo files from every player.
                    </FormText>
                </FormGroup>
                <Button color="primary" size="lg" block disabled={!userRoles || !userRoleChanged} onClick={editUser} className='mb-3'>Edit User Roles</Button>
            </React.Fragment>
        );
    };

    // create a list for each user.
    const renderUserDropdown = () => {
        let select = null;
        if (userData.length > 0) {
            select = (
                <Select
                    options={userData}
                    onChange={e => loadUserRoles(e.id)}
                    isSearchable={true}
                    value={userData.find(o => o.id === userId) || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => `User: ${label.userName} Player: ${label.playerName}`}
                    isLoading={loading}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                    className="mb-3"
                />)
        } else {
            select = (<Select options={[{ label: "No users found in the system.", value: "Not" }]} theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'dimgrey',
                    neutral0: 'black',
                    neutral80: 'white'
                },
            })}/>);
        }
        return (select);
    };

    return (
        <React.Fragment>
            <Row className='mb-3'>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <h3 className='text-center'>Edit User Roles</h3>
                    <p>Please select a user to make changes to.</p>
                    <p>Please be careful in this page, you can revoke and add access very easily, intentionally or not.</p>
                    {renderUserDropdown()}
                    {renderEditUserForm()}
                    {editedUserName !== "" && (<h3 className='text-center'>{editedUserName} has been successfully changed!</h3>)}
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default EditUsers;