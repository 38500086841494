import * as React from 'react';
import { useState } from 'react';
import AdHocMatchCategoryTabs from './AdHocMatchCategoryTabs';
import { IAdHocMatchSummaryVm } from '../../WorldDoomLeague';
import AdHocMatchView from './AdHocMatchView';

interface AdHocMatchCategoryRoundViewProps {
    gameData: IAdHocMatchSummaryVm
}

const AdHocMatchCategoryRoundView = (props: AdHocMatchCategoryRoundViewProps) => {
    const [activeTab, setActiveTab] = useState(1);

    return (
        <React.Fragment>
            <AdHocMatchCategoryTabs activeTab={activeTab} setActiveTab={setActiveTab} />
            <br />
            <AdHocMatchView activeTab={activeTab} matchInfo={props.gameData} />
        </React.Fragment>);
};

export default AdHocMatchCategoryRoundView;