import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardSubtitle, CardText, CardTitle, Col, Row } from 'reactstrap';
import { ErrorContext } from '../../../state';
import { DemosClient, IOutstandingDemoVm, IOutstandingWeekDemoDto } from '../../../WorldDoomLeague';
import OutstandingDemo from './OutstandingDemo';

interface OutstandingDemoListProps {
    season: number;
}

const OutstandingDemoList = (props: OutstandingDemoListProps) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState(true);
    const [demosData, setDemosData] = useState<IOutstandingWeekDemoDto[]>([]);

    useEffect(() => {
        if (props.season && props.season > 0) {
            const fetchData = async () => {
                setLoading(true);
                try {
                    let client = new DemosClient();
                    const response = await client.getOutstandingDemos(props.season)
                        .then(response => response.toJSON() as Promise<IOutstandingDemoVm>);
                    const data = response.demoList;
                    setDemosData(data);
                } catch (e) {
                    error.setError(JSON.parse(e.response));
                }
                setLoading(false);
            };

            fetchData();
        }
    }, [props.season]);

    return (
        <React.Fragment>
            <div className='table-sticky'>
                {demosData && demosData.map((s, idx) => (
                    <Card className='bg-secondary mb-3'>
                        <CardHeader>
                            <div className='text-center'>Week #{s.weekNumber} ({s.weekType})</div>
                        </CardHeader>
                        <CardBody>
                            {s.outstandingDemos && s.outstandingDemos.map((t, _idx) => (
                                <OutstandingDemo demo={t} key={t.id} />
                            ))}
                        </CardBody>
                    </Card>
                ))}
            </div>
            {loading && (<h2 className='text-center'>Loading...</h2>)}
        </React.Fragment>);
};

export default OutstandingDemoList;