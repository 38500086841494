import * as React from 'react';
import { Row, Col, Table, Button, UncontrolledCollapse } from 'reactstrap';
import { GameModeType, IAdHocRoundBoxScoreDto } from '../../../../WorldDoomLeague';
import { FormatMinMaxAvg } from '../../../../helpers';

interface MatchOverallPerformanceTouchesStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto,
    gameMode: GameModeType
};

const AdHocMatchOverallPerformanceTouchesStats = (props: MatchOverallPerformanceTouchesStatsProps) => {
    return (
        <React.Fragment>
            {props.gameMode == GameModeType.CaptureTheFlag && (
                <Row>
                    <Col>
                        <Button color="primary" block id="touchestoggler" style={{ marginBottom: '1rem' }}>
                            Flag Touches
                        </Button>
                        <UncontrolledCollapse toggler="#touchestoggler">
                            <Row>
                                <Col>
                                    <h2>Flag Touches</h2>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>All Flag Touches</th>
                                                <th>Touches</th>
                                                <th>Pickup Touches</th>
                                                <th>Touches with over 100 HP</th>
                                                <th>Flag Returns</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.finalBoxScore.redTeamPlayerRoundBoxScore.map((s, idx) => (
                                                <tr className='bg-danger'>
                                                    <th scope="row">{s.playerName}</th>
                                                    <td>{s.matchStats.flagTouches + s.matchStats.pickupFlagTouches}</td>
                                                    <td>{s.matchStats.flagTouches}</td>
                                                    <td>{s.matchStats.pickupFlagTouches}</td>
                                                    <td>{s.matchStats.flagTouchesWithOverHundredHp}</td>
                                                    <td>{s.matchStats.flagReturns}</td>
                                                </tr>
                                            ))}
                                            {props.finalBoxScore.blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                                <tr className='bg-primary'>
                                                    <th scope="row">{s.playerName}</th>
                                                    <td>{s.matchStats.flagTouches + s.matchStats.pickupFlagTouches}</td>
                                                    <td>{s.matchStats.flagTouches}</td>
                                                    <td>{s.matchStats.pickupFlagTouches}</td>
                                                    <td>{s.matchStats.flagTouchesWithOverHundredHp}</td>
                                                    <td>{s.matchStats.flagReturns}</td>
                                                </tr>
                                            ))}
                                            {props.finalBoxScore.greenTeamPlayerRoundBoxScore.map((s, idx) => (
                                                <tr className='bg-success'>
                                                    <th scope="row">{s.playerName}</th>
                                                    <td>{s.matchStats.flagTouches + s.matchStats.pickupFlagTouches}</td>
                                                    <td>{s.matchStats.flagTouches}</td>
                                                    <td>{s.matchStats.pickupFlagTouches}</td>
                                                    <td>{s.matchStats.flagTouchesWithOverHundredHp}</td>
                                                    <td>{s.matchStats.flagReturns}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Min/Max/Avg Touch HP</th>
                                                <th>Min/Max/Avg Touch Green Armor</th>
                                                <th>Min/Max/Avg Touch Blue Armor</th>
                                                <th>Min/Max/Avg Touch HP {'->'} Cap</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.finalBoxScore.redTeamPlayerRoundBoxScore.map((s, idx) => (
                                                <tr className='bg-danger'>
                                                    <th scope="row">{s.playerName}</th>
                                                    <td>{FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureMin)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureMax)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureAverage)}</td>
                                                </tr>
                                            ))}
                                            {props.finalBoxScore.blueTeamPlayerRoundBoxScore.map((s, idx) => (
                                                <tr className='bg-primary'>
                                                    <th scope="row">{s.playerName}</th>
                                                    <td>{FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureMin)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureMax)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureAverage)}</td>
                                                </tr>
                                            ))}
                                            {props.finalBoxScore.greenTeamPlayerRoundBoxScore.map((s, idx) => (
                                                <tr className='bg-success'>
                                                    <th scope="row">{s.playerName}</th>
                                                    <td>{FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.greenArmorWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagMin)} / {FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagMax)} / {FormatMinMaxAvg(s.matchStats.blueArmorWhenTouchingFlagAverage)}</td>
                                                    <td>{FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureMin)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureMax)} / {FormatMinMaxAvg(s.matchStats.healthWhenTouchingFlagThatResultsInCaptureAverage)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </UncontrolledCollapse>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default AdHocMatchOverallPerformanceTouchesStats;