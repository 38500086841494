import * as React from 'react';
import { Card, CardBody, CardHeader, Table } from 'reactstrap';
import { OrdinalSuffixOf } from '../../helpers';
import { ITeamTradesDto } from '../../WorldDoomLeague';

interface TeamTradesProps {
    trades: ITeamTradesDto[];
}

const TeamTrades = (props: TeamTradesProps) => {
    return (
        <React.Fragment>
            {props.trades.map((s, idx) => (
                <React.Fragment>
                    <Card className='border-white mb-3'>
                        <CardHeader>
                            <div className='text-center'>Week {s.weekNumber}</div>
                        </CardHeader>
                        <CardBody>
                            <Table responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>From</th>
                                        <th>To</th>
                                        <th>Traded Player</th>
                                        <th>Player Traded For</th>
                                        <th>Promoted Captain?</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {s.weekTrades.map((t, _idx) => (
                                        <tr>
                                            <th scope="row">{t.teamTradedFrom}</th>
                                            <td>{t.teamTradedTo}</td>
                                            <td>{t.tradedPlayerName}</td>
                                            <td>{t.playerTradedFor}</td>
                                            <td>{t.playerPromotedCaptain == true && ('✔️')}{t.playerPromotedCaptain == false && ('❌')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </React.Fragment>
            ))}
            {props.trades.length <= 0 && (
                <p className='text-center'>No trades recorded for this team.</p>
            )}
        </React.Fragment>
    );
};

export default TeamTrades;