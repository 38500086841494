import * as React from 'react';
import { IAdHocRoundBoxScoreDto } from '../../../../WorldDoomLeague';
import AdHocMatchOverallWeaponsAccuracyStats from './accuracy/AdHocMatchOverallWeaponsAccuracyStats';
import AdHocMatchOverallWeaponsDamageStats from './damage/AdHocMatchOverallWeaponsDamageStats';
import AdHocMatchOverallWeaponsFragsStats from './frags/AdHocMatchOverallWeaponsFragStats';

interface AdHocMatchOverallWeaponsStatsProps {
    finalBoxScore: IAdHocRoundBoxScoreDto
};

const AdHocMatchOverallWeaponsStats = (props: AdHocMatchOverallWeaponsStatsProps) => {

    return (
        <React.Fragment>
            <AdHocMatchOverallWeaponsFragsStats finalsBoxScore={props.finalBoxScore} />
            <AdHocMatchOverallWeaponsDamageStats finalBoxScore={props.finalBoxScore} />
            <AdHocMatchOverallWeaponsAccuracyStats finalBoxScore={props.finalBoxScore} />
        </React.Fragment>
    );
};

export default AdHocMatchOverallWeaponsStats;