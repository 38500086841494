import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { ErrorContext } from '../../state';

const NewsBlog = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://old.doomleague.org/wp-json/wp/v2/posts?filter[categories]=news&per_page=5&_embed')
                    .then(response => response.json());

                setBlogData(response);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    return (
        <Col className='col-sm-8'>
            {blogData.map((s, idx) => (
                <React.Fragment>
                    <Card className='border-white mb-3'>
                        <CardBody>
                            <h2>{s.title.rendered}</h2>

                            <div dangerouslySetInnerHTML={{ __html: s.content.rendered }}></div>

                            <p>Posted on {new Intl.DateTimeFormat('default', { dateStyle: "full", timeStyle: "full" } as Intl.DateTimeFormatOptions).format(new Date(s.date))}</p>
                        </CardBody>
                    </Card>
                </React.Fragment>
            ))}
        </Col>
    );
};

export default NewsBlog;