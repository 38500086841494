import * as React from 'react';
import { Label, Input} from 'reactstrap';
import DateTimePicker from 'react-datetime-picker';
import { useEffect } from 'react';
import { ISeasonDto } from '../../../WorldDoomLeague';

interface EditSeasonDetailsProps {
    season: ISeasonDto;
    seasonName: string;
    signupDate: Date;
    setSeasonName: React.Dispatch<React.SetStateAction<string>>;
    setSignupDate: React.Dispatch<React.SetStateAction<Date>>;
}

const EditSeasonDetails = (props: EditSeasonDetailsProps) => {

    useEffect(() => {
        if (props.season) {
            props.setSeasonName(props.season.seasonName);
            props.setSignupDate(new Date(props.season.dateStart));
        }
    }, [props.season]);

    return (
        <React.Fragment>
            <Label for='seasonname'>Season Name</Label>
            <Input type='text' className='form-control' id='seasonname' name='seasonname' disabled={!props.season} value={props.seasonName} placeholder='Season Name' onChange={e => props.setSeasonName(e.target.value)} />
            <br />
            <Label for='signupDates'>Signups Begin</Label>
            <DateTimePicker id='signupDates' name='signupDates' onChange={props.setSignupDate} disabled={!props.season} value={props.signupDate} />
        </React.Fragment>
    );
};

export default EditSeasonDetails;