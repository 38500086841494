import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ErrorContext } from '../../state';
import { SeasonsClient, ISeasonListVm, ISeasonStandingsDto } from '../../WorldDoomLeague';
import { FormatHhMmSs, FormatShortTimeString } from '../../helpers'
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col } from 'reactstrap';

const HomeTeamStandings = () => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SeasonsClient();
                const response = await client.getCurrentSeasonsStandings()
                    .then(response => response.toJSON() as Promise<ISeasonListVm>);
                const data = await response.seasons;
                setData(data);
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, []);

    // create a new table for each stat.
    const renderGameStatTables = () => {
        const gamestats = data;
        var tableArray = [];
        if (!loading) {
            if (gamestats.length > 0) {
                const gameobj = gamestats;
                gameobj.forEach(function (value) {
                    const seasonStandings = value.seasonStandings;
                    tableArray.push(
                        <Card className='bg-dark mb-3'>
                        <CardHeader>{value.seasonName}</CardHeader>
                            <CardBody>
                                {seasonStandings && seasonStandings.length > 0 && (
                                <table className='table table-striped table-sm'>
                                    <thead>
                                        <tr>
                                            <th>Team</th>
                                            <th>PTS</th>
                                            <th>GP</th>
                                            <th>RP</th>
                                            <th>W</th>
                                            <th>L</th>
                                            <th>T</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {seasonStandings.map((seasonStandings: ISeasonStandingsDto) =>
                                            <tr key={seasonStandings.teamName}>
                                                <td><NavLink to={`/team/${seasonStandings.teamId}`}>{seasonStandings.teamName}</NavLink></td>
                                                <td>{seasonStandings.points}</td>
                                                <td>{seasonStandings.gamesPlayed}</td>
                                                <td>{seasonStandings.roundsPlayed}</td>
                                                <td>{seasonStandings.wins}</td>
                                                <td>{seasonStandings.losses}</td>
                                                <td>{seasonStandings.ties}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    </table>
                                )}
                                {seasonStandings && seasonStandings.length <= 0 && (
                                    <h4 className='text-center'>No games have been played yet!</h4>
                                    )}
                            </CardBody>
                            </Card>);
                    });
            } else {
                tableArray.push(<Card className='bg-dark mb-3'>
                    <CardHeader>World Doom League Season Status</CardHeader>
                    <CardBody>
                        <h4>No active seasons currently.</h4>
                    </CardBody>
                </Card>);
            }
        } else {
            tableArray.push(<Card className='bg-dark mb-3'>
                <CardHeader>Loading...</CardHeader>
                <CardBody>
                    <h4>Loading...</h4>
                </CardBody>
            </Card>);
        }
        return (tableArray);
    };

    return (
        <React.Fragment>
            {renderGameStatTables()}
        </React.Fragment>
    );
};

export default HomeTeamStandings;