import * as React from 'react';
import { Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import { MatchRoundFragStatsCategories, FragWeaponCategories } from '../../../../../helpers';

interface AdHocMatchOverallWeaponsFragCategoriesTabsProps {
    activeCategoryTab: FragWeaponCategories,
    setActiveCategoryTab: React.Dispatch<React.SetStateAction<FragWeaponCategories>>;
    categories: MatchRoundFragStatsCategories[];
    setCategory: React.Dispatch<React.SetStateAction<MatchRoundFragStatsCategories>>;
    activeWeapon: string;
    setActiveWeapon: React.Dispatch<React.SetStateAction<string>>;
};

const AdHocMatchOverallWeaponsFragCategoriesTabs = (props: AdHocMatchOverallWeaponsFragCategoriesTabsProps) => {

    const toggle = (tab: FragWeaponCategories, category: MatchRoundFragStatsCategories) => {
        if (props.activeCategoryTab !== tab)
        {
            // Change the weapon tab if that weapon doesn't exist in our new selected category.
            if (category.FragWeaponCategories.find(f => f === props.activeWeapon) === undefined) {
                props.setActiveWeapon(category.FragWeaponCategories[0] || "");
            }
            props.setActiveCategoryTab(tab);
            props.setCategory(category);
        }
    };

    return (
        <React.Fragment>
            {props.categories.length > 0 && (
                <Row className='mb-3'>
                    <Col>
                        <Nav tabs>
                            {props.categories.map((s, idx) => (
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: props.activeCategoryTab === s.category })}
                                        onClick={() => { toggle(s.category, s); }}
                                    >
                                        {s.category}
                                    </NavLink>
                                </NavItem>
                            ))}
                        </Nav>
                    </Col>
                </Row>
            )}
        </React.Fragment>);
};

export default AdHocMatchOverallWeaponsFragCategoriesTabs;