import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Table } from 'reactstrap';
import { IMatchFinalBoxScoreDto } from '../../../../../WorldDoomLeague';
import { formatdecider, DamageWeaponCategories, MatchRoundDamageStatsCategories } from '../../../../../helpers'
import { useEffect, useState } from 'react';

interface MatchOverallWeaponsDamageTableProps {
    categoryObject: MatchRoundDamageStatsCategories;
    activeWeapon: string;
    finalBoxScore: IMatchFinalBoxScoreDto;
};

const MatchOverallWeaponsDamageTable = (props: MatchOverallWeaponsDamageTableProps) => {
    const [redPlayers, setRedPlayers] = useState<string[]>([]);
    const [bluePlayers, setBluePlayers] = useState<string[]>([]);

    const getRedTeamPlayers = (boxscore: IMatchFinalBoxScoreDto) => {
        var redPlayers = [] as string[];
        boxscore.redTeamPlayerFinalBoxScore.map((s, idx) => {
            redPlayers.push(s.playerName);
        });

        return redPlayers;
    };

    const getBlueTeamPlayers = (boxscore: IMatchFinalBoxScoreDto) => {
        var bluePlayers = [] as string[];
        boxscore.blueTeamPlayerFinalBoxScore.map((s, idx) => {
            bluePlayers.push(s.playerName);
        });

        return bluePlayers;
    };

    const createRedPlayerTableRow = () => {
        var redRows = [];
        var redRow = [];
        redPlayers.map((s, idx) => {
            redRow.push(<th scope="row">{s}</th>);

            bluePlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.redTeamPlayerFinalBoxScore.find(f => f.playerName === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === DamageWeaponCategories.Damage) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.damageByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                redRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === DamageWeaponCategories.DamageWithFlag) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.damageByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                redRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === DamageWeaponCategories.CarrierDamage) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.carrierDamageByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                redRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === DamageWeaponCategories.CarrierDamageWithFlag) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.carrierDamageByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                redRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                redRow.push(<td>-</td>);
                            }
                        } else {
                            redRow.push(<td>-</td>);
                        }
                    }
                } else {
                    redRow.push(<td>-</td>);
                }
            });

            redRows.push(<tr className='bg-danger'>{redRow}</tr>);
            redRow = [];
        });

        return (redRows);
    };

    const createBluePlayerTableRow = () => {
        var blueRows = [];
        var blueRow = [];
        bluePlayers.map((s, idx) => {
            blueRow.push(<th scope="row">{s}</th>);

            redPlayers.map((u, __idx) => {
                var playerRoundStats = props.finalBoxScore.blueTeamPlayerFinalBoxScore.find(f => f.playerName === s);
                if (playerRoundStats) {
                    if (props.categoryObject.category === DamageWeaponCategories.Damage) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.damageByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                blueRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === DamageWeaponCategories.DamageWithFlag) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.damageByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                blueRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === DamageWeaponCategories.CarrierDamage) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.carrierDamageByWeapon.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                blueRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    } else if (props.categoryObject.category === DamageWeaponCategories.CarrierDamageWithFlag) {
                        var playerDamageByWeapon = playerRoundStats.damageStats.carrierDamageByWeaponWithFlag.find(f => f.weapon === props.activeWeapon);
                        if (playerDamageByWeapon) {
                            var playerDamage = playerDamageByWeapon.damage.find(f => f.targetName === u);
                            if (playerDamage) {
                                blueRow.push(<td>{playerDamage.damage ? `${formatdecider(playerDamage.damage)} [${formatdecider(playerDamage.greenArmor)} GA, ${formatdecider(playerDamage.blueArmor)}  BA]` : '-'}</td>);
                            } else {
                                blueRow.push(<td>-</td>);
                            }
                        } else {
                            blueRow.push(<td>-</td>);
                        }
                    }
                } else {
                    blueRow.push(<td>-</td>);
                }
            });

            blueRows.push(<tr className='bg-primary'>{blueRow}</tr>);
            blueRow = [];
        });

        return (blueRows);
    };

    useEffect(() => {
        setRedPlayers(getRedTeamPlayers(props.finalBoxScore));
        setBluePlayers(getBlueTeamPlayers(props.finalBoxScore));

    }, [props.categoryObject, props.activeWeapon]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th> </th>
                                {bluePlayers.map((s, idx) => (
                                    <th>{s}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {createRedPlayerTableRow()}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table responsive size="sm">
                        <thead>
                            <tr>
                                <th> </th>
                                {redPlayers.map((s, idx) => (
                                    <th>{s}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {createBluePlayerTableRow()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default MatchOverallWeaponsDamageTable;