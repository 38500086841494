import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Label, Input, FormGroup, Button, Row, Col } from 'reactstrap';
import Select from 'react-select';

import {
    PlayersClient,
    IUserDto,
    DisassociatePlayerWithUserCommand
} from '../../../WorldDoomLeague';
import { ErrorContext } from '../../../state';

interface DisassociatePlayerProps {
    data: IUserDto[];
    setEditedUserId: React.Dispatch<React.SetStateAction<string>>;
};

const DisassociatePlayer = (props: DisassociatePlayerProps) => {
    const [userId, setUserId] = useState<string>("");
    const error = useContext(ErrorContext);

    const editPlayer = async (evt) => {
        try {
            let client = new PlayersClient();
            const command = new DisassociatePlayerWithUserCommand;
            command.userId = userId;
            const response = await client.disassociate(command);
            props.setEditedUserId(userId);
            setUserId("");
        } catch (e) {
            error.setError(JSON.parse(e.response));
        }
    };

    const handleUserDropdownSelected = (userId: string) => {
        setUserId(userId);
    };

    // create a list for each engine.
    const renderUserDropdown = () => {
        let select = null;
        if (props.data.length > 0) {
            select = (
                <Select
                    options={props.data}
                    onChange={e => handleUserDropdownSelected(e.id)}
                    isSearchable={true}
                    value={props.data.find(o => o.id === userId) || null}
                    getOptionValue={value => value.id.toString()}
                    getOptionLabel={label => `Player Name: ${label.playerName} | User Name: ${label.userName}`}
                    isOptionDisabled={o => o.playerId === null}
                    theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            primary25: 'dimgrey',
                            neutral0: 'black',
                            neutral80: 'white'
                        },
                    })}
                    className="mb-3"
                />)
        } else {
            select = (<Select options={[{ label: "No users found in the system.", value: "Not" }]} theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary25: 'dimgrey',
                    neutral0: 'black',
                    neutral80: 'white'
                    },
                })}
                className="mb-3"
                    />);
        }
        return (select);
    };

    const renderDisassociatePlayerButton = () => {
        return (
            <React.Fragment>
                <Button color="primary" size="lg" block disabled={!userId} onClick={editPlayer}>Disassociate Player</Button>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <h3 className='text-center'>Disassociate Player</h3>
            <p>Please select a user to disassociate to a player.</p>
            {renderUserDropdown()}
            {renderDisassociatePlayerButton()}
        </React.Fragment>
    );
};

export default DisassociatePlayer;