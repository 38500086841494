import * as React from 'react';
import { Row, Col } from 'reactstrap';
import { useContext, useEffect, useState } from 'react';
import SelectSeason from './SelectSeason';
import EditEngine from './EditEngine';
import EditWad from './EditWad';
import { ErrorContext } from '../../../state';
import { ISeasonDto, ISeasonsVm, SeasonsClient } from '../../../WorldDoomLeague';
import EditSeasonDetails from './EditSeasonDetails';
import EditSeasonButtons from './EditSeasonButtons';

const EditSeason = (props) => {
    const error = useContext(ErrorContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [engineId, setEngineId] = useState(0);
    const [wadId, setWadId] = useState(0);
    const [season, setSeason] = useState<ISeasonDto>(null);
    const [data, setData] = useState<ISeasonDto[]>([]);
    const [seasonName, setSeasonName] = useState<string>("");
    const [signupDate, setSignupDate] = useState<Date>(null);
    const [updatedSeasonId, setUpdatedSeasonId] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                let client = new SeasonsClient();
                const response = await client.get()
                    .then(response => response.toJSON() as Promise<ISeasonsVm>);
                const data = await response.seasonList;
                setData(data);
                if (season) {
                    if (data.find(f => f.id == season)) {
                        setSeason(data.find(f => f.id == season));
                    } else {
                        setSeason(null);
                    }
                }
            } catch (e) {
                error.setError(JSON.parse(e.response));
            }
            setLoading(false);
        };

        fetchData();
    }, [updatedSeasonId]);

    return (
        <React.Fragment>
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <h3 className='text-center mb-3'>Edit Season</h3>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <SelectSeason loading={loading} seasonData={data} season={season} setSeason={setSeason} />
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col xs="6" sm="4">
                    <EditEngine season={season} engine={engineId} setEngine={setEngineId} />
                </Col>
                <Col xs="6" sm="4">
                    <EditSeasonDetails season={season} setSeasonName={setSeasonName} seasonName={seasonName} signupDate={signupDate} setSignupDate={setSignupDate} />
                </Col>
                <Col sm="4">
                    <EditWad season={season} wad={wadId} setWad={setWadId} />
                </Col>
            </Row>
            <Row>
                <Col sm="12" md={{ size: 6, offset: 3 }}>
                    <EditSeasonButtons engineId={engineId} season={season} seasonName={seasonName} setUpdatedSeasonId={setUpdatedSeasonId} signupDate={signupDate} wadId={wadId} />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default EditSeason;