import * as React from 'react';
import { Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/home/Home';
import LeaderboardStats from './components/stats/leaderboard/LeaderboardStats';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import AdminRoutes from './components/admin/AdminRoutes';
import './custom.css';
import UpcomingMatches from './components/matches/UpcomingMatches';
import Results from './components/results/Results';
import Match from './components/match/Match';
import Season from './components/season/Season';
import Team from './components/team/Team';
import Player from './components/player/Player';
import Map from './components/map/Map';
import Demos from './components/demos/Demos';
import Players from './components/players/Players';
import Rules from './components/rules/Rules';
import AdHocMatch from './components/adhocmatch/AdHocMatch';

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;


export default () => (
    <Router>
        <Layout>
            <ApiAuthorizationRoutes />
            <AdminRoutes />
            <Route exact path='/' component={Home} />
            <Route exact path='/matches' component={UpcomingMatches} />
            <Route path="/matches/:id" component={Match} />
            <Route path="/season/:id" component={Season} />
            <Route path="/team/:id" component={Team} />
            <Route path="/player/:id" component={Player} />
            <Route path="/map/:id" component={Map} />
            <Route exact path='/results' component={Results} />
            <Route exact path='/demos' component={Demos} />
            <Route exact path='/players' component={Players} />
            <Route exact path='/rulebook' component={Rules} />
            <Route path='/stats/leaderboards' component={LeaderboardStats} />
            <Route path="/adhocgames/:id" component={AdHocMatch} />
        </Layout>
    </Router>
);